import { useEffect, useState } from "react";
import Kpi from "./Kpi";
import {
  getDataKpiChart,
  getDataKpiTable,
  getDataKpiTableDownload,
  getDataMonthUsers,
  getDataMonthUsersTable,
} from "./getData";
import { useKpiState } from "./hooks";
import { bastChartLegend } from "./data";

export default function SapAdminReviewLeadTime() {
  /* 
  "KPI SAP Admin review Lead Time": {
    Description:
      "Created Date BAST sampai dengan SAP Admin menginput nomor SES per Department",
    // PAYLOAD
    payload: {
      kpi: 5,
      year: 2024,
      company_code: 1492,
      department_id: 1,
      approval1: 3,
      approval2: 31,
    },
    // RESPONSE DATA
    data: [
      // ... MONTH 1 - 12
      {
        month_no: "1",
        month_name: "Jan-2024",
        year_no: "2024",
        company_code: "1492",
        total_bast: "13",
        total_vendor: "4",
        lead_time: "4",
      },
    ],
  },
  =======================================================================
    "KPI SAP Admin review Lead Time Per User": {
  // PAYLOAD
    payload: {
      kpi: 5,
      year: 2024,
      month: 4,
      company_code: 1492,
      department_id: 1,
      approval1: 3,
      approval2: 31,
    },
    // RESPONSE DATA
    data: [
      // ... ALL USER
      {
        user_name: "user co test email",
        ontime: 7,
        delay: 1,
        total: 8,
        percent_ontime: "87.5%",
        percent_delay: "12.5%",
      },
    ],
   */
  const hooksData = useKpiState({ 
      approval1: 3,
      approval2: 31,
      isInvoice: false,
    })
  // const [chartPayload, setChartPayload] = useState<any>({
  //   kpi: 7,
  //   year: new Date().getFullYear(),
  //   company_code: parseInt(
  //     JSON.parse(sessionStorage.getItem("current_company") as string)
  //       .company_code
  //   ),
  //   department_id: null,
  //   approval1: 3,
  //   approval2: 31,
  //   excel: false,
  // });
  // const [chartData, setChartData] = useState<any>({});
  // const [tableData, setTableData] = useState<any>([]);
  // const [kpiUsersData, setKpiUsersData] = useState<any>([]);
  // const [kpiUsersDataTable, setKpiUsersDataTable] = useState<any>([]);

  const chartTitle = "KPI SAP Admin review Lead Time";
  const chartLegend = bastChartLegend

  // TABLE
  // const tableColumn = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "September",
  //   "October",
  //   "November",
  //   "December",
  // ];
  // const tableColumn1 = ["Quantity", "%"];

  // useEffect(() => {
  //   // window.scrollTo(0, 0);
  //   getDataKpiChart({ body: chartPayload, isInvoice: false })
  //     .then((data) => setChartData(data))
  //     .catch((err) => {})
  //     .finally(() => {});

  //   getDataKpiTable({ body: chartPayload, isInvoice: false })
  //     .then((data) => setTableData(data))
  //     .catch((err) => {})
  //     .finally(() => {});
  // }, [chartPayload]);

  // const downloadExcel = () => {
  //   getDataKpiTableDownload({
  //     body: { ...chartPayload, excel: true },
  //     isInvoice: false,
  //   });
  // };
  // const getMonthUsers = async (month: any) => {
  //   // console.log(month, "month");
  //   // try {
  //   //   const data = await getDataMonthUsers({ body: { ...chartPayload, month}, isInvoice: false })
  //   //   if (data?.error === false && data?.data) {
  //   //     setKpiUsersData(data?.data)
  //   //   }
  //   // } catch (error) {
  //   //   console.log(error)
  //   // }
  //   getDataMonthUsers({ body: { ...chartPayload, month }, isInvoice: false })
  //     .then((data) => {
  //       setKpiUsersData(data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     })
  //     .finally(() => {});
  //     getDataMonthUsersTable({ body: { ...chartPayload, month }, isInvoice: false })
  //     .then((data) => setKpiUsersDataTable(data))
  //     .catch((err) => {console.log(err)})
  //     .finally(() => {});
  // };

  const props = {
    chartTitle,
    chartLegend,
    // tableRow,
    // tableColumn,
    // tableColumn1,
    // chartData,
    // chartPayload,
    // setChartPayload,
    // tableData,
    // downloadExcel,
    // getMonthUsers,
    // kpiUsersData,
    // kpiUsersDataTable,
    ...hooksData
  };

  return <Kpi {...props} />;
}
