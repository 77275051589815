import { useEffect, useState } from "react";
import Kpi from "./Kpi";
import {
  getDataKpiChart,
  getDataKpiTable,
  getDataKpiTableDownload,
  getDataMonthUsers,
  getDataMonthUsersTable,
} from "./getData";
import { invoiceChartLegend } from "./data";
import { useKpiState } from "./hooks";

export default function AccountPayableReviewLeadTime() {
  /* ==================================================================================================================================================
   *  6 KPI Account Payables Review Lead Time
      - per department
      
      "KPI Account Payables Review Lead Time": {
        Description:
          "perbandingan antara tanggal Invoice dibuat sampai dengan Team AP melakukan approval",
        // PAYLOAD
        payload: {
          kpi: 5,
          year: 2024,
          company_code: 1492,
          department_id: 1,
          approval1: 6,
          approval2: 7,
        },
        // RESPONSE DATA
        data: [
          // ... MONTH 1 - 12
          {
            month_no: "1",
            month_name: "Jan-2024",
            year_no: "2024",
            company_code: "1492",
            total_invoice: "13",
            total_vendor: "4",
            lead_time: "4",
          },
        ],
      },
      "KPI Account Payables Review Lead Time Per User": {
      // PAYLOAD
        payload: {
          kpi: 5,
          year: 2024,
          month: 4,
          company_code: 1492,
          department_id: 1,
          approval1: 6,
          approval2: 7,
        },
        // RESPONSE DATA
        data: [
          // ... ALL USER
          {
            user_name: "user co test email",
            ontime: 7,
            delay: 1,
            total: 8,
            percent_ontime: "87.5%",
            percent_delay: "12.5%",
          },
        ],
      },
    */
  // const [chartPayload, setChartPayload] = useState<any>({
  //   kpi: 7,
  //   year: new Date().getFullYear(),
  //   company_code: parseInt(
  //     JSON.parse(sessionStorage.getItem("current_company") as string)
  //       .company_code
  //   ),
  //   department_id: null,
  //   approval1: 6,
  //   approval2: 7,
  //   excel: false,
  // });
  // const [chartData, setChartData] = useState<any>({});
  // const [tableData, setTableData] = useState<any>([]);
  // const [kpiUsersData, setKpiUsersData] = useState<any>([]);
  // const [kpiUsersDataTable, setKpiUsersDataTable] = useState<any>([]);
  const hooksData = useKpiState({approval1: 6, approval2: 7, isInvoice: true})

  const chartTitle = "KPI Account Payables Review Lead Time";
  const chartLegend = invoiceChartLegend

  // TABLE
  // const tableRow = {
  //   delay: "Approval Delay",
  //   onTime: "Approval On Time",
  //   total: "Total",
  // };
  // const tableColumn = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "September",
  //   "October",
  //   "November",
  //   "December",
  // ];
  // const tableColumn1 = ["Quantity", "%"];

  // useEffect(() => {
  //   // window.scrollTo(0, 0);
  //   getDataKpiChart({ body: chartPayload })
  //     .then((data) => setChartData(data))
  //     .catch((err) => {})
  //     .finally(() => {});

  //   getDataKpiTable({ body: chartPayload })
  //     .then((data) => setTableData(data))
  //     .catch((err) => {})
  //     .finally(() => {});
  // }, [chartPayload]);

  // const downloadExcel = () => {
  //   getDataKpiTableDownload({
  //     body: { ...chartPayload, excel: true },
  //   });
  // };
  // const getMonthUsers = (month: any) => {
  //   getDataMonthUsers({ body: { ...chartPayload, month } })
  //   .then((data) => setKpiUsersData(data))
  //   .catch((err) => {console.log(err)})
  //   .finally(() => {});
  //   getDataMonthUsersTable({ body: { ...chartPayload, month } })
  //   .then((data) => setKpiUsersDataTable(data))
  //   .catch((err) => {console.log(err)})
  //   .finally(() => {});
  // };

  const props = {
    chartTitle,
    chartLegend,
    // tableRow,
    // tableColumn,
    // tableColumn1,
    // chartData,
    // chartPayload,
    // setChartPayload,
    // tableData,
    // downloadExcel,
    // getMonthUsers,
    // kpiUsersData,
    // kpiUsersDataTable,
    ...hooksData
  };

  return <Kpi {...props} />;
}
