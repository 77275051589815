import { Button, Col, Form, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import MILT_STM from "../../charts/MILT_STM";
import { CURRENT_COMPANY } from "../../../helpers/api/apiCore";
import { API_URI } from "../../../constants";
import Select from "react-select";
import FormInput from "../../../components/FormInput";

import { getData, getDataTable } from "./getData";
import KpiTable from "./KpiTable";
import ChartKpi from "./ChartKpi";
import ModalKpi from "./ModalKpi";
import { getWfRoleWithDept } from "../../../helpers/utils2";

const ucc = sessionStorage.getItem(CURRENT_COMPANY) as string;
let userCurrentCompany: any;
if (ucc?.[0] === "{" || ucc?.[0] === "[") {
  userCurrentCompany = JSON.parse(ucc);
}

const Kpi = ({
  chartTitle,
  chartLegend,
  tableRow,
  tableColumn,
  tableColumn1,
  chartData,
  chartPayload,
  setChartPayload,
  tableData,
  downloadExcel,
  getMonthUsers,
  kpiUsersData,
  kpiUsersDataTable,
  isPerMonthUser = true,
  }: any) => {
  const [kpi, setKPI] = useState<any>(chartPayload?.kpi);
  const [year, setYear] = useState<any>(chartPayload?.year);
  const [department_id, set_depertment_id] = useState<any>(
    chartPayload?.department_id
  );
  const [yearSelected, setYearSelected] = useState<any>({
    id: new Date().getFullYear(),
    label: new Date().getFullYear(),
    key: new Date().getFullYear(),
  });

  const wf_role_dept_bast = getWfRoleWithDept("1");
  const findWfRole = (role: any) => {
    return wf_role_dept_bast.find((el: any) => {
      return el.name === role && el.department_ids?.length > 0;
    })
  }
  const WfRoleSapAdmin = findWfRole("SAP Admin");
  const WfRoleContractInspector = findWfRole("Project Controller");
  const WfRoleContractOwner = findWfRole("Contract Owner");
  const kpiModule = () => {
    const isSapAdmin = window.location.pathname.includes('sap-admin-review-lead-time')
    const isContractInspector = window.location.pathname.includes('contract-inspector-review-lead-time')
    const isContractOwner = window.location.pathname.includes('contract-manager-review-lead-time')
    let deptId: any
    if (isSapAdmin) {
      deptId = WfRoleSapAdmin?.department_ids[0]
    }
    if (isContractInspector) {
      deptId = WfRoleContractInspector?.department_ids[0]
    }
    if (isContractOwner) {
      deptId = WfRoleContractOwner?.department_ids[0]
    }
    return deptId
  }

  const [departmentSelected, setDepartmentSelected] = useState<any>({
    id: null,
    label: "All",
    key: "All",
    value: null,
  });
  const [dataAsli, setDataAsli] = useState<any>([]);
  const [dataProp, setDataProp] = useState<any>({});
  console.log(dataAsli, "dataasli");
  console.log(dataProp, "dataprop");
  console.log(chartData, "chartdata");
  // const [dataTable, setDataTable] = useState<any>([]);
  const company_code = parseInt(
    JSON.parse(sessionStorage.getItem("current_company") as string).company_code
  );
  const [yearOption, setYearOption] = useState<any>([]);
  const [departmentOption, setDepartmentOption] = useState<any>([
    {
      id: null,
      label: "All",
      key: "All",
      value: null,
    },
  ]);
  const [openKpiUser, setOpenKpiUser] = useState(false);
  const [monthDataPoint, setMonthDataPoint] = useState<any>(null);
  // console.log(monthDataPoint)

  useEffect(() => {
    if (
      isPerMonthUser &&
      monthDataPoint !== null &&
      monthDataPoint !== undefined &&
      monthDataPoint >= 0
    ) {
      getMonthUsers(monthDataPoint);
    }
  }, [monthDataPoint]);

  useEffect(() => {
    const years = [];
    const year2 = new Date().getFullYear();
    for (let i = 2023; i <= year2; i++) {
      years.push({
        id: i,
        label: i.toString(),
        key: i,
      });
    }
    setYearOption(years);
  }, []);

  useEffect(() => {
    const getDepartments = async () => {
      try {
        const response = await fetch(`${API_URI}department-list`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization:
              "Bearer " +
              JSON.parse(sessionStorage.getItem("shreyu_user") as string)
                .access_token,
          },
          body: JSON.stringify({
            pagination: {
              pageIndex: 0,
              pageSize: 10000,
            },
            columnFilters: [
              {
                id: "company",
                value: userCurrentCompany?.company_initial,
              },
            ],
          }),
        });
        const dataFetch = await response.json();
        console.log(dataFetch);
        if (dataFetch?.data?.data) {
          const options = dataFetch?.data?.data
            .map((el: any) => {
              return {
                ...el,
                value: el.id,
                label: el.name,
              };
            })
            .filter((el: any) => {
              const isRemove =
                el.name === "Project Huu" ||
                el.name === "Corporate Marketing" ||
                el.name === "Senior Surveyor" ||
                el.name === "External Affairs & Sustainability" ||
                el.name === "Sustainability & External Affair" ||
                el.name === "Sustainability & External Affairs" ||
                el.name === "General Affair" ||
                el.name === "General Affairs" ||
                el.name === "Corporate Affair" ||
                el.name === "Corporate Affairs" ||
                el.name === "External Affairs & Sustainability";
              return !isRemove;
            });
          options.unshift({ id: null, label: "All", key: "All", value: null });
          // console.log(options);
          setDepartmentOption(options);
          const deptId = kpiModule()
          // console.log(deptId, "deptId");
          // console.log(options, "option");
          if (deptId) {
            const dept = options.find((el: any) => String(el.id) === deptId)
            // console.log(dept, "dept");
            setDepartmentSelected(dept)
            set_depertment_id(deptId)
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
    };

    getDepartments();
  }, []);

  // useEffect(() => {
  //   // setYear(yearSelected.label)
  //   // setKPI("7")
  //   // getData()
  //   // getData({ year, setDataAsli, kpi, setDataProp });
  //   getDataTable({
  //     year,
  //     setDataTable,
  //     kpi,
  //     company_code,
  //   });
  // }, [year, kpi]);

  useEffect(() => {
    setChartPayload((prev: any) => {
      if (
        prev?.kpi !== kpi ||
        prev?.year !== year ||
        prev?.department_id !== department_id
      ) {
        return {
          ...prev,
          kpi: kpi ? kpi : 0,
          year: year,
          department_id: department_id,
        };
      } else {
        return prev;
      }
    });
  }, [year, kpi, department_id]);

  return (
    <>
      <div className="mt-3"></div>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>{"Years"}</Form.Label>
            <Select
              name={"vendorCode"}
              options={yearOption}
              value={yearSelected}
              className={"react-select react-select-container"}
              classNamePrefix="react-select"
              isDisabled={false}
              // isClearable={true}
              // escapeClearsValue={true}
              onChange={(e: any) => {
                console.log(e);
                setYearSelected(e);
                setYear(e.label);
              }}
            ></Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>{"KPI value"}</Form.Label>
            <FormInput
              name="wht"
              type="text"
              hidden={false}
              placeholder="Input KPI"
              defaultValue={"7"}
              readOnly={false}
              value={kpi}
              onChange={(e) => {
                const z = () => {
                  const val = e.target.value.replace(/[^\d]+/g, "");
                  setKPI(val);
                  if (val && parseInt(val)) {
                    const a = [];
                    for (let i: any = 0; i <= 11; i++) {
                      a.push(parseInt(val));
                    }
                    setDataProp({
                      ...dataProp,
                      avgPlanLead: a,
                    });
                  }
                };
                z();
                // useDebounce(z, 1000)
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>{"Departments"}</Form.Label>
            <Select
              name={"department_id"}
              options={departmentOption}
              value={departmentSelected}
              className={"react-select react-select-container"}
              classNamePrefix="react-select"
              isDisabled={false}
              // isClearable={true}
              // escapeClearsValue={true}
              onChange={(e: any) => {
                console.log(e);
                setDepartmentSelected(e);
                set_depertment_id(e.value);
              }}
            ></Select>
          </Form.Group>
        </Col>
      </Row>
      {/* CHART */}
      <Row>
        {/* {userCurrentCompany.company_initial === "STM" && ( */}
        <Col md={12}>
          {/* <MILT_STM
            data={dataProp}
            // multiYaxisChartData={multiYaxisChartData}
            // showLoader={!isChatInitilized}
            /> */}
          <ChartKpi
            data={chartData}
            title={chartTitle ? chartTitle : "KPI"}
            legend={chartLegend}
            setMonthDataPoint={setMonthDataPoint}
            setOpenKpiUser={setOpenKpiUser}
            monthDataPoint={monthDataPoint}
            getMonthUsers={getMonthUsers}
            isPerMonthUser={isPerMonthUser}
          />
        </Col>
        {/* )} */}
        {/* {userCurrentCompany.company_initial === "VEI" && (
          <Col md={12}>
            <MILT_VEI
            // multiYaxisChartData={multiYaxisChartData}
            // showLoader={!isChatInitilized}
            />
          </Col>
        )} */}
      </Row>
      <Row>
        <Col md={12} className="mb-3">
          <KpiTable
            data={tableData}
            year={year}
            // delayTitle={tableRow?.delay ? tableRow?.delay : "Delay"}
            // ontimeTitle={tableRow?.onTime ? tableRow?.onTime : "Miro On Time"}
            delayTitle={"Approval Delay"}
            ontimeTitle={"Approval On Time"}
            // showLoader={!isChatInitilized}
          />
        </Col>
      </Row>
      <Row>
        <Button
          onClick={() => {
            // const Download = (arrayBuffer: any, type: any) => {
            //   var blob = new Blob([arrayBuffer], { type: type });
            //   var url = URL.createObjectURL(blob);
            //   window.open(url);
            // };
            // const company_code = parseInt(
            //   JSON.parse(sessionStorage.getItem("current_company") as string)
            //     .company_code
            // );

            // fetch(
            //   `${API_URI}dashboard/cek-miro?kpi=${kpi}&company_code=${company_code}&year=${year}`,
            //   {
            //     method: "GET",
            //     // body:JSON.stringify({
            //     //   "kpi": 7,
            //     //   "company_code": parseInt(JSON.parse(sessionStorage.getItem('current_company')as string).company_code)
            //     // }),
            //     redirect: "follow",
            //   }
            // ).then((response) => {
            //   response.blob().then((blob) => {
            //     let url = window.URL.createObjectURL(blob);
            //     let a = document.createElement("a");
            //     a.href = url;
            //     a.download = "Report_MIRO.xlsx";
            //     a.click();
            //   });
            // });
            downloadExcel && downloadExcel();
          }}
          className="vale-button"
        >
          Download Report
        </Button>
      </Row>
      <ModalKpi
        open={openKpiUser}
        onClose={() => setOpenKpiUser(false)}
        setOpen={setOpenKpiUser}
        legend={{ leftYAxis1: chartLegend?.leftYAxis1 }}
        data={kpiUsersData}
        dataTable={kpiUsersDataTable}
        year={year}
        monthDataPoint={monthDataPoint}
      />
    </>
  );
};

export default Kpi;
