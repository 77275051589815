// static data
import CreateCorporateIntegrity from "./CreateCorporateIntegrity";
import {useParams} from 'react-router-dom';
import { toast } from "react-toastify";
import { API_URI } from "../../constants";
import { useEffect, useState } from "react";
import { changeUStoID, getCompanySession, valueOptions } from "../../helpers/utils";

const AddCorporateIntegrity = () => {
  const dataAdd:any = {
    sap_company_number: {
      name: "",
      isDisabled: true,
    },
    subcompany_code: {
      name: '',
      isDisabled: false,
    },
    justification: {
      name: "",
      isDisabled: false,
    },
    vendor_code: {
      // name: "",
      name: '',
      isDisabled: false,
    },
    vendor_name: {
      name: "",
      isDisabled: true,
    },
    payment_amount: {
      name: "",
      isDisabled: false,
    },
    gl_no: {
      name: "",
      isDisabled: false,
    },
    wbs_no: {
      name: "",
      isDisabled: false,
    },
    is_vat: {
      name: '1',
      isDisabled: true,
      options: [{
        'key': '1',
        'label': "Yes",
        'value': '1'
      },{
        'key': '0',
        'label': "No",
        'value': '0'
      }],
    },
    vat_amount: {
      name: "0",
      isDisabled: false,
    },
    requested_by: {
      name: JSON.parse(sessionStorage.getItem("shreyu_user") as string).data?.name,
      isDisabled: true,
    },
    viewOnly: {
      name: false,
      isDisabled: false,
    },
    reference_number: {
      name: "",
      isDisabled: false,
    },
    reference_file: {
      name: "",
      isDisabled: false,
    },
    suporting_doc: {
      name: "",
      isDisabled: false,
    },
    // specialPaymentNumber: {
    //   name: "SPC00001",
    //   isDisabled: true,
    // }
  };
  const dataView = {};
  const sap_admin_disabled = [
    'special_payment_number',
    'sap_company_number',
    'vendor_name',
    'is_vat',
    'requested_by'
  ]
  const ci_fileds = [
    'referenceCi',
    'referenceFileCi',
    'ci_approval_date',
    'ci_activity_date'
  ]
  const {page, id} = useParams();
  const [dataUtama, setDataUtama] = useState<any>([]);
  const getDataVendor = async () => {
    // const response = await axios.get(API_URI + 'department/');
    fetch(API_URI + `special-payment/${id}`, {
      method: "GET"
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if(data && data.success){
        if(data.data) {
          console.log(data.data)
          const b:any = {};
          for (const [key, value] of Object.entries(data.data)) {
            b[key] = {
              name: value,
              isDisabled: false,
              isHide: false,
            }
            if(sap_admin_disabled.includes(key)) {
              b[key].isDisabled = true
            } else if(ci_fileds.includes(key) && data.data.is_ci !== "1") {
              b[key].isDisabled = true
            }
          }
          b["geoOrStm"] = {
            name: "mineral",
            isDisabled: false
          }
          b["amount_of_dpp"].name = changeUStoID(b["amount_of_dpp"].name)
          b["vat_amount"].name = changeUStoID(b["vat_amount"].name)
          b["is_vat"].options = [{
            // 'key': '1',
            'label': "Yes",
            'value': '1'
          },{
            // 'key': '0',
            'label': "No",
            'value': '0'
          }]
          b.is_vat.defaultValue = b.is_vat.options[valueOptions(b.is_vat.options, b.is_vat.name)]
          b.reference_number = b.referenceNumber;
          b.reference_file = b.referenceFile ? b.referenceFile : {name: '', isDisabled: false};
          delete b.referenceNumber
          delete b.referenceFile
          b.vendor_code = {
            name: b.vendor.name.vendor_code,
            isDisabled: false
          }
          b.vendor_name = {
            name: b.vendor.name.vendor_name,
            isDisabled: false
          }
          b.is_vat.name = b.vendor.name.is_vat
          b.mode = 'edit'
          console.log(b)
          setDataUtama(b);
        }else{
          setDataUtama([])
          // console.log(a)
        }
      }else{
        console.log(data.message);
        // setIsError(true);
        toast.error('Failed getting data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    })
    .catch(err => {
      console.log(err)
      toast.error('Failed getting data', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
    // console.log(response.json());
    // setDataApi(response);
  };
  useEffect(() => {
    if(page === 'create') {
      dataAdd.is_vat.defaultValue = dataAdd.is_vat.options[valueOptions(dataAdd.is_vat.options, dataAdd.is_vat.name)]
      dataAdd.sap_company_number.name = getCompanySession[0]
      dataAdd.mode = 'create'
      setDataUtama(dataAdd);
    } else if (page === 'edit') {
      getDataVendor();
    } else {
      setDataUtama(dataView)
    }
    // getDataDepartement();
    // axios.get(API_URI + 'department/').then((response)=> {
    //   console.log(response);
    // })
  }, [
    
  ]);
  return (
    <>
      <CreateCorporateIntegrity data={dataUtama} canApprove={false} mode={page}/>
    </>
  );
};

export default AddCorporateIntegrity;
