import Kpi from "./Kpi";
import { bastChartLegend } from "./data";
import { useKpiState } from "./hooks";

export default function ContractInspectorReviewLeadTime() {
  /* 
   ==================================================================================================================================================
   *  3 KPI Contract Inspector review Lead Time
      - per department
      "KPI Contract Inspector review Lead Time": {
        Description:
          "tanggal BAST diinput SES oleh SAP Admin sampai dengan Contract Inspector melakukan approval",
        // PAYLOAD
        payload: {
          kpi: 5,
          year: 2024,
          company_code: 1492,
          department_id: 1,
          approval1: 31,
          approval2: 2,
        },
        // RESPONSE DATA
        data: [
          // ... MONTH 1 - 12
          {
            month_no: "1",
            month_name: "Jan-2024",
            year_no: "2024",
            company_code: "1492",
            total_bast: "13",
            total_vendor: "4",
            lead_time: "4",
          },
        ],
      },
      // ========================================================================================
      "KPI Contract Inspector review Lead Time Per User": {
      // PAYLOAD
        payload: {
          kpi: 5,
          year: 2024,
          month: 4,
          company_code: 1492,
          department_id: 1,
          approval1: 31,
          approval2: 2,
        },
        // RESPONSE DATA
        data: [
          // ... ALL USER
          {
            user_name: "user co test email",
            ontime: 7,
            delay: 1,
            total: 8,
            percent_ontime: "87.5%",
            percent_delay: "12.5%",
          },
        ],
      },
   */
  const hooksData = useKpiState({approval1: 31, approval2: 2, isInvoice: false});

  const chartTitle = "KPI Contract Inspector review Lead Time";
  const chartLegend = bastChartLegend

  const props = {
    chartTitle,
    chartLegend,
    ...hooksData
  };

  return <Kpi {...props} />;
}
