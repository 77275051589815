import Kpi from "./Kpi";
import { useKpiState } from "./hooks";
import { invoiceChartLegend } from "./data";

export default function InvoiceCreationLeadTime() {
  /* ==================================================================================================================================================
   *  5 KPI Invoice Creation Lead Time
  "KPI Invoice Creation Lead Time": {
    Description:
      "perbandingan antara tanggal status BAST Vendor Input Invoice sampai dengan Vendor membuat Invoice.",
    // PAYLOAD
    payload: {
      kpi: 5,
      year: 2024,
      company_code: 1492,
      department_id: null,
      approval1: 4,
      approval2: 6,
    },
    // RESPONSE DATA
    data: [
      // ... MONTH 1 - 12
      {
        month_no: "1",
        month_name: "Jan-2024",
        year_no: "2024",
        company_code: "1492",
        total_bast: "13",
        total_vendor: "4",
        lead_time: "4",
      },
    ],
  },
  */
  const hooksData = useKpiState({approval1: 4, approval2: 6, isInvoice: false});

  const chartTitle = "KPI Invoice Creation Lead Time";
  const chartLegend = invoiceChartLegend

  const props = {
    chartTitle,
    chartLegend,
    ...hooksData,
    isPerMonthUser: false,
  };

  return <Kpi {...props} />;
}
