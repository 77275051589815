import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetUserRole } from "../../../hooks";
import { useTranslation } from "react-i18next";

// import { getUserVendorData } from "../../../helpers/getUserVendor"
// const { vendor_code, company_code, vendor_type } = getUserVendorData()

const ConfirmationModal = ({
  open,
  setOpen,
  modalType,
  bastStatus,
  getData,
  moveToList,
  deleteId,
  rowId,
  setBastItems,
  bastItemStatus,
  bastItems,
  bulkStatus,
  bastDetail,
}: any) => {
  // console.log(
  //   {
  //     modalType,
  //     bastStatus,
  //     getData,
  //     moveToList,
  //     deleteId,
  //     rowId,
  //     setBastItems,
  //     bastItemStatus,
  //     bastItems,
  //     bulkStatus,
  //   },
  //   "CONFIRMATION MODAL PROPS"
  // );
  // console.log(bastDetail);
  const { t } = useTranslation();
  const { userId } = useGetUserRole();
  const id = bastStatus?.id;
  const approval_status_id = bastStatus?.approval_status_id;
  const vendor_code = bastStatus?.vendor_code;
  const was_approval_status_id = bastStatus?.was_approval_status_id;
  const good_or_services = bastStatus?.good_or_services;
  const title =
    modalType === "delete"
      ? "Deletion"
      : modalType === "approve"
      ? "Approval"
      : modalType === "bulk approve"
      ? "Approval"
      : modalType === "reject"
      ? "Rejection"
      : modalType === "delete item"
      ? "Delete Item"
      : modalType === "approve item"
      ? "Approval Item"
      : modalType === "reject item"
      ? "Rejection Item"
      : "";
  const [reason, setReason] = useState("");

  const disabled =
    (approval_status_id === "5" && reason.length < 1) ||
    (bastItemStatus?.checking_status === 2 && reason.length < 1);
  let eButton: any;

  const navigate = useNavigate();

  const bulkUpdateStatusApproval = async (bulkStatus: any) => {
    // console.log(bulkStatus);
    // return
    try {
      const response: any = await axios({
        url: "/bast/approvals",
        method: "POST",
        data: bulkStatus,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization:
            "Bearer " +
            JSON.parse(sessionStorage.getItem("shreyu_user") as string)
              .access_token,
        },
      });
      console.log(response, "RES BAST STAT UPDATE");
      if (response?.data?.success) {
        getData();
        // setReason("");
        toast.success(t("Update Success."));
      } else {
        toast.error(t("Failed to Update BAST Status!"));
      }
    } catch (error) {
      toast.error(t("Failed to Update BAST Status!"));
    } finally {
      setOpen(false);
      eButton.disabled = disabled;
    }
  };
  const updateStatusApproval = async (obj: any) => {
    let { id, approval_status_id, comment, was_approval_status_id, good_or_services } = obj;

    let rejectIdWithStep: any
    if (was_approval_status_id && approval_status_id === "5") {
      if ((was_approval_status_id === "31" || was_approval_status_id === "2") && good_or_services === "2") {
        rejectIdWithStep = "434"
      } else if (good_or_services === "1" && was_approval_status_id === "2") {
        rejectIdWithStep = "5"
      } else {
        rejectIdWithStep = "5"
      }
    }
    const data = {
      bast_id: id,
      approval_status_id: was_approval_status_id ? rejectIdWithStep : approval_status_id,
      comment,
      module_id: 1,
      user_id: userId,
      vendor_code,
    };
    if (!(approval_status_id === "5")) delete data.comment;
    // console.log(data);
    try {
      const response: any = await axios({
        url: "/bast/approvals",
        method: "POST",
        data: [data],
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization:
            "Bearer " +
            JSON.parse(sessionStorage.getItem("shreyu_user") as string)
              .access_token,
        },
      });
      // console.log(response, "RES BAST STAT UPDATE");
      if (response?.data?.success) {
        if (moveToList) {
          navigate(-1);
        } else if (getData) {
          getData();
        }
        setReason("");
        toast.success(t("Update Success."));
      } else {
        toast.error(t("Failed to Update BAST Status!"));
      }
    } catch (error) {
      toast.error(t("Failed to Update BAST Status!"));
    } finally {
      setOpen(false);
      eButton.disabled = disabled;
    }
  };
  const deleteBast = async (obj: any) => {
    let { id } = obj;
    try {
      const response: any = await axios({
        url: "/bast/" + id,
        method: "POST",
        data: {
          _method: "DELETE",
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization:
            "Bearer " +
            JSON.parse(sessionStorage.getItem("shreyu_user") as string)
              .access_token,
        },
      });
      // console.log(response, "RES BAST STAT UPDATE");
      if (response?.data?.success) {
        if (moveToList) {
          navigate(-1);
        } else {
          getData();
        }
        setReason("");
        toast.success(t("Update Success."));
      } else {
        toast.error(t("Failed to Update BAST Status!"));
      }
    } catch (error) {
      toast.error(t("Failed to Update BAST Status!"));
    } finally {
      setOpen(false);
    }
  };
  const deleteBastItem = async (obj: any) => {
    // let { id } = obj;
    if (deleteId) {
      try {
        const response: any = await axios({
          url: "/bastdetail/" + deleteId,
          method: "POST",
          data: {
            _method: "DELETE",
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization:
              "Bearer " +
              JSON.parse(sessionStorage.getItem("shreyu_user") as string)
                .access_token,
          },
        });
        // console.log(response, "RES BAST STAT UPDATE");
        if (response?.data?.success) {
          setBastItems((prev: any[]): any => {
            prev.splice(rowId, 1);
            return [...prev];
          });
          setReason("");
          // toast.success(t("Update Success."));
        } else {
          toast.error(t("Failed to Update BAST Status!"));
        }
      } catch (error) {
        toast.error(t("Failed to Update BAST Status!"));
      } finally {
        setOpen(false);
      }
    } else {
      setBastItems((prev: any[]): any => {
        prev.splice(rowId, 1);
        return [...prev];
      });
      setReason("");
      setOpen(false);
    }
  };
  const approveBastItem = async () => {
    // let { id } = obj;
    const physical_picture = bastDetail?.getValues
      ? bastDetail.getValues("physical_picture")
      : "";
    const department = bastDetail?.getValues?.("department_id")?.value;
    const department_id = department ? department : bastDetail?.department_id;
    const data1: any = {
      approval_status_id: approval_status_id,
      //
      bast_no: bastDetail?.bast_no,
      good_or_services: bastDetail?.good_or_services,
      migo_or_ses: bastDetail?.migo_or_ses,
      gl_no: bastDetail?.gl_no,
      wbs_no: bastDetail?.wbs_no,
      posting_date: bastDetail?.posting_date,
      contract_sap: bastDetail?.contract_sap,
      po_no: bastDetail?.po_no,
      suplier_name: bastDetail?.suplier_name,
      delivery_note: bastDetail?.delivery_note,
      bill_of_leading: bastDetail?.bill_of_leading,
      airway_bill: bastDetail?.airway_bill,
      notes: bastDetail?.notes,
      contract_no: bastDetail?.contract_no,
      timesheet_file: bastDetail?.timesheet_file,
      sumary_reimburse_file: bastDetail?.sumary_reimburse_file,
      proforma_invoice_file: bastDetail?.proforma_invoice_file,
      delivery_order_file: bastDetail?.delivery_order_file,
      bill_of_leading_file: bastDetail?.bill_of_leading_file,
      airway_bill_file: bastDetail?.airway_bill_file,
      monthly_report_file: bastDetail?.monthly_report_file,
      pib_file: bastDetail?.pib_file,
      po_file: bastDetail?.po_file,
      contract_file: bastDetail?.contract_file,
      others_file: bastDetail?.others_file,
      company_sub_id: bastDetail?.company_sub_id,
      id: bastDetail?.id,
      company_code: bastDetail?.company_code,
      suplier_id: bastDetail?.suplier_id,
      grand_total: bastDetail?.grand_total,
      grand_total_idr: bastDetail?.grand_total_idr,
      currency_id: bastDetail?.currency_id,
      vendor_code: bastDetail?.vendor_code,
      vendor_type: bastDetail?.vendor_type,
      coscenter_no: bastDetail?.coscenter_no,
      currency_rate: bastDetail?.currency_rate,
      // department_id: bastDetail?.department_id,
      department_id,
      physical_picture: physical_picture
        ? physical_picture
        : bastDetail?.physical_picture,
      // have_invoice: bastDetail?.have_invoice,
      // invoice_id: bastDetail?.invoice_id,
      // doc_no: bastDetail?.doc_no,
      // monthly_report_file: bastDetail?.monthly_report_file,
    };

    const bast_detail = bastItems.map((el: any) => {
      const out: any = {
        is_reimbursement:
          el.is_reimbursement === "Yes" ? "1" : "0",
        description: el.description,
        uom: el.uom?.id,
        qty: el.qty,
        unit_price: el.unit_price,
        unit_price_in_idr: el.unit_price_in_idr,
        total_value: el.total_value,
        total_value_idr: el.total_value_idr,
        currency_id: el.currency_id,
        currency_rate: el.currency_rate,
        expired_date: el.expired_date,
        physical_picture: el.physical_picture,
        product_waranty_file: el.product_waranty_file,
        checking_status: el.checking_status,
        reason: el.reason,
        comment: el.comment,
        comment_by: el.comment_by,
        locator: el.locator,
      };
      if (el.id) {
        out.id = el.id;
      }
      if (el.bast_id) out.bast_id = el.bast_id;
      return out;
    });

    data1.bast_detail = bast_detail;
    // console.log(bast_detail)
    // console.log(data1)
    // return
    try {
      const response: any = await axios({
        url: "/bast",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization:
            "Bearer " +
            JSON.parse(sessionStorage.getItem("shreyu_user") as string)
              .access_token,
        },
        data: [data1],
      });
      console.log(response);
      if (response?.data?.success) {
        setReason("");
        getData();
        toast.success(t("Update Success."));
      } else {
        toast.warn(t("Failed to approve BAST item"));
      }
    } catch (error) {
      console.log(error);
      toast.error(t("Failed to approve BAST item"));
    } finally {
      setOpen(false);
      eButton.disabled = disabled;
    }
    return;
    const data: any = [];
    for (let a of bastItems) {
      const b: any = {
        id: a.id,
        checking_status: a.checking_status,
        // "reason ": "test",
        checking_by: a.checking_by,
      };
      if (a.reason) b.reason = a.reason;
      data.push(b);
    }
    // const shouldUpdateItemStatus = data.some((el: any) => el.checking_status)
    // console.log(data)
    // return
    try {
      let response: any;
      // if (shouldUpdateItemStatus) {
      response = await axios({
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization:
            "Bearer " +
            JSON.parse(sessionStorage.getItem("shreyu_user") as string)
              .access_token,
        },
        url: "/bast/warehouse",
        method: "POST",
        // data: [{ ...bastItemStatus }],
        data,
      });
      // }
      // const response1: any = await axios({
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //     "Access-Control-Allow-Origin": "*",
      //     Authorization:
      //       "Bearer " +
      //       JSON.parse(sessionStorage.getItem("shreyu_user") as string)
      //         .access_token,
      //   },
      //   url: "/bast/approvals",
      //   method: "POST",
      //   data: [
      //     {
      //       bast_id: id,
      //       approval_status_id: 2,
      //       comment: reason,
      //       module_id: 1,
      //       user_id: userId,
      //       vendor_code,
      //     },
      //   ],
      // });

      // console.log(response, "RES approveBastItem");
      // console.log(response1, "RES");
      // if (response?.data?.success || response1?.data?.success) {
      if (response?.data?.success) {
        setReason("");
        getData();
        toast.success(t("Update Success."));
      } else {
        toast.error(t("Failed to approve BAST item"));
      }
    } catch (error) {
      toast.error(t("Failed to approve BAST item"));
    } finally {
      setOpen(false);
      eButton.disabled = disabled;
    }
  };
  const rejectBastItem = async () => {
    const physical_picture = bastDetail?.getValues
      ? bastDetail.getValues("physical_picture")
      : "";
    // let { id } = obj;
    const department = bastDetail?.getValues?.("department_id")?.value;
    const department_id = department ? department : bastDetail?.department_id;

    let rejectIdWithStep: any
    if ((bastDetail?.approval_status_id === "31" || bastDetail?.approval_status_id === "2") && bastDetail?.good_or_services === "2") {
      rejectIdWithStep = "434"
    } else if (bastDetail?.good_or_services === "1" && bastDetail?.approval_status_id === "2") {
      rejectIdWithStep = "5"
    } else {
      rejectIdWithStep = "5"
    }
    
    const data1: any = {
      approval_status_id: rejectIdWithStep,
      //
      reason: reason,
      bast_no: bastDetail?.bast_no,
      good_or_services: bastDetail?.good_or_services,
      migo_or_ses: bastDetail?.migo_or_ses,
      gl_no: bastDetail?.gl_no,
      wbs_no: bastDetail?.wbs_no,
      posting_date: bastDetail?.posting_date,
      contract_sap: bastDetail?.contract_sap,
      po_no: bastDetail?.po_no,
      suplier_name: bastDetail?.suplier_name,
      delivery_note: bastDetail?.delivery_note,
      bill_of_leading: bastDetail?.bill_of_leading,
      airway_bill: bastDetail?.airway_bill,
      notes: bastDetail?.notes,
      contract_no: bastDetail?.contract_no,
      timesheet_file: bastDetail?.timesheet_file,
      sumary_reimburse_file: bastDetail?.sumary_reimburse_file,
      proforma_invoice_file: bastDetail?.proforma_invoice_file,
      delivery_order_file: bastDetail?.delivery_order_file,
      bill_of_leading_file: bastDetail?.bill_of_leading_file,
      airway_bill_file: bastDetail?.airway_bill_file,
      monthly_report_file: bastDetail?.monthly_report_file,
      pib_file: bastDetail?.pib_file,
      po_file: bastDetail?.po_file,
      contract_file: bastDetail?.contract_file,
      others_file: bastDetail?.others_file,
      company_sub_id: bastDetail?.company_sub_id,
      id: bastDetail?.id,
      company_code: bastDetail?.company_code,
      suplier_id: bastDetail?.suplier_id,
      grand_total: bastDetail?.grand_total,
      grand_total_idr: bastDetail?.grand_total_idr,
      currency_id: bastDetail?.currency_id,
      vendor_code: bastDetail?.vendor_code,
      vendor_type: bastDetail?.vendor_type,
      coscenter_no: bastDetail?.coscenter_no,
      currency_rate: bastDetail?.currency_rate,
      // department_id: bastDetail?.department_id,
      department_id,
      physical_picture: physical_picture
        ? physical_picture
        : bastDetail?.physical_picture,
      // have_invoice: bastDetail?.have_invoice,
      // invoice_id: bastDetail?.invoice_id,
      // doc_no: bastDetail?.doc_no,
      // monthly_report_file: bastDetail?.monthly_report_file,
    };

    const bast_detail = bastItems.map((el: any) => {
      const out: any = {
        is_reimbursement:
          el.is_reimbursement === "Yes" ? "1" : "0",
        description: el.description,
        uom: el.uom?.id,
        qty: el.qty,
        unit_price: el.unit_price,
        unit_price_in_idr: el.unit_price_in_idr,
        total_value: el.total_value,
        total_value_idr: el.total_value_idr,
        currency_id: el.currency_id,
        currency_rate: el.currency_rate,
        expired_date: el.expired_date,
        physical_picture: el.physical_picture,
        product_waranty_file: el.product_waranty_file,
        checking_status: el.checking_status,
        reason: el.reason,
      };
      if (el.id) {
        out.id = el.id;
      }
      if (el.bast_id) out.bast_id = el.bast_id;
      return out;
    });

    data1.bast_detail = bast_detail;
    // return console.log(bast_detail)
    try {
      const response: any = await axios({
        url: "/bast",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization:
            "Bearer " +
            JSON.parse(sessionStorage.getItem("shreyu_user") as string)
              .access_token,
        },
        data: [data1],
      });
      console.log(response);
      if (response?.data?.success) {
        setReason("");
        getData();
        toast.success(t("Update Success."));
      } else {
        toast.error(t("Failed to reject BAST item"));
      }
    } catch (error) {
      console.log(error);
      toast.error(t("Failed to reject BAST item"));
    } finally {
      setOpen(false);
      eButton.disabled = disabled;
    }

    return;
    const data: any = [];
    for (let a of bastItems) {
      const b: any = {
        id: a.id,
        checking_status: a.checking_status,
        // "reason ": "test",
        checking_by: a.checking_by,
      };
      if (a.reason) b.reason = a.reason;
      data.push(b);
    }
    // console.log(bastItems)
    // console.log(data)
    // return
    try {
      const response: any = await axios({
        url: "/bast/warehouse",
        method: "POST",
        // data: [{ ...bastItemStatus, reason }],
        data,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization:
            "Bearer " +
            JSON.parse(sessionStorage.getItem("shreyu_user") as string)
              .access_token,
        },
      });
      // console.log(response, "RES rejectBastItem");
      if (response?.data?.success) {
        setReason("");
        getData();
        toast.success(t("Update Success."));
      } else {
        toast.error(t("Failed to reject BAST item"));
      }
    } catch (error) {
      toast.error(t("Failed to reject BAST item"));
    } finally {
      setOpen(false);
      eButton.disabled = disabled;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          setOpen(false);
        }
      }}
      className="modal-approval-confirmation-class"
      // fullScreen={modalType === "reject" || modalType === "reject item"}
    >
      <DialogTitle>{title} Confirmation</DialogTitle>
      <DialogContent style={{ width: "100%", overflow: "hidden" }}>
        {modalType === "reject" ? (
          <div style={{ width: "100%" }}>
            <p>Please add your rejection reason.</p>
            <Form>
              <Form.Control
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                as="textarea"
                rows={8}
              />
              <Form.Text style={{ color: "red" }}>{t("*Required")}</Form.Text>
            </Form>
          </div>
        ) : modalType === "reject item" ? (
          <div style={{ width: "100%" }}>
            <p>Please add your rejection reason.</p>
            <Form>
              <Form.Control
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                as="textarea"
                rows={8}
              />
              <Form.Text style={{ color: "red" }}>{t("*Required")}</Form.Text>
            </Form>
          </div>
        ) : (
          <p>Are you sure you want to {modalType}?</p>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          className="btn-secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          No
        </Button>
        <Button
          className="vale-button"
          onClick={(e: any) => {
            eButton = e.target;
            eButton.disabled = true;
            if (modalType === "bulk approve") {
              bulkUpdateStatusApproval(bulkStatus);
            }
            if (modalType === "approve") {
              updateStatusApproval({
                id,
                approval_status_id,
                // approval_status_id: bastStatus?.isInputMigo
                //   ? 30
                //   : Number(approval_status_id) === 31
                //   ? "2"
                //   : approval_status_id
                //   ? String(Number(approval_status_id) + 1)
                //   : "1",
              });
            }
            if (modalType === "reject") {
              updateStatusApproval({
                id,
                approval_status_id: "5",
                comment: reason,
                was_approval_status_id,
                good_or_services,
              });
            }
            if (modalType === "delete") {
              deleteBast({ id });
            }
            if (modalType === "delete item") {
              deleteBastItem({ deleteId });
            }
            if (modalType === "reject item") {
              rejectBastItem();
            }
            if (modalType === "approve item") {
              approveBastItem();
            }
          }}
          disabled={disabled}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// const Alert = () => {
//   return (
//     <div
//       role="alert"
//       className="fade alert alert-danger alert-dismissible show"
//     >
//       <button
//         type="button"
//         className="btn-close"
//         onClick={() => {
//           // setShowAlert(false);
//         }}
//         aria-label="Close alert"
//       ></button>
//       A simple danger alert—check it out!
//     </div>
//   );
// };

export default ConfirmationModal;
