import Kpi from "./Kpi";
import { useKpiState } from "./hooks";
import { invoiceChartLegend } from "./data";

export default function BuktiPotongLeadTime() {
  /* ==================================================================================================================================================
   *  10 KPI Bukti Potong Lead Time
      - per department

      "KPI Bukti Potong Lead Time": {
        Description:
          "perbandingan antara tanggal Invoice dibayarkan dengan tanggal bukti potong diupload oleh Tax Admin pada tanggal 20 dibulan berikutnya",
        // PAYLOAD
        payload: {
          kpi: 5,
          year: 2024,
          company_code: 1492,
          department_id: 1,
          approval1: 11,
          approval2: null,
          approval3: 12,
          approval4: null,
        },
        // RESPONSE DATA
        data: [
          // ... MONTH 1 - 12
          {
            month_no: "1",
            month_name: "Jan-2024",
            year_no: "2024",
            company_code: "1492",
            total_invoice: "13",
            total_vendor: "4",
            lead_time: "4",
          },
        ],
      },
      "KPI Bukti Potong Lead Time Per User": {
      // PAYLOAD
        payload: {
          kpi: 5,
          year: 2024,
          month: 4,
          company_code: 1492,
          department_id: 1,
          approval1: 11,
          approval2: null,
          approval3: 12,
          approval4: null,
        },
        // RESPONSE DATA
        data: [
          // ... ALL USER
          {
            user_name: "user co test email",
            ontime: 7,
            delay: 1,
            total: 8,
            percent_ontime: "87.5%",
            percent_delay: "12.5%",
          },
        ],
      },
   */
  const hooksData = useKpiState({approval1: [11, 12], approval2: null});

  const chartTitle = "KPI Bukti Potong Lead Time";
  const chartLegend = invoiceChartLegend

  const props = {
    chartTitle,
    chartLegend,
    ...hooksData
  };

  return <Kpi {...props} />;
}
