import { Button, Card } from "react-bootstrap";
import DetailList3 from "../../components/MyComponents/Detail/DetailList3";
import logoVale from "../../assets/images/stm-logo.png";
import logoVei from "../../assets/images/logo-vale.png";
// import logoVale from "../../assets/images/logo-dashboard.png";
import HistoryComponent from "../../components/MyComponents/History/HistoryComponent";
// import {history} from "./historyData";
import { toast } from "react-toastify";
import { API_URI } from "../../constants";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { changeUStoID, getWorkflowRole, mergePDF, mineralOrGeo, roleSpecialPayment, valueOptions } from "../../helpers/utils";
import PaymentAdvice from "../../components/MyComponents/PaymentAdvice/PaymentAdvice";
import { Check, Close, Edit } from "@mui/icons-material";
import ConfirmationModal2 from "../../components/MyComponents/Modal/ConfirmationModal2";
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useTranslation, Trans } from 'react-i18next';
import Loading from "../../components/MyComponents/Loading";

const role = getWorkflowRole('3');

const dataView = {
  "Special Payment Number": "SPC00001",
  "SAP Company Number": "STM 1492", // VEI 156",
  "Geothermal / Mineral": "Geothermal",
  "Justification": "HERTZ Australia",
  "Vendor Detail": "SPE002-81",
  "Vendor Name": "FA",
  "Payment Amount": "IDR 50,000,000",
  GL: "5030500001",
  WBS: "R001828.04.08.19",
  "Vat Applicable": "Yes",
  // wht: '',
  // : ,
  // "Amount Of DPP": "PPH23 2% - 5010400013",
  "Amount Of VAT": "IDR 5,500,000",
  // "Amount Of WHT": "PPH23 2% - 5010300099",
  // "Is All Relevant Paperwork Attach": "Yes",
  "Requested By": "ZULFIKAR",
  "Supporting Document": "invoice.pdf",
  "Reference Number": "",
  "Reference File": "reference.pdf",
  "Payment Advise File": "payment.pdf",
  "All Document": "Doc-SPC00001.pdf"
};

const ViewInvoiceSAP = () => {
const { t, i18n } = useTranslation();
const changeIntoTitleValue = (data) => {
  const a = Object.entries(data);
  // console.log(a)
  const b = []
  for (const c of a) {
    // // console.log(a)
    if(c.length >= 2) {
      const d = Array.isArray(c[1]) ? c[1] : [c[1]]
      if((d.length === 2 && d[1] !== ' - ') || d.length === 1 && d[0] !== ' - ' || d.length === 3) {
        b.push({
          title: t(c[0]),
          value: d
        })
      }
    }
  }
  // console.log(b)
  return b
}
const navigate = useNavigate();
const {id} = useParams();
const [dataAsli, setDataAsli] = useState();
const [dataUtama, setDataUtama] = useState();
const [dataC, setDataC] = useState();
const [history, setHistory] = useState([]);
const [modalType, setModalType] = useState("");
const [openModal, setOpenModal] = useState(false);
const [isLoad, setIsLoad] = useState(false);
const [adaRole, setAdaRole] = useState(false);
const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
const [currentCompany, setCurrentCompany] = useState(JSON.parse(sessionStorage.getItem('current_company')));
const role = getWorkflowRole('3');
const session_storage = JSON.parse(sessionStorage?.getItem("shreyu_user"))
const [permission, setPermission] = useState({})
const permissions = session_storage?.data?.permissions;

const getIASPDF = ( () => {
  return new Promise(async(result, reject) => {
    // console.log('IN GETBASTFILE')
    const res = await fetch(API_URI + "special-payment-generate-ias-pdf/" + id,{
      method: "GET",
      // headers: {
      //   "Accept":"application/json",
      //   "Content-Type":"application/json",
      //   "Access-Control-Allow-Origin": "*"
      // }
    })
    // console.log(res)
    const blob = await res.blob()
    // console.log(blob)
    const reader = new FileReader();
    reader.onload = function () {
      // // console.log(this.result);
      if (this.result) {
        result(this.result);
        // setBastDetail((prev) => ({ ...prev, bast_file: this.result }));
        // allFile.push(this.result);
      }
    }; // <--- `this.result` contains a base64 data URI
    await reader.readAsDataURL(blob)
  })
})
const getSpecialPDF = ( () => {
  return new Promise(async(result, reject) => {
    // console.log('IN GETSP PDF')
    const res = await fetch(API_URI + "special-payment-generate-pdf/" + id,{
      method: "GET",
      // headers: {
      //   "Accept":"application/json",
      //   "Content-Type":"application/json",
      //   "Access-Control-Allow-Origin": "*"
      // }
    })
    // console.log(res)
    const blob = await res.blob()
    // console.log(blob)
    const reader = new FileReader();
    reader.onload = function () {
      // // console.log(this.result);
      if (this.result) {
        result(this.result);
        // setBastDetail((prev) => ({ ...prev, bast_file: this.result }));
        // allFile.push(this.result);
      }
    }; // <--- `this.result` contains a base64 data URI
    await reader.readAsDataURL(blob)
  })
})

const getData =(() => {
  setIsLoad(true);
  if(permissions) {
    // console.log('permissions:', permissions);
    for (const a of permissions){
      if (a.modules_id === '3'){
        // console.log('permission:' ,a);
        setPermission(a)
      }
    }
  }
  fetch(API_URI + `special-payment/${id}`, {
    method: "GET",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + JSON.parse(sessionStorage?.getItem('shreyu_user'))?.access_token,
    }
  })
  .then(response => response.json())
  .then(async(data) => {
    // console.log(data);
    if(data && data.success){
      if(data.data) {
        // console.log(data.data)
        const b = data.data
        let ketemu = false;
        if(role.role){
          for(const aa of role?.role){
            if(b.approval_status?.status_name?.includes(aa)){
              ketemu = true;
            }
          }
          b.canEdit = (
            b.approval_status?.status_name === 'Rejected' && 
            (b.approval_status?.id === 21 ||
            (b.approval_status?.id === 22)) && 
            role?.canEdit) ? true : false
          b.canDelete = (
            b.approval_status?.status_name === 'Rejected' && 
            (b.approval_status?.id === 21) && 
            role?.canDelete) ? true : false
          // if(b.approval_status_id == 15){
          //   // console.log(role?.directManager, role?.employee?.payroll_name,b.direct_manager)
          //   if(role?.employee?.payroll_name?.toLowerCase() === role?.directManager?.toLowerCase() && 
          //     b.approval_status_id == 15 && (role?.employee.payroll_name === b.direct_manager || role?.employee.payroll_name === b.assign_direct_manager)){
          //     // console.log('direct manager')
          //     setAdaRole(ketemu);
          //     b.canApprove = true
          //     b.canReject = true
          //     b.canEdit = false
          //     b.canDelete = false
          //   }else {
          //     // console.log('NOT direct manager')
          //     setAdaRole(false);
          //     b.canApprove = false
          //     b.canReject = false
          //     b.canEdit = false
          //     b.canDelete = false
          //   }
          // }else {
            // console.log('masuk else')
            setAdaRole(ketemu);
            if(b.approval_status?.status_name?.includes('President') && role?.role?.includes('President Director')){
              // console.log('di lvl direktur')
              b.canApprove = true;
              b.canReject = true;
            } else if(b.approval_status?.status_name?.includes('Payment Advice') && role?.role?.includes('Treasury')){
              b.canApprove = false;
              b.canReject = true;
            } else if(b.approval_status?.status_name?.includes('Approver FV60') && role?.role?.includes('Direct Manager')){
              b.canApprove = true;
              b.canReject = true;
            } else {
              b.canApprove = (b.approval_status?.status_name?.includes('Waiting')
                && ketemu && role?.canApprove) ? true : false
              b.canReject = (b.approval_status?.status_name?.includes('Waiting') 
                && ketemu && role?.canReject) ? true : false
            }
          // }
        }
        // console.log(b);
        setDataAsli(b);
        // getIASPDF().then(async(iasFile) => {
          // console.log("iasFile", iasFile);
        getSpecialPDF().then(async(specialPDF) => {
          const allFile = []
          if(b.paymentAdvice ) {allFile.push(b.paymentAdvice)}
          // if(iasFile ) {allFile.push(iasFile)}
          if(specialPDF ) {allFile.push(specialPDF)}
          if(b.referenceFile) {allFile.push(b.referenceFile)}
          if(b.suporting_doc) {allFile.push(b.suporting_doc)}
          if(b.referenceFileCi) {allFile.push(b.referenceFileCi)}
          b.paid_date = b.paid_date ? new Date(b.paid_date) : undefined;
          // console.log(b.paid_date);
          // // console.log("specialPDF", specialPDF)
          const all_doc = await mergePDF(allFile)
          const all_docs1 = [b.special_payment_number + '.pdf', all_doc, ' ']
          // const all_docs2 = [b.special_payment_number + '.pdf', all_doc, '']
          const c = {
            "All Document": all_docs1, // b.approval_status_id == 20 ? all_docs1 : all_docs2,
            "Special Payment Number": b.referenceNumber,
            "SAP Company Number": b.company.company_initial + ' ' + b.company.company_code,
            "Department": b.department?.name ? b.department?.name : ' - ',
            "Geothermal / Mineral": mineralOrGeo(b.subcompany_code),
            "Description": b.justification,
            "Vendor Detail": b.vendor.vendor_code,
            "Vendor Name": b.vendor.vendor_name,
            "Currency": b.currency_id ? b.currency_id : ' - ',
            "Payment Amount": changeUStoID(b.payment_amount),
            "Vat Applicable": b.is_vat === 1 ? "Yes" : "No",
            // "Amount Of DPP": "PPH23 2% - 5010400013",
            "Amount Of VAT": changeUStoID(b.vat_amount),
            "Amount Of WHT": changeUStoID(b.amount_of_wht),
            "WHT": b.wht_no,
            "Net Payment": changeUStoID(b.net_payment),
            GL: b.gl_no,
            WBS: b.wbs_no ? b.wbs_no : ' - ',
            'Cost Center': b.cost_center_id ? b.cost_center_id : ' - ',
            // "Amount Of WHT": "PPH23 2% - 5010300099",
            // "Is All Relevant Paperwork Attach": "Yes",
            "Requested By": b.user.name,
            "Reference Number": b.referenceNumber,
            // "IAS File": iasFile ? ['ias.pdf', iasFile] : [' - ', iasFile],
            "Supporting Document": b.suporting_doc ? ["Supporting_doc.pdf", b.suporting_doc]: ['Supporting_doc.pdf', ' - '],
            "Reference File": b.referenceFile ? ["Reference.pdf",b.referenceFile] : ["Reference.pdf", " - "],
            "Payment Advice": b.paymentAdvice ? ["Payment.pdf", b.paymentAdvice] : ["Payment.pdf", " - "],
            "Special Payment PDF": specialPDF ? [b.referenceNumber + ".pdf", specialPDF] : [b.referenceNumber + ".pdf", " - "],
            "FV60": b.fv60,
            "House Bank": b.house_bank ? b.house_bank : ' - ',
            "Account ID": b.account_id ? b.account_id : ' - ',
            "Payment Method": b.payment_method ? b.payment_method : ' - ',
            "Paid Date": b.paid_date ? dayjs(b.paid_date).format('DD MMM YYYY') : ' - ',
            // "All Document": ["All_Document.pdf",b.all_document]
            "Corporate Integrity Reference": b.referenceCi ? b.referenceCi : ' - ',
            "Corporate Integrity File": b.referenceFileCi ? ["CI_Reference.pdf",b.referenceFileCi] : ["CI_Reference.pdf", " - "],
            "Corporate Integrity Approval Date": b.ci_approval_date ? dayjs(b.ci_approval_date).format('DD MMM YYYY') : ' - ',
            "Corporate Integrity Activity Date": b.ci_activity_date ? dayjs(b.ci_activity_date).format('DD MMM YYYY') : ' - ',
          };
          setDataC(c);
          const items = changeIntoTitleValue(c);
          setDataUtama(items);
          setIsLoad(false);
        })
        // })
      }else{
        setDataUtama([])
        setIsLoad(false);
        // console.log('Tidak ada Data')
        // // console.log(a)
      }
    }else{
      // console.log(data.message);
      // setIsError(true);
      toast.error('Failed getting data', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsLoad(false);
    }
  })
  .catch(err => {
    // console.log(err)
    toast.error('Failed getting data', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setIsLoad(false);
  })
  // // console.log(response.json());
  // setDataApi(response);
})
const getHistory =(() => {
  setIsLoad(true);
  fetch(API_URI + `special-payment-history/${id}`, {
    method: "POST",
    body:JSON.stringify({
      sorting:[{
        id: 'created_at',
        desc: true
      }],
      pagination:{
        "pageIndex": 0,
        "pageSize": 1000
      }
    })
  })
  .then(response => response.json())
  .then(data => {
    // console.log(data);
    if(data && data.success){
      if(data.data && data.data.data) {
        // // console.log(data.data)
        const b = data.data.data
        // console.log(data.data.data)
        const c = []
        for (const a of b) {
          // console.log(role?.role);
          // // console.log(a)
          c.push({
            date: a.created_at,
            company: a.vendor?.vendor_name,
            user: a.user?.name,
            process: a.process,
            activity: a.read_status,
            comment: a.reason
          })
        }
        // console.log(c)
        setHistory(c);
        setIsLoad(false);
      }else{
        setHistory([])
        // console.log('Tidak ada Data')
        setIsLoad(false);
        // // console.log(a)
      }
    }else{
      // console.log(data.message);
      // setIsError(true);
      toast.error('Failed getting data history', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsLoad(false);
    }
  })
  .catch(err => {
    // console.log(err)
    toast.error('Failed getting data history', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setIsLoad(false);
  })
  // // console.log(response.json());
  // setDataApi(response);
})
useEffect(() => {
  // console.log('UseEffect kosong');
  getData()
  getHistory();
},[])

useEffect(() => {
  if(dataC) {
    // console.log('UseEffect t');
    const items = changeIntoTitleValue(dataC);
    setDataUtama(items);
  }
}, [t])
return (
  <div className="card mb-12 mt-3">
    {/* <Loading loading={load} /> */}
    {/* <div className="card-header bg-vale">Detail Special Payment</div> */}
    <div className="card-header bg-vale d-flex align-items-center justify-content-between">
      <p className="m-0">{t("Detail Special Payment")}</p>
      <div className="d-flex align-items-center ms-2">
        <p className="m-0" style={{ color: "white" }}>
          Status:
        </p>
        <div
          className={`status-${
            dataAsli?.approval_status?.status_name?.includes("Waiting") || dataAsli?.approval_status?.status_name?.includes("Input")
              ? "pending"
              : dataAsli?.approval_status?.status_name?.includes("Completed") ||
                dataAsli?.approval_status?.status_name?.includes("Paid") ||
                dataAsli?.approval_status?.status_name?.includes("Approved")
              ? "complete"
              : "reject"
          } ms-2`}
          style={{backgroundColor: `${dataAsli?.approval_status?.status_color}`}}
        >
          {/* <p>{dataAsli?.approval_status?.status_name}</p> */}
          <p>{dataAsli?.approval_status?.status_name}</p>
        </div>
      </div>
    </div>
    <div className="d-flex flex-column align-items-center mt-4 mb-3">
      { currentCompany.company_initial === 'STM' ?
      <img src={logoVale} height="100em" alt="" /> :
      <img src={logoVei} height="100em" alt="" />
      }
      <h5>{t("Special Payment")}</h5>
      {/* <h5 className="my-0">GOODS / SERVICES RECEIPT NOTE</h5> */}
    </div>
    <div className="card-body">
      <Card>
        <Card.Body>
          {dataUtama &&
          <DetailList3 items={dataUtama} />
          }
        </Card.Body>
      </Card>
    </div>
      <div
        style={{ display: "flex", justifyContent: "end", gap: "1rem" }}
        className="mx-3 mb-3"
      >
        {/* {role?.iasEdit && (
          <Button
            onClick={() => {
              navigate(`/sap/special-payment-ias/edit/${dataAsli.id}`);
            }}
            className="vale-button d-flex align-items-center justify-content-center"
          >
            <p className="m-0">{t("Edit IAS")}</p>
          </Button>
        )} */}
        {(
          (
            (permission.is_update === '1' || permission.is_create === '1') &&
            // (
            //   (
            //     dataAsli?.approval_status?.id === 21 && !role?.role.includes('Admin AP')
            //   ) ||
            //   (
            //     dataAsli?.approval_status?.id === 22 && role?.role?.includes('Admin AP')
            //   )
            // ) &&
            (dataAsli?.approval_status?.status_name === 'Rejected' &&
              dataAsli?.user?.id === role?.id) ||
            (dataAsli?.approval_status?.status_name?.includes('Department Manager') && dataAsli?.user?.id === role?.id) ||
            (dataAsli?.approval_status?.status_name?.includes('Approver FV60') && dataAsli?.first_approval === '1' && dataAsli?.user?.id === role?.id)
          )
          // (
          //   role?.role?.includes('Admin AP') && 
          //   dataAsli?.approval_status?.id === 16
          // ) 
          // dataAsli?.approval_status?.status_name === 'Rejected' &&
          // dataAsli?.approval_status?.status_name?.includes('SAP Admin')) &&

              // ((dataAsli?.approval_status?.id === 21) ||
              // (dataAsli?.approval_status?.id === 22 && role?.role?.includes('SAP Admin'))) && 
        ) && (
          <Button
            onClick={() => {
              // setModalType("reject");
              // setIsModalConfirmationOpen(true);
              // setDataReject(dataUtama);
              navigate(`/sap/special-payment/edit/${id}`)
            }}
            disabled={isLoad}
            className="edit-button d-flex align-items-center justify-content-center"
          >
            <p className="m-0">Edit</p>
            <Edit />
          </Button>
        )}
        {(dataAsli?.approval_status?.status_name?.includes('Waiting') && dataAsli?.canReject && !dataAsli?.approval_status?.status_name?.includes('Payment Advice')) && (
        
        <Button
          onClick={() => {
            setModalType("reject");
            setIsModalConfirmationOpen(true);
            // setDataReject(dataUtama);
          }}
          className="reject-button d-flex align-items-center justify-content-center"
          disabled={isLoad}
        >
          <p className="m-0">Reject</p>
          <Close />
        </Button>
        )}
        {(dataAsli?.approval_status?.status_name?.includes('Waiting') &&  dataAsli?.canApprove && !dataAsli?.approval_status?.status_name?.includes('Treasury')) && (
        <Button
          onClick={() => {
            setModalType("approve");
            setIsModalConfirmationOpen(true);
          }}
          className="vale-button d-flex align-items-center justify-content-center"
          disabled={isLoad}
        >
          <p className="m-0">Approve</p>
          <Check />
        </Button>
        )}
      </div>
    {((role?.role?.includes('Treasury') && dataAsli?.approval_status?.status_name?.includes('Payment Advice') )|| (role?.role?.includes('Treasury') && dataAsli?.approval_status?.status_name?.includes('Paid Completed')))&&(
    <PaymentAdvice data={dataAsli} getData={getData}
      // rejectButton={() => (
      //   <Button
      //     onClick={() => {
      //       setModalType("reject");
      //       setIsModalConfirmationOpen(true);
      //     }}
      //     className="reject-button d-flex align-items-center justify-content-center"
      //   >
      //     <p className="m-0">Reject</p>
      //     <Close />
      //   </Button>
      // )}
    />
    )}
    <div className="card mb-12">
      <div className="card-header bg-vale">
        <p className="m-0">{t("Special Payment History")}</p>
      </div>
      <HistoryComponent data={history} />
    </div>
    <ConfirmationModal2 
       open={isModalConfirmationOpen}
       setOpen={setIsModalConfirmationOpen}
       modalType={modalType}
       bastStatus={dataAsli}
       getData={getData}
       isNavigate={true}
       navigateUrl={`/sap/special-payment-list`}
    />
  </div>
);
    }
export default ViewInvoiceSAP;