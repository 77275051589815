import { useCallback, useEffect, useMemo, useState } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { MaterialReactTable, MRT_Cell, MRT_ColumnDef, MRT_ColumnFiltersState, MRT_PaginationState, MRT_Row, MRT_SortingState } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from "@mui/material";
import { Delete, Edit, Check, Close, FileUpload } from "@mui/icons-material";
import { Link } from "react-router-dom";
import CreateClaimNonCC from "./CreateClaimNonCC";
import { useTranslation, Trans } from 'react-i18next';
import { toast } from "react-toastify";
import { API_URI } from "../../constants";
import { changeUStoID, checkColumnFilters, getCompanySession, getWorkflowRoleEC, replaceToDot, roleEmployeeClaim, roleSpecialPayment, setApprovalAPI } from "../../helpers/utils";
import * as XLSX from 'xlsx/xlsx.mjs';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ConfirmationModal2 from "../../components/MyComponents/Modal/ConfirmationModal2";
import ConfirmationModalEC from "../../components/MyComponents/Modal/ConfirmationModalEC";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Loading from "../../components/MyComponents/Loading";
import FeatherIcon from "feather-icons-react";
import { checkNotificationNumber } from "../../helpers/utils2";

type InvoiceColumn = {
  id?: number;
  employee_claim_number?: string;
  doc_no?: string;
  subcompany_code?: string;
  department?: {
    id?: number;
    name?: string;
  }
  reason_travel_advance?: string;
  employee_id?: string;
  previous_form?: string;
  status: string;
  cash_advance: string;
  total_expense_claimed: string;
  difference: string;
  company_to_pay: string;
  return_to_company: string;
  action: string;
  canEdit: boolean;
  canDelete: boolean;
  canApprove: boolean;
  canReject: boolean;
  approval_status_id: string;
  currency_id: string;
  approval_status?:{
    id?:number;
    status_name?: string;
    status_color?: string;
  },
  approval_status_name?: string
  employee:{
    payroll_name: string;
  }
  created_at: Date;
  status_name?: string;
};

type ModalTypeState = "delete" | "approve" | "reject" | "";

function useDebounce(cb: any, delay: number = 1000) {
  const [debounceValue, setDebounceValue] = useState(cb);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(cb);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [cb, delay]);
  return debounceValue;
}

const ClaimNonCCList = () => {
  const { t, i18n } = useTranslation();
  const [isModalPaymentAdviceOpen, setIsModalPaymentAdviceOpen] = useState(false);
  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
  const [dataReject, setDataReject] = useState<any>({});
  const [modalType, setModalType] = useState<ModalTypeState>("");
  const [listEmployee, setListEmployee] = useState<any>([]);
  const [isError, setIsError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [permission, setPermission] = useState<any>({});
  const session_storage = JSON.parse(sessionStorage?.getItem("shreyu_user")as string)
  const permissions = session_storage?.data?.permissions;
  //table state
  const [rowCount, setRowCount] = useState(0);
  const colfil = JSON.parse(sessionStorage.getItem('ec') as string);
  const [columnFilters, setColumnFilters] = useState<any>(colfil ? colfil : []);
  const [globalFilter, setGlobalFilter] = useState('');
  // const [sorting, setSorting] = useState<MRT_SortingState>([{
  //   id: 'id',
  //   desc: true
  // }]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const pagi = JSON.parse(sessionStorage.getItem('ec-pagination') as string)
  console.log(pagi)
  const [pagination, setPagination] = useState<MRT_PaginationState>(
    pagi?.pageSize ? pagi : {
    pageIndex: 0,
    pageSize: 100,
  });
  const [rowSelection, setRowSelection] = useState({});
  const [CSVData, setCSVData] = useState<ExcelColumn[]>([]);
  const [CSVHead, setCSVHead] = useState();
  const [load, setLoad] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [file, setFile] = useState({});
  const [currentCompany, setCurrentCompany] = useState(JSON.parse(sessionStorage.getItem('current_company')as string));

  const [isExportMonth, setIsExportMonth] = useState(false);
  const [monthYear, setMonthYear] = useState("");
  const [start_date, set_start_date] = useState("");
  const [end_date, set_end_date] = useState("");
  const [allDepartment, setAllDepartment] = useState<any>(false);
  // const [debounceVal, setDebounceVal] = useState("");
  // const debounceValue = useDebounce(columnFilters, 1000);

  const submitImport = () => {
    let fileInput:any = {...file};
    const thisFile = fileInput.target.files[0];
    let reader = new FileReader();
    reader.onload = function(e: any) {
      const data = e.target.result;
      const workbook = XLSX.read(data, {cellDates: true, dateNF:"DD/MM/YYYY"});
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsa = XLSX.utils.sheet_to_json(sheet);
      const head:any = [];
      Object.keys(jsa[0]).forEach(function(key) {
        head.push(key);
      });
      for(const a of jsa) {
        dayjs.extend(customParseFormat)
        // console.log(a);
        // console.log(a.paidDate);
        a.paidDate = a.paidDate ? dayjs(a.paidDate).add(2,'hour').format('DD MMM YYYY')  : null
      }
      setCSVData(jsa);
      setCSVHead(head);
      setCreateModalOpen(true);
    };
    reader.readAsArrayBuffer(thisFile);
  }

  const checkValid = (head: any, jsa: any) => {
    let valid = true;
    for(let a in jsa) {
      for (const b of head) {
        let ketemu = false;
        for (let [key, value] of Object.entries(jsa[a])) {
            if(b === key) {
              if(value !== ''){
                ketemu = true;
              }
            }
        }
        if(!ketemu){
          // console.log(`${b} is required!`)
          // jsa[a][b] = `${b} is required!`
          valid = false;
        }
      }
    }
    setIsValid(valid);
    return valid;
  }

  const onChangeUploadCSV = (e: any) => {
    // console.log(e)
    setFile(e);
  }
  type ExcelColumn = {
    docNo: string,
    paidDate: Date
  }
  const handleSaveCell = (cell: MRT_Cell<ExcelColumn>, value: any) => {
    //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here
    // console.log(value);
    CSVData[cell.row.index][cell.column.id as keyof ExcelColumn] = value;
    //send/receive api updates here
    setCSVData([...CSVData]); //re-render with new data
    setIsValid(checkValid(CSVHead, CSVData));
  };

  const sendEditSpecialPayment = async (data2: any) => {
    // const response = await axios.get(API_URI + 'department/');
    // console.log(data2);
    fetch(API_URI + 'employee-claim-update', {
      method: "POST",
      body:JSON.stringify(data2),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
    .then(response => response.json())
    .then(data => {
      if(data.success) {
        // console.log(data);
        toast.success('Sucess Save Payment Advice')
        navigate(`/employee-claim/ec-list`)
      } else {
        toast.error('Failed To save Payment Advice')
      }
    })
    .catch(err => {
      // console.log(err)
      toast.error('Failed To save Payment Advice', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
    // // console.log(response.json());
    // setDataApi(response);
  };

  const handleAddUpdateApi = ((dataCreate: any) => {
    for(const a of dataCreate) {
      // console.log(a);
      a.doc_no = a.docNo;
      delete a.docNo;
      a.paid_date = dayjs(a.paidDate).format("YYYY-MM-DD");
      delete a.paidDate;
      a.user_id = JSON.parse(sessionStorage.getItem('shreyu_user') as string).data.id
    }
    sendEditSpecialPayment(dataCreate)
  })

  const [role, setRole] = useState(getWorkflowRoleEC('4'));

  const checkColumnFiltersCompanyCode = () => {
    console.log(columnFilters);
    if(columnFilters.length <= 1){
       return [
        {id: 'company', value: currentCompany.company_initial},
        // {id: 'employee_id',value: role.employee.payroll_id}
      ];
    } else {
      for (const a of columnFilters) {
        if(a.id === 'company') { 
          a.value = currentCompany.company_initial
        }
      }
      return columnFilters
    }
  }

  // console.log(role);
  const getData = async () => {
    setLoad(true);
    try{
      let colFilter: any = [];
      if(permissions) {
        // console.log('permissions:', permissions);
        for (const a of permissions){
          if (a.modules_id === '4'){
            // console.log('permission:' ,a);
            setPermission(a)
          }
        }
      }
      // console.log(columnFilters)
      colFilter = checkColumnFiltersCompanyCode();
      for (const a of colFilter) {
        if(a.id === 'created_at') {
          a.value = a.value.slice(-1) === '-' ? a.value.slice(0, -1) : a.value
        }
      }
      console.log(colFilter);
      fetch(API_URI + 'employee-claim-list', {
        method: "POST",
        headers:{
          "Authorization": 'Bearer ' + JSON.parse(sessionStorage.getItem('shreyu_user')as string).access_token,
          "Content-Type":"application/json",
          "Access-Control-Allow-Origin": "*"
        },
        body:JSON.stringify({
          // columnFilters:[
          //   ...columnFilters,{
          //     id: "company_code",
          //     value: currentCompany.company_initial
          //   }
          // ],
          columnFilters: colFilter,
          sorting,
          pagination,
          employee_id: role.employee?.payroll_id ? role.employee?.payroll_id : null,
          all_department: allDepartment
        })
      })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        if(data && data.success){
          if(data.data && data.data.data) {
            // console.log(data.data.data)
            const a = [];
            const c = [...data.data.data];
            // console.log(role);
            for(const b of c) {
              let ketemu = false;
              if(role.role){
                for(const aa of role?.role){
                  if(b.approval_status?.status_name?.includes(aa)){
                    ketemu = true;
                  }
                }
                // console.log('ketemu=', ketemu)
                // console.log(b.approval_status?.status_name)
                b.canEdit = (
                  b.approval_status?.status_name === 'Rejected' && 
                  ((b.approval_status?.id === 430 && (role?.employee?.id === b.employee?.id)))) && 
                  role?.canEdit ? true : false
                b.canDelete = (
                  b.approval_status?.status_name === 'Rejected' && 
                  (((b.approval_status?.id === 430 && role?.role?.includes('SAP Admin')))) && 
                  role?.canDelete) ? true : false
                if(role?.role?.includes('System Admin')){
                  // b.canApprove = (b.approval_status?.status_name?.includes('Waiting') && role?.canApprove) ? true : false
                  // b.canReject = (b.approval_status?.status_name?.includes('Waiting') && role?.canReject) ? true : false
                }else {
                  // console.log(role?.employee, b.direct_manager, b.approval_status_id)
                  if(b?.approval_status?.id === 423 &&
                      b.direct_manager === role?.employee?.payroll_name
                    ){
                      b.canApprove = true;
                      b.canReject = true;
                  }else if( role?.role?.includes('Finance Manager') && b?.approval_status?.id === 425 ){
                    b.canApprove = true
                    b.canReject = true
                  }else if( role?.role?.includes('SAP Admin')){
                    if( b?.approval_status?.id === 432 ) {
                      b.canApprove = false
                      b.canReject = true
                      b.canEdit = true
                    } else if( b?.approval_status?.id === 437 ) {
                      b.canApprove = false
                      b.canReject = true
                      b.canEdit = true
                    }
                  }else if( role?.role?.includes('SAP Admin Direct Report') && b?.approval_status?.id === 424 ){
                    console.log('Dapet SAP Admin Direct Report')
                    b.canApprove = true
                    b.canReject = true
                    console.log(b)
                    console.log(role);
                  }else if( role?.role?.includes('FV60 Releaser') && b?.approval_status?.id === 435 ){
                    b.canApprove = true
                    b.canReject = true
                  }else if( role?.role?.includes('Admin AP') && b?.approval_status?.id === 436 ){
                    b.canApprove = true
                    b.canReject = true
                  }else if( role?.role?.includes('Treasury') && b?.approval_status?.id === 431 ){
                    b.canApprove = false
                    b.canReject = false
                  }// else {
                    // console.log('masuk ke else2')
                  //   b.canApprove = (b.approval_status?.status_name?.includes('Waiting') && ketemu && role?.canApprove) ? true : false
                  //   b.canReject = (b.approval_status?.status_name?.includes('Waiting') && ketemu && role?.canReject) ? true : false
                  // }
                }
              }
              // console.log(role?.canApprove)
              // console.log(b)
              let hasil = true;
              if(b.claim_details){
                for(const d of b.claim_details) {
                  if (d.status === '2') {
                    hasil = false;
                    break;
                  }
                }
              }
              // if(hasil === false){
                // b.canApprove = false;
                // b.canReject = true;
              // }
              b.subcompany_code = b.subcompany_code === '001' ? 'Mineral' : 'Geothermal'
              // if(role?.employee?.payroll_id == b.employee?.payroll_id) {
              //   b.canEdit = true;
              // }
              // b.canEdit = true;
              a.push(b)
            }
            // console.log(c)
            checkNotificationNumber(document)
            setListEmployee(JSON.parse(JSON.stringify([...c])));
            setRowCount(data.data.total)
            // console.log(data.data.data)
            setIsError(false);
            setLoad(false)
          }else{
            setListEmployee([]);
            setRowCount(0)
            // // console.log(a)
            setIsError(false);
            setLoad(false)
          }
        }else{
          // console.log(data.message);
          setIsError(true);
          setLoad(false)
          // toast.error('Failed getting data', {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          // });
        }
      })
      .catch(err => {
        // console.log(err)
        setIsError(true);
        toast.error('Failed getting data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoad(false)
      })
    }catch(err){
      setIsError(true);
      toast.error('Failed getting data', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoad(false)
    }
    // // console.log(response.json());
    // setDataApi(response);
  };
  const handleDeleteRow = useCallback(
    (row: MRT_Row<InvoiceColumn>) => {
      if (
        !window.confirm(
          `Are you sure you want to delete ${row.getValue("doc_no")}`
        )
      ) {
        return;
      }
      fetch(API_URI + `employee-claim/${row.original.id}`, {
        method: "POST",
        body: JSON.stringify({
          "_method": "DELETE"
        }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        toast.success('Success Removing data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        getData()
      })
      .catch(err => {
        // console.log(err)
        setIsError(true);
        toast.error('Failed Removing data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
    },
    [listEmployee]
  );
  const navigate = useNavigate();

  const columnMRT = useMemo<MRT_ColumnDef<InvoiceColumn>[]>(
    () => [
      {
        accessorKey: "created_at",
        header: t("Posting Date"),
        size: 80,
        Cell: ({ row, cell }) => {
          const a:any = cell.getValue();
          return(<div> {dayjs(a).format('DD MMM YYYY')}</div>)
        }
      },
      {
        accessorKey: "employee_claim_number",
        header: t("Doc No"),
        size: 80,
      },
      {
        accessorKey: "employee.payroll_name",
        header: t("Employee Name"),
        size: 80,
      },
      {
        accessorKey: "subcompany_code",
        header: t("Mineral or Geothermal"),
        size: 80,
        filterSelectOptions:[{ text: "Mineral", value: "001" }, {text: "Geothermal", value: '002'}],
        filterVariant: 'select',
      },
      {
        accessorKey: "department.name",
        header: t("Department"),
        size: 80,
      },
      {
        accessorKey: "currency_id",
        header: t("Currency"),
        size: 80,
      },
      {
        accessorKey: "cash_advance",
        header: t("Cash Advance"),
        size: 80,
        muiTableBodyCellProps: ({
          cell
        }) => ({
          sx: {
            textAlign: 'right'
          }
        }),
        Cell: ({ row, cell }) => {
          const value = cell.getValue();
          // console.log(value)
          // console.log(changeUStoID(value))
          return(<div> {changeUStoID(cell.getValue())}</div>)
        }
      },
      {
        accessorKey: "total_expense_claimed",
        header: t("Total Expense Claimed"),
        size: 250,
        muiTableBodyCellProps: ({
          cell
        }) => ({
          sx: {
            textAlign: 'right'
          }
        }),
        Cell: ({ row, cell }) => {
          return(<div> {changeUStoID(cell.getValue())}</div>)
        }
      },
      {
        accessorKey: "company_to_pay",
        header: t("Company To Pay"),
        size: 80,
        muiTableBodyCellProps: ({
          cell
        }) => ({
          sx: {
            textAlign: 'right'
          }
        }),
        Cell: ({ row, cell }) => {
          return(<div> {changeUStoID(cell.getValue())}</div>)
        }
      },
      {
        accessorKey: "return_to_company",
        header: t("Return to Company"),
        size: 80,
        muiTableBodyCellProps: ({
          cell
        }) => ({
          sx: {
            textAlign: 'right'
          }
        }),
        Cell: ({ row, cell }) => {
          return(<div> {changeUStoID(cell.getValue())}</div>)
        }
      },
      {
        accessorKey: "approval_status.status_name",
        // accessorKey: "status_name",
        header: t("Status"),
        size: 80,
        filterSelectOptions:[
          { text: "Waiting Review Direct Reporting Line", value: 'Direct Reporting Line'},
          { text: "Waiting Review Finance Manager", value: 'Finance Manager'},
          { text: "Waiting Input FV60", value: "Input FV60" },
          { text: "Waiting Review SAP Admin Direct Report", value: 'SAP Admin Direct'},
          { text: "Waiting Review Releaser FV60", value: 'Releaser FV60'},
          { text: "Waiting Review Admin AP", value: 'Admin AP'},
          // { text: "Waiting Review Treasury", value: "Treasury" },
          { text: "Waiting Payment Advice", value: "Payment Advice" },
          // {text: "Waiting Review Direct Reporting Line", value: 'Direct Reporting Line'},
          // { text: "Waiting Review President Director", value: "President Director" }, 
          { text: "Rejected", value: "Rejected" },
          { text: "Rejected > 30 days", value: "Rejected > 30 days" },
          { text: "Paid Complete", value: "Paid Complete" },
          { text: "Completed", value: "Completed" },
        ],
        filterVariant: 'select',
        Cell: ({ row, cell }) => {
          const value = String(cell.getValue());
          const isIncludeAppr = value.includes("eview");
          const isIncludeAppr2 = value.includes("nput");
          return (
            <div
              className={`status-default invoice-${
                isIncludeAppr || isIncludeAppr2
                  ? "waiting-approval"
                  : value.toLowerCase().split(" ").join("-")
              }`}
              style={{backgroundColor: row.original.approval_status?.status_color}}
            >
              <p>{value}</p>
            </div>
          );
        },
      },
    ],
    [listEmployee, t]
  );
  const [columnOrder, setColumnOrder] = useState<any>(
    ['mrt-row-actions', 'mrt-row-select', ...columnMRT.map((c) => c.accessorKey)],
  );

  const checkFilter = () => {
    let ketemu = false;
    let colFilter: any=[];
    for(const a of columnFilters) {
      if (a.id === 'company') {
        ketemu = true;
      }
    }
    colFilter = checkColumnFiltersCompanyCode();
    if (ketemu) {
      console.log('ketemu company')
      console.log(columnFilters)
      console.log(colFilter)
      checkColumnFilters(colFilter, 'ec')
      checkColumnFilters(columnOrder, 'ec-column-order')
      checkColumnFilters(pagination, 'ec-pagination')
    } else {
      const a = JSON.parse(sessionStorage.getItem('ec') as string)
      const a2 = JSON.parse(sessionStorage.getItem('ec-column-order') as string)
      const a3 = JSON.parse(sessionStorage.getItem('ec-pagination') as string)
      if(a3){
        setPagination(a3)
      } else {
        setPagination({ pageIndex: 0, pageSize: 100})
      }
      if(a2){
        setColumnOrder(a2)
      }
      if(a){
        for(const b of a) {
          if (b.id === 'company') {
            b.value = currentCompany.company_initial
          }
        }
        setColumnFilters(a)
        // colFilter.push({id: 'company', value: currentCompany.company_initial})
      } else {
        setColumnFilters([{id: 'company', value: currentCompany.company_initial}])
        // colFilter.push({id: 'company', value: currentCompany.company_initial})
      }
    }
  }

  // checkFilter();

  useEffect(() => {
    // console.log('columnFilters', columnFilters)
    // console.log('sorting', sorting)
    // console.log('globalFilter', globalFilter)
    // console.log('pagination', pagination)
    checkFilter();
    getData();
    // console.log(role);
    // console.log(getCompanySession)
    // getDataDepartement();
    // axios.get(API_URI + 'department/').then((response)=> {
    //   // console.log(response);
    // })
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    allDepartment,
  ]);

  return (
    <>
      <div className="mb-3"></div>
      <Loading loading={load} />
      <Row className="mt-3">
        {isExportMonth ? (
          <Col md={4} className="mb-2">
            <Form.Group>
              <Form.Label>{t("Choose Month")}</Form.Label>
              <Form.Control
                type="month"
                onChange={(e) => {
                  setMonthYear(e.target.value);
                }}
                max={new Date().toISOString().split("T")[0].substring(0,7)}
                value={monthYear}
              />
            </Form.Group>
          </Col>
        ) : (
          <>
            <Col md={2} className="mb-2">
              <Form.Group>
                <Form.Label>{t("From Date")}</Form.Label>
                <Form.Control
                  type="date"
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    set_start_date(e.target.value);
                  }}
                  value={start_date}
                />
              </Form.Group>
            </Col>
            <Col md={2} className="mb-2">
              <Form.Group>
                <Form.Label>{t("To Date")}</Form.Label>
                <Form.Control
                  type="date"
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    set_end_date(e.target.value);
                  }}
                  value={end_date}
                />
              </Form.Group>
            </Col>
          </>
        )}
        <Col md={2} className="mb-2 d-flex align-items-end">
          <Button
            style={{ fontWeight: "bold" }}
            className="w-100 rounded-pill"
            variant="secondary"
            onClick={() => setIsExportMonth(!isExportMonth)}
          >
            {t("Choose")} {isExportMonth ? t("Date Range") : t("Month")}
          </Button>
        </Col>
        <Col md={2} className="mb-2 d-flex align-items-end">
          <Button
            className="w-100 vale-button d-flex align-items-center justify-content-center"
            onClick={async () => {
              // // console.log(start_date)
              // // console.log(end_date)
              // // console.log(monthYear)
              // // console.log(my)
              let params = "";
              let company_code = JSON.parse(sessionStorage.getItem('current_company')as string).company_code
              let my = ''
              if (isExportMonth) {
                const moye = new Date(monthYear);
                my = `${moye.toLocaleString("default", {
                  month: "long",
                })} ${moye.getFullYear()}`;
                params = `?company_code=${company_code}&monthYear=${my}`;
              } else {
                if (!start_date || !end_date) {
                  return toast.warn("Please select date range");
                }
                const oneDay = 24 * 60 * 60 * 1000;
                let firstDate: any = start_date
                let secondDate: any = end_date
                // const diffDays =
                //   Math.round((secondDate - firstDate) / oneDay) + 1;
                // console.log(diffDays);
                // if (diffDays > 31) {
                //   return toast.warn("Date range must be less than 31 days");
                // }
                // if (!diffDays || diffDays < 1) {
                //   return toast.warn(
                //     "Date range must be greater than 0 days"
                //   );
                // }
                if(new Date(end_date) < new Date(start_date)){
                  const tempMonth = end_date;
                  firstDate = tempMonth
                  secondDate = start_date
                  set_end_date(start_date);
                  set_start_date(tempMonth);
                }
                params = `?company_code=${company_code}&start_date=${firstDate}&end_date=${secondDate}`;
              }
              if (allDepartment) params += "&dept=all";
              // return;
              const res = await fetch(
                API_URI + `employee-claim-export-data${params}`,
                {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization:
                      "Bearer " +
                      JSON.parse(
                        sessionStorage.getItem("shreyu_user") as string
                      ).access_token,
                  },
                }
              );
              const blob: any = await res.blob();
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement("a");
              a.href = url;
              // a.download = "Export_Employee_Claim.xlsx";
              if(isExportMonth){
                my = my.replace(' ', '_');
                a.download = `Employee_Claim_${my}.xlsx`;
              } else {
                a.download = `Employee_Claim_${start_date}_${end_date}.xlsx`;
              }
              a.click();
            }}
          >
            <FeatherIcon icon="download" />{" "}
            <span className="ms-2">{t("Export Employee Claim")}</span>
          </Button>
        </Col>{" "}
        {permission?.is_general === "1" && (
          <Col className="d-flex align-items-center justify-content-start pt-2">
            <div className="d-flex align-items-center justify-content-start">
              <Form.Check
                type="switch"
                label={t("All")}
                checked={allDepartment}
                className="custom-switch"
                onClick={() => setAllDepartment(!allDepartment)}
              />
            </div>
          </Col>
        )}
      </Row>
      <Row className="mt-3">
        <Col>
          <Card>
            <div className="card-header bg-vale">List Employee Claim Non CC</div>
            <MaterialReactTable
              columns={columnMRT}
              //   enableSelectAll={true}
              //   enableRowSelection={true}
              //   enableMultiRowSelection={true}
              enableSelectAll={role?.canMultiple}
              enableMultiRowSelection={role?.canMultiple}
              enableRowSelection={(row) => {
                // console.log(row.original);
                // console.log(rowSelection);
                return row.original.canApprove
              }}
              enableGlobalFilter={false}
              enableColumnDragging={true}
              enableColumnOrdering={true}
              enableStickyHeader={true}
              // enableStickyFooter={true}
              manualFiltering
              manualPagination
              manualSorting
              onColumnFiltersChange={setColumnFilters}
              // onGlobalFilterChange={setGlobalFilter}
              onPaginationChange={setPagination}
              onSortingChange={setSorting}
              enableEditing
              enablePinning
              // enableRowNumbers
              rowCount={rowCount}
              onRowSelectionChange={setRowSelection}
              // muiTableHeadCellFilterTextFieldProps={({ column }) => (
              //   {
              //     variant: "standard",
              //     placeholder: column.columnDef.header === "Posting Date" ? "yyyy-mm-dd" : `Filter By ${column.columnDef.header}`,
              //   }
              // )}
              state={{
                rowSelection,
                columnFilters,
                // globalFilter,
                // isLoading,
                pagination,
                showAlertBanner: isError,
                // showProgressBars: isRefetching,
                sorting,
                columnOrder
              }}
              onColumnOrderChange={setColumnOrder}
              initialState={{ 
                density: 'compact',
                showColumnFilters: true,
                columnPinning: {
                  left: ["mrt-row-select", "mrt-row-actions"],
                  right: ["approval_status.status_name"] 
                }, 
                columnVisibility: { 
                  subcompany_code: currentCompany.company_initial === 'STM' ? true : false,
                  id: false
                },
              }}
              data={listEmployee}
              muiTableBodyCellProps={({ cell, column, row }) => ({
                onClick: (event) => {
                  // // console.log(cell.column.id);
                  // // console.log('Cell: ', cell);
                  // console.log("column: ", column.id);
                  // console.log("row: ", Object.keys(row.original));
                  // // console.log('table : ', table );
                  if (Object.keys(row.original).includes(column.id)) {
                    navigate(`/employee-claim/ec-view/${row.original.id}`, {
                      state: row.original,
                    });
                    // // console.log(cell.getValue())
                    // // console.log(row.original);
                  }
                },
                // sx: {cursor: "pointer"}
                sx: {
                  cursor: Object.keys(row.original).includes(column.id)
                    ? "pointer"
                    : "", //you might want to change the cursor too when adding an onClick
                },
              })}
              renderTopToolbarCustomActions={() => (
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  {(permission.is_create === '1') && (
                  <Button
                    onClick={() => navigate("/employee-claim/ec/create/1")}
                    className="vale-button"
                  >
                    {t(`Create New`)}
                  </Button>
                )}
                {(role?.role?.includes('Treasury')) && (
                  <Button
                    // onClick={() => navigate("/sap/special-payment/create")}
                    onClick={() => setIsModalPaymentAdviceOpen(true)}
                    className="vale-button"
                  >
                    {t(`Import Paid Date`)}
                  </Button>
                )}
                </Box>
              )}
              renderRowActions={({ row, table }) => (
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  {(((permission.is_update === '1' && 
                    (((row?.original?.approval_status?.status_name === 'Rejected' && row?.original?.approval_status?.id !== 437) || row?.original?.approval_status?.id === 423) &&
                      row?.original?.employee_id === role?.employee.payroll_id
                    )) ||
                    // (row?.original?.approval_status?.id === 424 && role?.role.includes('Admin AP') ) ||
                    (
                      ( row?.original?.approval_status?.id === 432 && role?.role.includes('SAP Admin') ) ||
                      ( row?.original?.approval_status?.id === 437 && role?.role.includes('SAP Admin') )
                    ) && row?.original?.company_to_pay !== "0,00"
                    )) &&
                    (
                    <Tooltip arrow placement="left" title={t("Edit")}>
                      <IconButton
                        onClick={() => navigate(`/employee-claim/ec/edit/${row.original.id}`)}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  )}
                  {permission.is_delete === '1' && 
                    ((row?.original?.approval_status?.status_name === 'Rejected'  && row?.original?.approval_status?.id !== 437)|| row?.original?.approval_status?.id === 423) &&
                    row?.original?.employee_id === role?.employee.payroll_id && (
                    <Tooltip arrow placement="right" title={t("Delete")}>
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteRow(row)}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  )}
                  {((
                    row.original.canApprove && !row?.original?.approval_status?.status_name?.includes('Payment Advice')
                  ) || ((
                    ( row?.original?.approval_status?.id === 432 && role?.role.includes('SAP Admin') ) ||
                    ( row?.original?.approval_status?.id === 437 && role?.role.includes('SAP Admin') )
                  ) && row?.original?.company_to_pay === "0,00")
                )&& (
                    <Tooltip arrow placement="right" title={t("Approve")}>
                      <IconButton disabled={load} onClick={() => {
                        setLoad(true);
                        // console.log(row.original)
                        // console.log("rowSelection",rowSelection)
                        const a = []
                        const d = []
                        const f = []
                        if(rowSelection){
                          for (const [key, value] of Object.entries(rowSelection)) {
                            a.push(listEmployee[key].id)
                            d.push(listEmployee[key].employee_id)
                            f.push(listEmployee[key].approval_status_id)
                          }
                        }
                        // console.log(a);
                        let id, approval_status_id, approved, reason, employee_id
                        if(a.length >= 1) {
                          id = a
                          employee_id = d
                          approval_status_id = f
                        } else {
                          id = [row.original.id]
                          employee_id = [row.original.employee_id]
                          approval_status_id = [row.original.approval_status_id]
                        }
                        setApprovalAPI([{
                          id: id,
                          approval_status_id,
                          approved: true,
                          reason: null,
                          approved_by: JSON.parse(sessionStorage?.getItem("shreyu_user")as string)?.data?.id,
                          employee_id: employee_id,
                          previous_form: row.original?.previous_form
                          // employee_id: JSON.parse(sessionStorage.getItem('shreyu_user')as string).data.employee.payroll_id
                        }], "employee-claim-approval").then(() => {
                          toast.success('Success: Approved', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          setRowSelection([])
                          getData();
                          setLoad(false);
                          // navigate(`/sap/special-payment-list`);
                        }).catch(() => {
                          toast.error('Failed to Approve', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          setLoad(false);
                        })
                      }}>
                        <Check />
                      </IconButton>
                    </Tooltip>
                  )}
                  {row.original.canReject  && !row?.original?.approval_status?.status_name?.includes('Payment Advice') && (
                    <Tooltip arrow placement="right" title={t("Reject")}>
                      <IconButton
                        color="error"
                        onClick={() => {
                          // console.log(row.original)
                          setModalType("reject");
                          setIsModalConfirmationOpen(true);
                          setDataReject(row.original);
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              )}
            />
          </Card>
        </Col>
      </Row>
      <InputPaymentAdviceModal
        open={isModalPaymentAdviceOpen}
        onClose={() => setIsModalPaymentAdviceOpen(false)}
        onSubmit={() => submitImport()}
        onFileUpload={(e: any) => onChangeUploadCSV(e)}
      />
      <ValidateExcelModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={(data: any[]) => handleAddUpdateApi(data)}
        CSV={CSVData!}
        CSVHead={CSVHead!}
        onCellEdit={(cell, value) => handleSaveCell(cell, value)}
      />
      <ConfirmationModalEC
        open={isModalConfirmationOpen}
        setOpen={setIsModalConfirmationOpen}
        modalType={modalType}
        bastStatus={dataReject}
        getData={getData}
      />
    </>
  );
};

const InputPaymentAdviceModal = ({
  open,
  onClose,
  onSubmit,
  setItem,
  item,
  onFileUpload
}: any) => {
  const { t, i18n } = useTranslation();
  const handleSubmit = () => {
    //put your validation logic here
    onSubmit();
    onClose();
  };
  const templateFile = API_URI  + 'employee-claim-export';

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">{t("Import Payment Date")}</DialogTitle>
      <DialogContent>
        <Row>
            <Col>
            <a href={templateFile} target="_blank" rel="noopener noreferrer" download>
                <Button>
                    {t("Download Template")}
                </Button>
            </a>
            </Col>
        </Row>
        <br></br>
        <Form>
          {/* <Form.Group className="mb-3" controlId="paymentAdvice">
            <Form.Label>Invoice No.</Form.Label>
            <Form.Control
              type="text"
              placeholder="Input File"
              defaultValue={"SMI-23080071"}
              disabled
              // disabled={props.data.paymentAdvice.isDisabled}
            />
          </Form.Group> */}
          <Form.Group className="mb-3" controlId="paymentAdviceDateFile">
            <Form.Label>{t("Upload Paid Date File")}</Form.Label>
            <Form.Control
              type="file"
              placeholder="Input File"
              onChange={(e) => onFileUpload(e)}
              // disabled={props.data.paymentAdvice.isDisabled}
            />
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="paidDate">
            <Form.Label>Paid Date</Form.Label>
            <ShreyuDatepicker
              hideAddon={true}
              dateFormat="dd MMM yyyy"
              value={new Date()}
              onChange={(date) => {
                // onDateChange(date);
              }}
            />
          </Form.Group> */}
        </Form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose} variant="secondary">
          {t("Cancel")}
        </Button>
        <Button className="vale-button" onClick={handleSubmit}>
          {t("Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type ExcelColumn = {
  docNo: string,
  paidDate: Date
}

interface CreateModalProps {
  // columns: MRT_ColumnDef<EmployeeColumn>[];
  onClose: () => void;
  onSubmit: (data: any[]) => void;
  onCellEdit: (cell: any, value: any) => void;
  // onFileUpload: (e: any) => void;
  CSV: ExcelColumn[];
  CSVHead: [];
  open: boolean;
}

const ValidateExcelModal = ({
  open,
  onClose,
  onSubmit,
  onCellEdit,
  // onFileUpload,
  CSV,
  CSVHead
}: CreateModalProps) => {
  const { t, i18n } = useTranslation();
  const handleSubmit = (data: any[]) => {
    //put your validation logic here
    onSubmit(data);
    onClose();
  };
  const [stmOrVei, setStmOrVei] = useState("stm");
  const columnMRTImport = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "docNo",
        header: "Document Number",
        enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "paidDate",
        header: "Paid Date",
        enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
    ],
    []
  );
  return (
    <Dialog open={open} fullScreen={true}>
      <DialogTitle textAlign="center">{t("Validate Data")}</DialogTitle>
      <DialogContent>
      <MaterialReactTable
        columns={columnMRTImport}
        data={CSV}
        enableEditing
        enableRowNumbers
        editingMode="cell"
        muiTableBodyCellEditTextFieldProps={({ cell }) => ({
          onBlur: (event) => {
            onCellEdit(cell, event.target.value);
          },
        })}
        renderBottomToolbarCustomActions={() => (
          <Typography sx={{ fontStyle: 'italic', p: '0 1rem' }} variant="body2">
            {t("Double-Click a Cell to Edit")}
          </Typography>
        )}
      />
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose} variant="secondary">
          {t("Cancel")}
        </Button>
        <Button className="vale-button" onClick={() => {
            handleSubmit(CSV)
          }}>
          {t("Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClaimNonCCList;
