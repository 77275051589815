const chartLegend = {
  total_vendor: "Number of Vendor (Qty)",
  lead_time: "Average Lead Time (Days)",
  planned_lead_time: "Average Planned Lead Time (Days)",
  rightYAxis1: "NUMBER OF WORKING DAYS",
  xAxis: [
    "Jan-",
    "Feb-",
    "Mar-",
    "Apr-",
    "May-",
    "Jun-",
    "Jul-",
    "Aug-",
    "Sep-",
    "Oct-",
    "Nov-",
    "Dec-",
  ],
};
export const bastChartLegend = {
  ...chartLegend,
  total_qty: "Number of BAST (Qty)",
  leftYAxis1: "NUMBER OF BAST",
}
export const invoiceChartLegend = {
  ...chartLegend,
  total_qty: "Number of Invoice (Qty)",
  leftYAxis1: "NUMBER OF INVOICES",
}

// export const months = [
//   "January",
//   "February",
//   "March",
//   "April",
//   "May",
//   "June",
//   "July",
//   "August",
//   "September",
//   "October",
//   "November",
//   "December",
// ]

// export const headerQty = ["Quantity", "%"]

// export const tableRowTitle = {
//   delay: "Approval Delay",
//   onTime: "Approval On Time",
//   total: "Total",
// }