import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Chart from "react-apexcharts";
import { Card } from "react-bootstrap";
import ModalTable from "./ModalTable";

export default function ModalKpi({
  open,
  onClose,
  setOpen,
  legend,
  data = [],
  dataTable = [],
  year,
  monthDataPoint,
}: any) {
  // console.log(data, "DATA")
  const handleClose = () => {
    setOpen(false);
  };
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={true}>
      {/* <AppBar sx={{ position: "relative", bgcolor: "green" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Sound
          </Typography>
          <Button autoFocus color="inherit" onClick={handleClose}>
            save
          </Button>
        </Toolbar>
      </AppBar> */}
      <DialogTitle
        className=""
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #e0e0e0",
          // boxShadow: "0 4px 2px -2px gray",
        }}
      >
        <div></div>
        <p className="m-0 p-0">KPI</p>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="" style={{ width: "100%", overflow: "scroll" }}>
        <div style={{ height: "1em" }}></div>
        <ModalChart
          legend={legend}
          data={data}
          year={year}
          month={months[monthDataPoint - 1]}
        />
        <ModalTable
          data={dataTable}
          delayTitle={"Approval Delay"}
          ontimeTitle={"Approval On Time"}
          year={year}
        />
      </DialogContent>
    </Dialog>
  );
}

function ModalChart({ legend, data, year, month }: any) {
  // const { dummyUsersKpi: data} = require("./getData")
  // console.log(data, "data")
  const categories = [];
  const series: any = [
    {
      name: "On Time",
      data: [
        // 44, 55, 57, 56, 61, 58, 63, 60, 66, 10, 44, 55, 57, 56, 61, 58, 63, 60, 66, 100, 4, 87, 56, 56,
        // 44, 55, 57, 56, 61, 58, 63, 60, 66, 10, 44, 55, 57, 56, 61, 58, 63, 60, 66, 10, 4, 87, 56, 56,
      ],
      color: "#A1F192",
      type: "column",
    },
    {
      name: "Delay",
      data: [
        // 44, 55, 57, 56, 61, 58, 63, 60, 66, 10, 44, 55, 57, 56, 61, 58, 63, 60, 66, 100, 4, 87, 56, 56,
        // 44, 55, 57, 56, 61, 58, 63, 60, 66, 10, 44, 55, 57, 56, 61, 58, 63, 60, 66, 10, 4, 87, 56, 56,
      ],
      color: "#FC6360",
      type: "column",
    },
  ];
  for (let i = 0; i < data.length; i++) {
    categories.push(data[i].user_name);
    series[0].data.push(data[i].ontime);
    series[1].data.push(data[i].delay);
  }
  // console.log(categories);
  // console.log(series);

  const options = {
    chart: {
      // locales: [],
      // type: 'bar',
      // height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        borderRadius: 3,
        dataLabels: {
          position: "center",
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories,
      // categories: [
      //   "Atian Jundana Hasa Atian Jundana Hasa Atian Jundana Hasa",
      //   "Roy Indra Atra Junior",
      //   "Junaidi Suhendra",
      //   "Yogi Saputra",
      //   "Jun",
      //   "Jul",
      //   "Aug",
      //   "Sep",
      //   "Oct",
      //   "Atian Jundana Hasa",
      //   "Roy Indra Atra Junior",
      //   "Junaidi Suhendra",
      //   "Yogi Saputra",
      //   "Jun",
      //   "Jul",
      //   "Aug",
      //   "Sep",
      //   "Oct",
      //   "Atian Jundana Hasa",
      //   "Roy Indra Atra Junior",
      //   "Junaidi Suhendra",
      //   "Yogi Saputra",
      //   "Jun",
      //   "Jul",
      //   "Atian Jundana Hasa Atian Jundana Hasa Atian Jundana Hasa",
      //   "Roy Indra Atra Junior",
      //   "Junaidi Suhendra",
      //   "Yogi Saputra",
      //   "Jun",
      //   "Jul",
      //   "Aug",
      //   "Sep",
      //   "Oct",
      //   "Atian Jundana Hasa",
      //   "Roy Indra Atra Junior",
      //   "Junaidi Suhendra",
      //   "Yogi Saputra",
      //   "Jun",
      //   "Jul",
      //   "Aug",
      //   "Sep",
      //   "Oct",
      //   "Atian Jundana Hasa",
      //   "Roy Indra Atra Junior",
      //   "Junaidi Suhendra",
      //   "Yogi Saputra",
      //   "Jun",
      //   "Jul",
      // ],
      labels: {
        style: {
          fontSize: "0.7em", // Adjust font size
          cssClass: "apexcharts-xaxis-label", // Add CSS class for custom styling
        },
        // Rotate labels for more space if needed
        rotate: -90, // Rotate the labels to fit them in wider space
        offsetY: 5, // Adjust position vertically if needed
        show: true,
        // hideOverlappingLabels: true,
        trim: true,
      },
    },
    yaxis: [
      {
        title: {
          text: legend?.leftYAxis1 ? legend?.leftYAxis1 : "NUMBER OF INVOICES",
        },

      },
    ],
    fill: {
      opacity: 1,
      // colors: ['#00E296', '#FFB01A']
    },
    tooltip: {
      followCursor: true,
    },
  };
  // const series = [
  //   {
  //     name: "On Time",
  //     data: [
  //       44, 55, 57, 56, 61, 58, 63, 60, 66, 10, 44, 55, 57, 56, 61, 58, 63, 60, 66, 100, 4, 87, 56, 56,
  //       44, 55, 57, 56, 61, 58, 63, 60, 66, 10, 44, 55, 57, 56, 61, 58, 63, 60, 66, 10, 4, 87, 56, 56,
  //     ],
  //   },
  //   {
  //     name: "Delay",
  //     data: [
  //       44, 55, 57, 56, 61, 58, 63, 60, 66, 10, 44, 55, 57, 56, 61, 58, 63, 60, 66, 100, 4, 87, 56, 56,
  //       44, 55, 57, 56, 61, 58, 63, 60, 66, 10, 44, 55, 57, 56, 61, 58, 63, 60, 66, 10, 4, 87, 56, 56,
  //     ],
  //   },
  // ];

  return (
    <>
      <Card>
        <Card.Body className="">
          <h4 className="header-title mt-0 mb-3">
            {month} {year}
          </h4>
          <Chart
            series={series}
            type="bar"
            height={380}
            options={options}
            className="apex-charts"
            // dir="ltl"
            // onClick={console.log("clicked")}
          />
        </Card.Body>
      </Card>
      {/* <Card className="mt-3">
        <Card.Body className="">
          <Chart
            series={[
              {
                data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
              },
            ]}
            type="bar"
            height={380}
            options={{
              chart: {
                type: "bar",
                height: 350,
                // locales: [],
              },
              plotOptions: {
                bar: {
                  borderRadius: 4,
                  // borderRadiusApplication: "end",
                  horizontal: true,
                },
              },
              dataLabels: {
                enabled: false,
              },
              xaxis: {
                categories: [
                  "South Korea South Korea South Korea",
                  "Canada",
                  "United Kingdom",
                  "Netherlands",
                  "Italy",
                  "France",
                  "Japan",
                  "United States",
                  "China",
                  "Germany",
                ],
              },
            }}
            className="apex-charts"
            // dir="ltl"
            // onClick={console.log("clicked")}
          />
        </Card.Body>
      </Card> */}
    </>
  );
}
