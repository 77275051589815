import { useMemo, useEffect, useState, useCallback, useRef } from "react";
import { Row, Col, Card, Form, Button, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import customParseFormat from 'dayjs/plugin/customParseFormat'
import Loading from "../../components/MyComponents/Loading";

import {
  MaterialReactTable,
  // MRT_ColumnDef,
  MRT_Row,
  MaterialReactTableProps,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_Cell,
} from "material-react-table";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TableHead,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useNavigate, Link } from "react-router-dom";
import Papa from 'papaparse';
import * as XLSX from 'xlsx/xlsx.mjs';
import { API_URI } from "../../constants";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput, VerticalForm } from "../../components";
import { set, useForm } from "react-hook-form";
import Select from "react-select";
import { b64toBlob, changeUStoID, checkFile, get_header_row, getMasterData, listCompanyOptions, onChangeMoney, removeDot, replaceToDot, valueOptions } from "../../helpers/utils";
import FindInPage from "@mui/icons-material/FindInPage";
import { toBase64 } from "../../utils";
import ShreyuDatepicker from "../../components/Datepicker";
import dayjs from "dayjs";
import { select } from "redux-saga/effects";

type EmployeeColumn = {
  id?: any;
  company_code?: string;
  request_number?: string;
  category?: string; // External Expenditure (Hard Code) (send to backend only)
  classification?: string; // Justification
  name_of_requester?: string;
  department_id?: number;
  est_program_schedule_start?: string;
  est_program_schedule_finish?: string;
  budget_proposed_idr?: string;
  budget_proposed_usd?: string;
  currency?: string;
  gl_no?: string;
  wbs_no?: string;
  costcenter_no?: string;
  company?: string;
  department?: {
    name?:string
  };
  department_name?: string;
  previous_form?: string;
  file_ci?: string;
};

const dataSelectionProps: any = {};
let divRef: any;
const CI = () => {
  const [listBank, setListBank] = useState<any>([]);
  const [listEmployeeDistinct, setListEmployeeDistinct] = useState<any>([]);
  const [currentCompany, setCurrentCompany] = useState(JSON.parse(sessionStorage.getItem('current_company')as string));

  const getData = async () => {
    // const response = await axios.get(API_URI + 'department/');
    fetch(API_URI + 'contract-corporate-integrity-list', {
      method: "POST",
      body:JSON.stringify({
        columnFilters,
        sorting,
        pagination
      })
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if(data && data.success){
        if(data.data && data.data.data) {
          console.log(data.data.data)
          const a = [];
          for(const b of data.data.data) {
            // b.company = b.company?.company_code ? b.company.company_code : null
            b.department_name = b.department?.name ? b.department.name : null
            b.department = b.department?.id ? b.department.id : null
            a.push(b)
          }
          setListEmployee(a);
          setRowCount(data.data.total)
          console.log(a)
          setIsError(false);
        }else{
          setListEmployee([]);
          setRowCount(0)
          // console.log(a)
          setIsError(false);
        }
      }else{
        console.log(data.message);
        setIsError(true);
        toast.error('Failed getting data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    })
    .catch(err => {
      console.log(err)
      setIsError(true);
      toast.error('Failed getting data', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
    // console.log(response.json());
    // setDataApi(response);
  };

  const getDataById = async (id: any) => {
    // const response = await axios.get(API_URI + 'department/');
    return new Promise((resolve, reject) => 
      fetch(API_URI + 'contract-corporate-integrity/' + id, {
        method: "GET"
      })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if(data && data.success){
          if(data && data.data) {
            console.log(data.data)
            data.data.department = data.data.department?.id ? data.data.department.id : null
            resolve(data.data);
          }else{
            reject(null);
          }
        }else{
          console.log(data.message);
          // setIsError(true);
          toast.error('Failed getting data', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          reject(null);
        }
      })
      .catch(err => {
        console.log(err)
        setIsError(true);
        toast.error('Failed getting data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
    )
    // console.log(response.json());
    // setDataApi(response);
  };

  const getDataAllCI = async () => {
    // const response = await axios.get(API_URI + 'department/');
    fetch(API_URI + 'contract-corporate-integrity-status-list', {
      method: "POST",
      body:JSON.stringify({
        "columnFilters": [
          // {"id": "company","value": JSON.parse(sessionStorage.getItem('current_company')as string).company_initial}
            //{company_code: parseInt(JSON.parse(sessionStorage.getItem('current_company')as string).company_name)}
        ],
        "sorting": [
            // {
            //     "id": "id",
            //     "desc": false
            // }
        ],
        "pagination": {
            "pageIndex": 0,
            "pageSize": 10000
        }
      })
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if(data && data.success){
        if(data.data && data.data.data) {
          console.log(data.data.data)
          const dir = []
          for(const a of data.data.data) {
            dir.push({
              value: a.request_number,
              label: a.request_number +' - ' + a.company,
              company: a.company
            })
          }
          setListAllEmployee(dir);
        }else{
          setListAllEmployee([]);
        }
      }else{
        console.log(data.message);
        toast.error('Failed getting data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    })
    .catch(err => {
      console.log(err)
      setIsError(true);
      toast.error('Failed getting data', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
    // console.log(response.json());
    // setDataApi(response);
  };

  const getDataDepartement = async () => {
    // const response = await axios.get(API_URI + 'department/');
    fetch(API_URI + 'department-list', {
      method: "POST",
      body:JSON.stringify({
        "columnFilters": [
          // {"id": "company","value": JSON.parse(sessionStorage.getItem('current_company')as string).company_initial}
            //{company_code: parseInt(JSON.parse(sessionStorage.getItem('current_company')as string).company_name)}
        ],
        "sorting": [
            {
                "id": "id",
                "desc": false
            }
        ],
        "pagination": {
            "pageIndex": 0,
            "pageSize": 10000
        }
    }
    )
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if(data && data.success){
        if(data.data && data.data.data) {
          console.log(data.data.data)
          const dir = []
          for(const a of data.data.data) {
            dir.push({
              value: a.id,
              label: a.name +' - ' + a.description,
              company: a.company
            })
          }
          console.log(dir);
          setListDepartment(dir)
          // setRowCount(data.data.total)
          // setIsError(false);
        }
      }else{
        console.log(data.message);
        // setIsError(true);
      }
    })
    .catch(err => {
      console.log(err)
      toast.error('Failed getting data', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // setIsError(true);
    })
    // console.log(response.json());
    // setDataApi(response);
  };

  const handleAddUpdateApi = ((dataCreate: any) => {
    for(const a of dataCreate) {
      if (a.company_code) {
        console.log(a.company);
        if(a.company_code === 'STM' || a.company_code === 'VEI'){
          a.company = a.company_code
          delete a.company_code
        }
      }
      // if (a.bank_branch) {
      //   a.bank_br = a.bank_branch
      //   delete a.bank_branch
      // }
      delete a.deleted_at
      delete a.created_at
      delete a.created_by
      delete a.updated_at
      delete a.updated_by
    }
    fetch(API_URI + 'contract-corporate-integrity', {
      method: "POST",
      body:JSON.stringify(dataCreate),
      headers: {
        "Accept":"application/json",
        "Content-Type":"application/json",
        "Access-Control-Allow-Origin": "*"
      }
      // mode: 'no-cors'
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      getData()
      if(data.success) {
        toast.success('Success Saving data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error('Failed saving data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    })
    .catch(err => {
      console.log(err)
      setIsError(true);
      toast.error('Failed saving data', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
  })

  const getDataGL = () => new Promise((resolve, reject) => 
    getMasterData('general-ledger-list', {
      columnFilters: [{
        "id": 'is_expense',
        "value": '1'
      }],
      sorting: [],
      pagination: {
        "pageIndex": 0,
        "pageSize": 999999
      },
      
    }).then(data => {
      const hasil = []
      for(const a of data.data.data){
        hasil.push({
          key: a.id,
          label: a.gl_code + ' - ' + a.gl_name,
          value: a.id,
          company: a.company
        })
      }
      setDataGL(hasil);
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      resolve(true);
    })
  )

  const getDataWBS = () => new Promise((resolve, reject) => 
    getMasterData('wbs-list', {
      columnFilters: [],
      pagination: {
        "pageIndex": 0,
        "pageSize": 999999
      },
      sorting: []
    }).then(data => {
      const hasil = []
      if(data && data?.data && data?.data?.data) {
        for(const a of data.data.data){
          hasil.push({
            key: a.id,
            label: a.wbs_code + ' - ' + a.wbs_name,
            value: a.id
          })
        }
      }
      setDataWBS(hasil);
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      resolve(true);
    })
  )

  const getDataCostCenter = () => new Promise((resolve, reject) => 
    getMasterData('cost-center-list', {
      columnFilters: [],
      pagination: {
        "pageIndex": 0,
        "pageSize": 999999
      },
      sorting: []
    }).then(data => {
      const hasil = []
      // hasil.push({
      //   key: 0,
      //   label: 'Please Select',
      //   value: 0
      // })
      if(data && data?.data && data?.data?.data){
        for(const a of data?.data?.data){
          hasil.push({
            key: a.id,
            label: a.code + ' - ' + a.names,
            value: a.id
          })
        }
      }
      setDataCostCenter(hasil);
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      resolve(true);
    })
  )

  const getDataCurrency = () => getMasterData('currency-list', {
    columnFilter: [],
    pagination: {
      "pageIndex": 0,
      "pageSize": 10000
    },
    sorting: []
  }).then(data => {
    const hasil = []
    for(const a of data.data.data){
      hasil.push({
        key: a.id,
        label: a.currency_code,
        value: a.currency_code
      })
    }
    setDataCurrency(hasil);
  })

  const getDataUser = () => new Promise((resolve, reject) => 
    getMasterData('user-list', {
      columnFilters: [{
        "id": 'type_id',
        "value": '1'
      }],
      sorting: [
        {
            "id": "name",
            "desc": false
        }
      ],
      pagination: {
        "pageIndex": 0,
        "pageSize": 999999
      },
      
    }).then(data => {
      const hasil = []
      for(const a of data.data.data){
        hasil.push({
          key: a.id,
          label: a.name + ' - ' + a.company_initial,
          value: a.id,
          company: a.company_initial
        })
      }
      setDataUser(hasil);
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      resolve(true);
    })
  )

  const getAllChildData =async () => {
    setLoad(true)
    await Promise.all([
      getDataGL(), 
      getDataWBS(), 
      getDataCostCenter(),
      getDataCurrency(),
      getDataAllCI(),
      getDataUser(),
    ]).then(() => {
      setLoad(false)
    }).catch(() => {
      setLoad(false)
    })
  }

  const [rowSelection, setRowSelection] = useState<any>(dataSelectionProps);
  const [propsForm, setPropsForm] = useState<any>(dataSelectionProps);
  const [searchTable, setSearchTable] = useState<string>("");
  const [listEmployee, setListEmployee] = useState<any>([]);
  const [listAllEmployee, setListAllEmployee] = useState<any>([]);
  const [listDepartment, setListDepartment] = useState<any>([]);
  const [dataGL, setDataGL] = useState<any>();
  const [dataWBS, setDataWBS] = useState<any>();
  const [dataCostCenter, setDataCostCenter] = useState<any>();
  const [dataCurrency, setDataCurrency] = useState<any>();
  const [dataUser, setDataUser] = useState<any>();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [createModal2Open, setCreateModal2Open] = useState(false);
  const [createModal3Open, setCreateModal3Open] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isValid, setIsValid] = useState(true);
  //table state
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    [],
  );
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [load, setLoad] = useState<any>(false);
  useEffect(() => {
    console.log('columnFilters', columnFilters)
    console.log('sorting', sorting)
    console.log('globalFilter', globalFilter)
    console.log('pagination', pagination)
    console.log(API_URI + 'department')
    getData();
    getDataDepartement();
    getAllChildData();
    // getDataBank();
    // getDataEmployee();
    // axios.get(API_URI + 'department/').then((response)=> {
    //   console.log(response);
    // })
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);
  const navigate = useNavigate();

  const handleDeleteRow = useCallback(
    (row: MRT_Row<EmployeeColumn>) => {
      if (
        !window.confirm(
          `Are you sure you want to delete ${row.getValue("name")}`
        )
      ) {
        return;
      }
      fetch(API_URI + `contract-corporate-integrity/${row.getValue('id')}`, {
        method: "POST",
        body: JSON.stringify({
          "_method": "DELETE"
        }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        toast.success('Success Removing data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        getData()
      })
      .catch(err => {
        console.log(err)
        setIsError(true);
        toast.error('Failed Removing data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
    },
    [listEmployee]
  );
  
  const columnMRT = useMemo<MRT_ColumnDef<EmployeeColumn>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        // enableColumnOrdering: true,
        enableColumnFilter: false,
        enableEditing: false, //disable editing on this column
        size: 80,
      },
      {
        accessorKey: "company",
        header: "Company Code",
        size: 80,
        type: 'select',
        options: [
          {value: 'STM',label: 'STM'},
          {value: 'VEI',label: 'VEI'},
        ],
        filterSelectOptions:[{ text: "STM", value: "STM" }, {text: "VEI", value: 'VEI'}],
        filterVariant: 'select'
      },
      {
        accessorKey: "request_number",
        header: "Request Number",
        // enableColumnOrdering: false,
        size: 80,
      },
      {
        accessorKey: "name_of_requester",
        header: "Name of Requester",
        // enableColumnOrdering: false,
        size: 80,
      },
      {
        accessorKey: "department_name",
        header: "Department",
        size: 80,
        type: 'select',
        options: listDepartment
      },
      {
        accessorKey: "est_program_schedule_start",
        header: "Start Date",
        size: 80,
      },
      {
        accessorKey: "est_program_schedule_finish",
        header: "End Date",
        // enableColumnOrdering: false,
        size: 80,
      },
      {
        accessorKey: "budget_proposed_idr",
        header: "Budget in IDR",
        // enableColumnOrdering: false,
        size: 80,
      },
      {
        accessorKey: "budget_proposed_usd",
        header: "Budget in USD",
        // enableColumnOrdering: false,
        size: 80,
      },
      {
        accessorKey: "currency",
        header: "Currency",
        // enableColumnOrdering: false,
        size: 80,
      },
      {
        accessorKey: "gl_no",
        header: "GL",
        // enableColumnOrdering: false,
        size: 80,
      },
      {
        accessorKey: "costcenter_no",
        header: "Cost Center",
        size: 80,
      },
      {
        accessorKey: "wbs_no",
        header: "WBS",
        size: 80,
      }
    ],
    []
  );

  const handleSaveRowEdits: MaterialReactTableProps<EmployeeColumn>["onEditingRowSave"] =
    async ({ exitEditingMode, row, values }) => {
      console.log(exitEditingMode, row, values);
      listEmployee[row.index] = values;
      //send/receive api updates here, then refetch or update local table data for re-render
      // console.log(hasil);
      setListEmployee([...listEmployee]);
      handleAddUpdateApi([values])
      exitEditingMode(); //required to exit editing mode and close modal
    };

  const handleCancelRowEdits = () => {};
  const [CSVData, setCSVData] = useState<EmployeeColumn[]>([]);
  const [CSVHead, setCSVHead] = useState();
  const [file, setFile] = useState({});
  const [dataModalCreate, setDataModalCreate] = useState({});
  const [dataModalCreateMode, setDataModalCreateMode] = useState("");

  const checkValid = (head: any, jsa: any) => {
    let valid = true;
    for(let a in jsa) {
      for (const b of head) {
        let ketemu = false;
        for (let [key, value] of Object.entries(jsa[a])) {
          if(b === key) {
            if (b === 'position'){ketemu = true;}
            if (b === 'bank_branch'){ketemu = true;}
            if (b === 'bank_name'){ketemu = true;}
            if (b === 'bank_account'){ketemu = true;}
            if (b === 'bank_customer'){ketemu = true;}
            if (b === 'address'){ketemu = true;}
            if (b === 'mapping'){ketemu = true;}
            if(value !== ''){ketemu = true;}
          } else {
            if (b === 'position'){ketemu = true;}
            if (b === 'bank_branch'){ketemu = true;}
            if (b === 'bank_name'){ketemu = true;}
            if (b === 'bank_account'){ketemu = true;}
            if (b === 'bank_customer'){ketemu = true;}
            if (b === 'address'){ketemu = true;}
            if (b === 'mapping'){ketemu = true;}
          }
        }
        if(!ketemu){
          // if(b ==='bank_branch') {console.log('Ini Bank Branch')}
          // if(b ==='bank_name') {console.log('Ini Bank Branch')}
          // if(b ==='bank_account') {console.log('Ini Bank Branch')}
          // if(b ==='bank_customer') {console.log('Ini Bank Branch')}
          // if(b ==='address') {console.log('Ini Address')}
          console.log(`${b} is required!`)
          // jsa[a][b] = `${b} is required!`
          valid = false;
        }
      }
    }
    setIsValid(valid);
    return valid;
  }

  const handleExport = async() => {
    const res = await fetch(
      API_URI + `employee-to-excel`,
      {
        method: "POST",
        body:JSON.stringify({
          // columnFilters,
        }),
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          Authorization:
            "Bearer " +
            JSON.parse(
              sessionStorage.getItem("shreyu_user") as string
            ).access_token,
        },
      }
    );
    const blob: any = await res.blob();
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = 'CorporateIntegrity.xlsx';
    a.click();
  }

  const submitImport = () => {
    let fileInput:any = {...file};
    const thisFile = fileInput.target.files[0];
    let reader = new FileReader();
    reader.onload = function(e: any) {
      const data = e.target.result;
      const workbook = XLSX.read(e.target.result);
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsa = XLSX.utils.sheet_to_json(sheet);
      const head:any = get_header_row(sheet);
      // console.log(head);
      // console.log(jsa[4])
      const valid = checkValid(head, jsa)
      // setIsValid(checkValid(head, jsa));
      if(valid) {
        handleAddUpdateApi(jsa);
      } else {
        setCSVData(jsa);
        setCSVHead(head);
        setCreateModal2Open(true);
      }
    };
    reader.readAsArrayBuffer(thisFile);
  }

  const onChangeUploadCSV = (e: any) => {
    console.log(e)
    setFile(e);
  }

  const handleSaveCell = (cell: MRT_Cell<EmployeeColumn>, value: any) => {
    //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here
    console.log(value);
    CSVData[cell.row.index][cell.column.id as keyof EmployeeColumn] = value;
    //send/receive api updates here
    setCSVData([...CSVData]); //re-render with new data
    setIsValid(checkValid(CSVHead, CSVData));
  };

  return (
    <>
      <Loading loading={load} />
      <Row className="mt-3">
        <Col>
          <Card>
            <Card.Body>
              <h4 className="header-title2 ">List Corporate Integrity</h4>
              <MaterialReactTable
                columns={columnMRT}
                data={listEmployee}
                initialState={{ 
                  density: 'compact',
                  showColumnFilters: true,
                  columnPinning: { 
                    left: ["mrt-row-actions"],
                  },
                  columnVisibility: { 
                    // subcompany_code: currentCompany.company_initial === 'STM' ? true : false,
                    wbs_no: currentCompany.company_initial === 'STM' ? true : false,
                    costcenter_no: currentCompany.company_initial === 'STM' ? false : true,
                    id: false
                  },
                }}
                renderTopToolbarCustomActions={() => (
                  <Box sx={{ display: "flex", gap: "1rem" }}>
                    {/* <Button
                      onClick={() => setCreateModalOpen(true)}
                      className="vale-button"
                    >
                      Import Data
                    </Button> */}
                    <Button
                      onClick={() => handleExport()}
                      className="vale-button"
                    >
                      Export Data
                    </Button>
                    <Button
                      onClick={() => {
                        setDataModalCreate({});
                        setDataModalCreateMode("Create New")
                        setCreateModal3Open(true)}
                      }
                      className="vale-button"
                    >
                      Create Data
                    </Button>
                  </Box>
                )}
                renderRowActions={({ row, table }) => (
                  <Box sx={{ display: "flex", gap: "1rem" }}>
                    <Tooltip arrow placement="left" title="Edit">
                      <IconButton
                        onClick={async() => {
                          // table.setEditingRow(row);
                          console.log(row.original);
                          const a: any = await getDataById(row.original.id)
                          a.company = row.original.company
                          setDataModalCreate(a)
                          setDataModalCreateMode("Save")
                          setCreateModal3Open(true)
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip arrow placement="right" title="Delete">
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteRow(row)}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
                
                muiTableBodyCellProps={({
                  cell,
                  // column,
                  row,
                  // table
                }) => ({
                  // onClick: (event) => {
                  //   // console.log(cell.column.id);
                  //   if (cell.column.id === "employeeId") {
                  //     navigate(
                  //       `/sys-admin/master-data/employee-list/${row.original.employeeId}`,
                  //       { state: row.original }
                  //     );
                  //     // console.log(cell.getValue())
                  //     // console.log(row.original);
                  //   }
                  // },
                  // sx: {
                  //   cursor: cell.column.id === "employeeId" ? "pointer" : "", //you might want to change the cursor too when adding an onClick
                  // },
                })}
                editingMode="modal" //default
                enableColumnOrdering
                enableEditing
                enableRowNumbers
                manualFiltering
                manualPagination
                manualSorting
                enableMultiSort={true}
                enableGlobalFilter={false}
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={rowCount}
                state={{
                  columnFilters,
                  globalFilter,
                  // isLoading,
                  pagination,
                  showAlertBanner: isError,
                  // showProgressBars: isRefetching,
                  sorting,
                }}
                // onEditingRowSave={handleSaveRowEdits}
                // onEditingRowCancel={handleCancelRowEdits}
                displayColumnDefOptions={{
                  "mrt-row-actions": {
                    muiTableHeadCellProps: {
                      align: "center",
                    },
                    size: 120,
                  },
                }}
                
              />
              {createModalOpen &&<CreateNewEmployeeModal
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={() => submitImport()}
                onFileUpload={(e: any) => onChangeUploadCSV(e)}
              />}
              {createModal2Open &&<ValidateExcelModal
                open={createModal2Open}
                onClose={() => setCreateModal2Open(false)}
                onSubmit={(data) => {
                  for (const a of data) {
                    // a.company = a.company_code
                    a.company = a.company_code
                    delete a.company_code
                    // a.bank_br = a.bank_branch
                    // a.bank_acc = a.bank_account
                    // a.bank_cust = a.bank_customer
                  }
                  handleAddUpdateApi(data)
                }}
                onCellEdit={(cell, value) => handleSaveCell(cell, value)}
                isValid={isValid}
                CSV={CSVData!}
                CSVHead={CSVHead!}
                department={listDepartment}
              />}
              {createModal3Open &&
              <CreateNewEmployeeModalSingle
                columns={columnMRT}
                department={listDepartment}
                bank={listBank}
                gl={dataGL}
                wbs={dataWBS}
                costcenter={dataCostCenter}
                currency={dataCurrency}
                allCI={listAllEmployee}
                user={dataUser}
                employeeDistinct={listEmployeeDistinct}
                open={createModal3Open}
                onClose={() => setCreateModal3Open(false)}
                onSubmit={(dataCreate) => {
                  console.log(dataCreate);
                  // dataCreate.id = null;
                  if(!(dataModalCreateMode === 'Save')) {
                    delete dataCreate.id;
                  }
                  dataCreate.department_id = dataCreate.department
                  // dataCreate.department_id = dataCreate.department
                  // dataCreate.company = dataCreate.company_code
                  // delete dataCreate.company_code
                  // delete dataCreate.company_mappings
                  // delete dataCreate.company_sub_id
                  if (dataCreate.company === 'VEI') {
                    dataCreate.costcenter_no = dataCreate.wbs_no
                    delete dataCreate.wbs_no
                  }
                  delete dataCreate.department
                  delete dataCreate.user
                  handleAddUpdateApi([dataCreate])
                }}
                data={dataModalCreate}
                mode={dataModalCreateMode}
              />}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

interface CreateModalProps {
  // columns: MRT_ColumnDef<EmployeeColumn>[];
  onClose: () => void;
  onSubmit: () => void;
  onFileUpload: (e: any) => void;
  open: boolean;
}

//example of creating a mui dialog modal for creating new rows
export const CreateNewEmployeeModal = ({
  open,
  onClose,
  onSubmit,
  onFileUpload,
}: CreateModalProps) => {
  const handleSubmit = () => {
    //put your validation logic here
    onSubmit();
    onClose();
  };

  const [stmOrVei, setStmOrVei] = useState("stm");
  const templateFile = API_URI  + 'employee-export';
  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Import Corporate Integrity Data</DialogTitle>
      <DialogContent>
        <Row>
            <Col>
            <a href={templateFile} target="_blank" rel="noopener noreferrer" download>
              <Button>
                  <i className="fas fa-download"/>
                  Download Template
              </Button>
            </a>
            </Col>
        </Row>
        <br></br>
        <Form>
          <Form.Group className="mb-3" controlId="bulkInputEmployee">
            <Form.Label>Attach File</Form.Label>
            <Form.Control ref={divRef} type="file" id="upload_csv_file" onChange={(e) => onFileUpload(e)}/>
            <Form.Text>Upload XSLX File (format .xslx)</Form.Text>
          </Form.Group>
        </Form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button className="vale-button" onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface CreateModal2Props {
  // columns: MRT_ColumnDef<EmployeeColumn>[];
  onClose: () => void;
  onSubmit: (data: any[]) => void;
  onCellEdit: (cell: any, value: any) => void;
  // onFileUpload: (e: any) => void;
  CSV: any[];
  CSVHead: [];
  open: boolean;
  isValid: boolean;
  department: any;
}

//example of creating a mui dialog modal for creating new rows
export const ValidateExcelModal = ({
  open,
  onClose,
  onSubmit,
  onCellEdit,
  // onFileUpload,
  CSV,
  CSVHead,
  isValid,
  department,
}: CreateModal2Props) => {
  const handleSubmit = (data: any[]) => {
    //put your validation logic here
    onSubmit(data);
    onClose();
  };
  const [stmOrVei, setStmOrVei] = useState("stm");

  const checkValidation = ((cell:any) => {
    const val = cell.getValue();
    if(val && val !== ''){
      return <div>{cell.getValue() as string}</div>
    } else {
      // return <div style={{backgroundColor: 'red'}}>{cell.getValue() as string}<br/>{cell.column.id} is required!</div>;
      return <div style={{border: '1px solid red', color:'red'}}>{cell.getValue() as string}<br/>{cell.column.columnDef.header} is required!</div>;
    }
  })

  const companyOptions= [
    {
      value: 'STM',
      label: 'STM'
    },
    {
      value: 'VEI',
      label: 'VEI'
    }
  ]

  const columnMRTImport = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "company_code",
        header: "Company Code",
        // enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: false,
        muiTableBodyCellEditTextFieldProps: {
          select: true, //change to select for a dropdown
          children: companyOptions.map((a) => (
            <MenuItem key={a.label} value={a.label}>
              {a.label}
            </MenuItem>
          )),
        },
        size: 80,
        Cell: ({ cell }) => checkValidation(cell)
      },
      {
        accessorKey: "payroll_id",
        header: "Payroll ID",
        // enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: false,
        size: 80,
        Cell: ({ cell }) => checkValidation(cell)
      },
      {
        accessorKey: "payroll_name",
        header: "Payroll Name",
        // enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: false,
        size: 80,
        Cell: ({ cell }) => checkValidation(cell)
      },
      {
        accessorKey: "position",
        header: "Position",
        // enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: false,
        size: 80,
        // Cell: ({ cell }) => checkValidation(cell)
      },
      {
        accessorKey: "department",
        header: "Department",
        type: 'select',
        // enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: false,
        size: 80,
        Cell: ({ cell }) => checkValidation(cell),
        muiTableBodyCellEditTextFieldProps: {
          select: true, //change to select for a dropdown
          children: department.map((a: any) => (
            <MenuItem key={a.label} value={a.label}>
              {a.label}
            </MenuItem>
          )),
        },
      },
      {
        accessorKey: "direct_manager",
        header: "Direct Manager",
        // enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: false,
        size: 80,
        Cell: ({ cell }) => checkValidation(cell)
      },
      {
        accessorKey: "bank_name",
        header: "Bank Name",
        // enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: false,
        size: 80,
        // Cell: ({ cell }) => checkValidation(cell)
      },
      {
        accessorKey: "bank_branch",
        header: "Bank Branch",
        // enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: false,
        size: 80,
        // Cell: ({ cell }) => checkValidation(cell)
      },
      {
        accessorKey: "bank_account",
        header: "Bank Account",
        // enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: false,
        size: 80,
        // Cell: ({ cell }) => checkValidation(cell)
      },
      {
        accessorKey: "bank_customer",
        header: "Bank Customer",
        // enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: false,
        size: 80,
        // Cell: ({ cell }) => checkValidation(cell)
      },
      {
        accessorKey: "email",
        header: "Email",
        enableEditing: true, //disable editing on this column
        size: 80,
        Cell: ({ cell }) => checkValidation(cell)
      },
      {
        accessorKey: "address",
        header: "Address",
        enableEditing: true, //disable editing on this column
        size: 80,
        // Cell: ({ cell }) => checkValidation(cell)
        // muiTableBodyCellProps:({
        //   cell
        // }) => ({
        //   sx: {
        //     backgroundColor: cell.getValue<any>() === 'address is required!' ? 'red' : undefined,
        //     // fontWeight: cell.column.id === 'department' && cell.getValue<string>() === '' ? '700' : '400'
        //   },
        // })
      },
      {
        accessorKey: "mapping",
        header: "Mapping",
        enableEditing: true, //disable editing on this column
        size: 80,
      }
    ],
    []
  );
  return (
    <Dialog open={open} fullScreen={true}>
      <DialogTitle textAlign="center">Validate Data</DialogTitle>
      <DialogContent>
      <MaterialReactTable
        columns={columnMRTImport}
        data={CSV}
        enableEditing
        enableRowNumbers
        editingMode="cell"
        muiTableBodyCellEditTextFieldProps={({ cell }) => ({
          onBlur: (event) => {
            onCellEdit(cell, event.target.value);
          },
        })}
        renderBottomToolbarCustomActions={() => (
          <Typography sx={{ fontStyle: 'italic', p: '0 1rem' }} variant="body2">
            Double-Click a Cell to Edit
          </Typography>
        )}
      />
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button className="vale-button" 
          onClick={() => {
            for(const a of CSV) {
              a.company = a.company_code
              a.bank_br = a.bank_branch
              a.bank_acc = a.bank_account
              a.bank_cust = a.bank_customer
              // delete a.company_code
            }
            console.log(CSV);
            handleSubmit(CSV)
          }} 
          disabled={!isValid}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface CreateModalProps3 {
  // columns: MRT_ColumnDef<EmployeeColumn>[];
  columns: any;
  onClose: () => void;
  onSubmit: (values: any) => void;
  open: boolean;
  data: any;
  mode:string;
  department: any;
  bank: any;
  gl: any;
  wbs: any;
  costcenter: any;
  currency: any;
  employeeDistinct: any;
  user: any
  allCI: any
}

export const CreateNewEmployeeModalSingle = ({
  open,
  columns,
  onClose,
  onSubmit,
  data,
  mode,
  department,
  bank,
  gl,
  wbs,
  costcenter,
  currency,
  employeeDistinct,
  user,
  allCI
}: CreateModalProps3) => {
  // console.log(data.company)
  // console.log(gl)
  console.log(allCI)
  if(!data.company) {
    data.company = 'STM'
    // gl = gl.filter((item:any) => item.company === 'STM')
    console.log(gl);
  }
  const defValue = {
    "company": valueOptions(listCompanyOptions, data.company),
    "wbs_no" : data.company === 'STM' ? data.wbs_no : data.costcenter_no,
    "department": valueOptions(department, data.department),
    "currency": valueOptions(currency, data.currency),
    "employeeDistinct": valueOptions(employeeDistinct, data.direct_manager),
    "name_of_requester": valueOptions(user, data.name_of_requester),
    "previous_form": valueOptions(allCI, data.previous_form),
  }

  const [dataGL, setDataGL] = useState<any>((gl?.filter((item:any) => item.company === data.company)) || []);
  const [dataWBS, setDataWBS] = useState<any>(data?.company === 'STM' ? wbs || [] : costcenter || []);
  const [dataCostCenter, setDataCostCenter] = useState<any>(costcenter || []);
  const [dataDept, setDataDept] = useState<any>((department?.filter((item:any) => item.company === data.company)) || []);
  const [dataUser, setDataUser] = useState<any>((user?.filter((item:any) => item.company === data.company)) || []);
  const [dataAllCI, setDataAllCI] = useState<any>((allCI?.filter((item:any) => item.company === data.company)) || []);
  const [companyCode, setCompanyCode] = useState<any>(data.company);
  data = {
    ...data,
    company: listCompanyOptions[defValue.company]?.value,
    department: department[defValue.department]?.value,
    currency: currency[defValue.currency]?.value,
    est_program_schedule_start: data.est_program_schedule_start ? new Date(data.est_program_schedule_start) : new Date(),
    est_program_schedule_finish: data.est_program_schedule_finish ? new Date(data.est_program_schedule_finish): new Date(),
    wbs_no: data.company === 'STM' ? data.wbs_no : data.costcenter_no,
    budget_proposed_idr: changeUStoID(data.budget_proposed_idr),
    budget_proposed_usd: changeUStoID(data.budget_proposed_usd),
  }

  const [values, setValues] = useState<any>(data)

  const baseColumnMRT = [
    {
      accessorKey: "id",
      header: "ID",
      // enableColumnOrdering: false,
      enableEditing: false, //disable editing on this column
      size: 80,
      required: false,
    },
    {
      accessorKey: "company",
      header: "Company Code",
      size: 80,
      value: valueOptions(listCompanyOptions, data.company),
      type: 'select',
      options: listCompanyOptions,
      required: true,
    },
    {
      accessorKey: "previous_form",
      header: "Previous Request Number",
      // enableColumnOrdering: false,
      size: 80,
      required: false,
      type: 'select',
      options: dataAllCI,
      value: valueOptions(dataAllCI, values?.previous_form),
    },
    {
      accessorKey: "request_number",
      header: "Request Number",
      // enableColumnOrdering: false,
      size: 80,
      required: true,
    },
    {
      accessorKey: "classification",
      header: "Classification",
      // enableColumnOrdering: false,
      size: 80,
      required: true,
    },
    {
      accessorKey: "name_of_requester",
      header: "Name of Requester",
      size: 80,
      required: true,
      type: 'select',
      options: user,
      value: valueOptions(dataUser, values?.name_of_requester),
    },
    {
      accessorKey: "department",
      header: "Department",
      size: 80,
      value: valueOptions(dataDept, values?.department),
      type: 'select',
      options: dataDept,
      required: true,
    },
    {
      accessorKey: "est_program_schedule_start",
      header: "Start Date",
      // enableColumnOrdering: false,
      size: 80,
      required: true,
      type: 'date'
    },
    {
      accessorKey: "est_program_schedule_finish",
      header: "End Date",
      // enableColumnOrdering: false,
      size: 80,
      required: true,
      type: 'date'
    },
    {
      accessorKey: "budget_proposed_idr",
      header: "Budget in IDR",
      // enableColumnOrdering: false,
      size: 80,
      required: true,
    },
    {
      accessorKey: "budget_proposed_usd",
      header: "Budget in USD",
      // enableColumnOrdering: false,
      size: 80,
      required: true,
    },
    {
      accessorKey: "currency",
      header: "Currency",
      size: 80,
      required: true,
      type: 'select',
      options: currency,
      value: valueOptions(currency, values?.currency),
    },
    {
      accessorKey: "gl_no",
      header: "GL",
      size: 80,
      required: true,
      type: 'select',
      options: dataGL,
      value: valueOptions(dataGL, values?.gl_no),
    },
  ]
  // const glColumn = {
  //   accessorKey: "gl_no",
  //   header: "GL",
  //   size: 80,
  //   required: false,
  // }

  const costcenterColumn = {
    accessorKey: "costcenter_no",
    header: "Cost Center",
    size: 80,
    required: false,
    type: 'select',
    options: costcenter,
    value: valueOptions(costcenter, data.costcenter_no),
  }

  const wbsColumn = {
    accessorKey: "wbs_no",
    header: "WBS",
    size: 80,
    required: true,
    type: 'select',
    options: dataWBS,
    value: valueOptions(dataWBS, values?.wbs_no),
  }
  
  // let columnMRT = useMemo<MRT_ColumnDef<EmployeeColumn>[]>(
  //   () => [
  //     ...baseColumnMRT,
  //     wbsColumn,
  //     {
  //       accessorKey: "file_ci",
  //       type: 'file',
  //       header: "File",
  //       size: 80,
  //       required: true,

  //     },
  //   ],
  //   []
  // );
  const [columnMRT, setcolumnMRT] = useState<any>([
    ...baseColumnMRT,
      wbsColumn,
      {
        accessorKey: "file_ci",
        type: 'file',
        header: "File",
        size: 80,
        required: true,

      },
  ])
  // const columnMRT = [
  //   ...baseColumnMRT,
  //     wbsColumn,
  //     {
  //       accessorKey: "file_ci",
  //       type: 'file',
  //       header: "File",
  //       size: 80,
  //       required: true,

  //     },
  // ]
  const initialValue = 
    columnMRT.reduce((acc: any, column: any) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any)

  const schemaResolver = yupResolver(
    yup.object().shape({
      id: yup.number(),
      // employee_id: yup.string().required("Please enter Employee ID"),
      company: yup.string().required("Please enter Company Code"),
      // company_code: yup.string().required("Please enter Company Code"),
      department: yup.string().required("Please enter Department"),
      previous_form: yup.string().nullable(), //.required("Please enter Previous Form"),
      request_number: yup.string().required("Please enter Request Number"),
      classification: yup.string().required("Please enter Classification"),
      name_of_requester: yup.string().required("Please enter Name of Requester"),
      est_program_schedule_start: yup.string().required("Please enter Start Date"),
      est_program_schedule_finish: yup.string().required("Please enter End Date"),
      budget_proposed_idr: yup.string().notOneOf( 
        [
          '0,00',
          null
        ],
        "Please enter Budget in IDR"
      ),
      budget_proposed_usd: yup.string().notOneOf( 
        [
          '0,00',
          null
        ],
        "Please enter Budget in USD"
      ),
      currency: yup.string().required("Please enter Currency"),
      gl_no: yup.string().required("Please enter GL"),
      wbs_no: yup.string().nullable()
      .when("company", {
        is: 'STM',
        then: yup.string().required("Please enter WBS")
      })
      .when("company", {
        is: 'VEI',
        then: yup.string().required("Please enter Cost Center")
      }),
      file_ci: yup.string().required("Please Upload File")
    })
  );

  const methods = useForm<EmployeeColumn>({
    // defaultValues: data,
    resolver: schemaResolver,
  });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = methods;

  setValue('company', values.company);
  setValue('department', values.department);
  setValue('previous_form', values.previous_form);
  setValue('request_number', values.request_number);
  setValue('classification', values.classification);
  setValue('name_of_requester', values.name_of_requester);
  setValue('est_program_schedule_start', values.est_program_schedule_start);
  setValue('est_program_schedule_finish', values.est_program_schedule_finish);
  setValue('budget_proposed_idr', values.budget_proposed_idr);
  setValue('budget_proposed_usd', values.budget_proposed_usd);
  setValue('currency', values.currency);
  // setValue('company_code', values.company_code);
  setValue('gl_no', values.gl_no);
  // setValue('costcenter_no', values.costcenter_no);
  setValue('wbs_no', values.wbs_no);
  setValue('file_ci', values.file_ci);
  // setValue('department_id', values.department_id);
  console.log("ERROR:", errors)
  console.log("VALUES:", values)

  const allOptions = (key: string) => {
    if (key === 'gl_no') {
      return dataGL
    } else if (key === 'wbs_no') {
      if (values.company == 'STM') {
        return dataWBS
      } else {
        return dataCostCenter
      }
    } else if (key === 'company') {
      return listCompanyOptions
    } else if (key === 'department') {
      return dataDept
    } else if (key === 'currency') {
      return currency
    } else if (key === 'name_of_requester') {
      return dataUser
    } else if (key === 'previous_form') {
      return dataAllCI
    } else {
      return [];
    }
  }

  return (
    <>
    <Dialog open={open} fullScreen={true}>
      <DialogTitle textAlign="center">{mode === 'Save' ? 'Edit' : 'Create New'}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={handleSubmit((d) => console.log(d))}
          // onSubmit={() => {}}
          // resolver={schemaResolver}
        >
          {/* <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '800px' },
              gap: '1.5rem',
            }}
          > */}
          <Form.Group as={Row} className="mb-3">
            {columnMRT.map((column: any) => (
            <>
            <Form.Label column md={2} className="mb-3">
              {/* {column.header == 'WBS' ? values.company == 'STM' ? 'WBS' : 'Cost Center' : column.header} */}
              {(values.company == 'STM' && column.accessorKey == 'wbs_no') ? 'WBS' : (values.company == 'VEI' && column.accessorKey == 'wbs_no') ? 'Cost Center' : column.header}
              {column.required ? <span className="text-danger">*</span> : null}
              {/* <span className="text-danger">*</span> */}
            </Form.Label>
              <Col md={4} className="mb-3">
                {column.type === 'select' ?
                <>
                <Select
                  key={`${column.accessorKey}-select-key`}
                  options={allOptions(column.accessorKey)}
                  name={column.accessorKey as string}
                  // defaultValue={column?.options?.[column?.value]}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isDisabled={column.readOnly}
                  isClearable={false}
                  value={allOptions(column.accessorKey)?.[valueOptions(allOptions(column.accessorKey), values?.[column?.accessorKey])] || ''}
                  onChange={(e) => {
                    if (column.accessorKey === 'company' && companyCode !== e.value) {
                      setValues({ 
                        ...values, 
                        [column.accessorKey]: e.value,
                        gl_no: '',
                        wbs_no: '',
                        name_of_requester: '',
                        department: ''
                      })
                      setCompanyCode(e.value);
                      setDataGL(gl.filter((item:any) => item.company === e.value));
                      setDataDept(department.filter((item:any) => item.company === e.value));
                      setDataUser(user.filter((item:any) => item.company === e.value));
                      setDataAllCI(allCI.filter((item:any) => item.company === e.value));
                      if (e.value === 'STM') {
                        console.log('set wbs')
                        setDataWBS(wbs);
                      } else {
                        console.log('set cost center')
                        setDataWBS(costcenter);
                      }
                      // getAllChildData(e.value);
                    } else {
                      setValues({ ...values, [column?.accessorKey]: e?.value })
                    }
                    console.log(values, errors, e?.value, column?.value);
                  }}
                ></Select>
                <FormInput
                  key={`${column.accessorKey}-input-select-key`}
                  // label={column.header}
                  name={column.accessorKey as string}
                  register={register}
                  hidden={true}
                  errors={errors}
                  control={control}
                  disabled={column.accessorKey === 'id' ? true : false}
                  // onChange={(e) =>
                  //   setValues({ ...values, [e.target.name]: e.target.value })
                  // }
                  value={values[column.accessorKey as string]}
                  // defaultValue={column?.options?.[column?.value]?.value}
                />
                </>
                : column.type === 'date' ?
                <>
                <ShreyuDatepicker
                  key={`${column.accessorKey}-date-key`}
                  hideAddon={true}
                  dateFormat="dd MMM yyyy"
                  onChange={(e) => {
                    console.log(e)
                    setValues({ ...values, [column.accessorKey]: e })
                    console.log(values, errors);
                  }}
                  value={values[column.accessorKey as string]}
                />
                <FormInput
                  key={`${column.accessorKey}-input-date-key`}
                  // label={column.header}
                  name={column.accessorKey as string}
                  register={register}
                  hidden={true}
                  errors={errors}
                  control={control}
                  disabled={column.accessorKey === 'id' ? true : false}
                  // onChange={(e) =>
                  //   setValues({ ...values, [e.target.name]: e.target.value })
                  // }
                  value={values[column.accessorKey as string]}
                  // defaultValue={values[column.accessorKey as string]}
                />
                </>
                : column.type === 'file' ?
                <>
                <Form.Label>Corporate Integrity File<span className="text-danger">*</span></Form.Label>
                {values[column.accessorKey as string] && (
                <div style={{ display: "inline" }}>
                    <FindInPage
                      onClick={() => {
                        const win: any = window.open();
                        const [meta, data] = values[column.accessorKey as string]?.split("base64,")
                        const blob = b64toBlob(data, "application/pdf");
                        const url = URL.createObjectURL(blob);
                        win.document.write(
                          `<iframe src="${
                            url
                          }" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
                        );
                      }}
                      className="cursor-pointer ms-2"
                    />
                    <Delete
                      color="error"
                      className="cursor-pointer ms-2"
                      onClick={() =>{
                        setValues({ 
                          ...values, 
                          [column.accessorKey]: null
                        })
                      }}
                    />
                </div>
                )}
                <Form.Control type="file" placeholder="Input File" 
                  // isDisabled={column.readOnly}
                  key={`${column.accessorKey}-file-key`}
                  onChange={async(e: any) => {
                    console.log(e);
                    // console.log(e.target.files)
                    if(checkFile(e)){
                      const file = await toBase64(e.target?.files[0]);
                      // console.log(file)
                      setValues({...values, [column.accessorKey]:file})
                    }
                  }}
                />
                <FormInput
                  key={`${column.accessorKey}-input-file-key`}
                  name={column.accessorKey as string}
                  // type="text"
                  register={register}
                  errors={errors}
                  hidden={true}
                  control={control}
                  placeholder="Input Supporting Doc"
                  // defaultValue={listData2.referenceFileCi}
                  value={values[column.accessorKey as string]}
                  readOnly={false}
                />
              </>
                :
                <FormInput
                  key={`${column.accessorKey}-input-key`}
                  // label={column.header}
                  name={column.accessorKey as string}
                  register={register}
                  errors={errors}
                  control={control}
                  hidden={column.hidden}
                  disabled={column.accessorKey === 'id' ? true : false}
                  onChange={(e) => {
                    if(column.accessorKey ==='budget_proposed_idr' || column.accessorKey ==='budget_proposed_usd'){
                      let z = e.target.value;
                      if (values[column.accessorKey] === '0,00'){
                        if(parseFloat(replaceToDot(z)) >= 10){
                          z = (parseFloat(replaceToDot(z)) / parseFloat('10')).toString()
                        }
                      }
                      onChangeMoney(values[column.accessorKey as string], e);
                      setValues({ ...values, [e.target.name]: changeUStoID(z) })
                    } else {
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }
                  }}
                  value={values[column.accessorKey as string]}
                />
                }
              </Col>
            </>
            ))}
          </Form.Group>
          {/* </Stack> */}
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={() => {
          setValues(initialValue);
          onClose();
        }}>Cancel</Button>
        {/* <Button color="secondary" onClick={handleSubmit} variant="contained"> */}
        {/* <Button onClick={handleSubmit((values)=>console.log(values))}> */}
        <Button onClick={
          handleSubmit(() => {
            console.log(values)
            values.id = data.id
            dayjs.extend(customParseFormat)
            values.est_program_schedule_start = values.est_program_schedule_start ? dayjs(values.est_program_schedule_start).format('YYYY-MM-DD')  : null as unknown as undefined
            values.est_program_schedule_finish = values.est_program_schedule_finish ? dayjs(values.est_program_schedule_finish).format('YYYY-MM-DD')  : null as unknown as undefined
            values.budget_proposed_idr = removeDot(values.budget_proposed_idr);
            values.budget_proposed_usd = removeDot(values.budget_proposed_usd);
            console.log(values)
            onSubmit(values)
            onClose()
          })
        }
        >
        {/* <Button variant="primary" type="submit"> */}
          {/* Create New */}
          {mode}
        </Button>
      </DialogActions>
    </Dialog>
    </>
  );
};

export default CI;
