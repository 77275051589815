import { API_URI } from "../../../constants";
import { CURRENT_COMPANY } from "../../../helpers/api/apiCore";

const userCurrentCompany = JSON.parse(
  sessionStorage.getItem(CURRENT_COMPANY) as string
);
export const getData = async ({ year, setDataAsli, kpi, setDataProp }: any) => {
  const res = await fetch(API_URI + "dashboard/miro", {
    method: "POST",
    body: JSON.stringify({
      start_date: year + "-01-01", //'2023-01-01',
      end_date: year + "-12-31", //'2023-12-31',
      company_code: userCurrentCompany?.company_code,
    }),
  });
  const data = await res.json();
  console.log(data);
  if (data && data.kpi_miro) {
    setDataAsli(data?.kpi_miro);
    const bln = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const NumOfInvoice = [];
    const NumOfVendor = [];
    const avgLeadTime = [];
    const avgPlanLead = [];
    for (let i = 0; i < 12; i++) {
      avgPlanLead.push(kpi);
    }
    // const avgPlanLead = [7, 7, 7, 7, 7, 7, 7, 7, 7 , 7, 7, 7]

    for (const a of bln) {
      let ketemu = [0, 0, 0];
      for (const b of data?.kpi_miro) {
        if (a === parseInt(b.month_no) && b.year_no == year) {
          ketemu = [
            parseInt(b.total_invoice),
            parseInt(b.total_vendor),
            parseFloat(b.lead_time),
          ];
          break;
        }
      }
      ketemu[0] === 0 ? NumOfInvoice.push(0) : NumOfInvoice.push(ketemu[0]);
      ketemu[1] === 0 ? NumOfVendor.push(0) : NumOfVendor.push(ketemu[1]);
      ketemu[2] === 0 ? avgLeadTime.push(0) : avgLeadTime.push(ketemu[2]);
    }
    setDataProp({
      NumOfInvoice,
      NumOfVendor,
      avgLeadTime,
      avgPlanLead,
      year,
    });
    console.log(NumOfInvoice);
  }
};

export const getDataTable = async ({
  year,
  setDataTable,
  kpi,
  company_code,
}: any) => {
  const res = await fetch(
    `${API_URI}dashboard/miro-table?kpi=${kpi}&company_code=${company_code}&year=${year}`,
    {
      method: "GET",
      // body:JSON.stringify({
      //   "kpi": 7,
      //   "company_code": parseInt(JSON.parse(sessionStorage.getItem('current_company')as string).company_code)
      // }),
      redirect: "follow",
    }
  );
  const data = await res.json();
  console.log(data);
  if (data) {
    setDataTable(data.data);
  }
};

export const getDataKpiChart = async (a: any) => {
  const { body, isInvoice = true } = a;
  console.log(body);

  const bln = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const NumOfInvoice: any = [];
  const NumOfVendor: any = [];
  const avgLeadTime: any = [];
  const avgPlanLead: any = [];
  if (!body?.approval1 && !body?.approval2) {
    return {
      NumOfInvoice,
      NumOfVendor,
      avgLeadTime,
      avgPlanLead,
      year: body.year,
    }  
  }
  let data: any;
  try {
    const res = await fetch(
      API_URI +
        `dashboard/approval-${isInvoice ? "invoice" : "bast"}-lead-time`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    data = await res.json();
    if (data?.data) {
      data = data.data;
      const bln = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

      for (let i = 0; i < 12; i++) {
        avgPlanLead.push(body.kpi);
      }
      /* 
          {
            "month_no": 10,
            "month_name": "Oct-2024",
            "year_no": "2024",
            "company_code": "1492",
            "department_id": "2",
            "total_bast": 23,
            "total_vendor": 20,
            "lead_time": 3.9
        }
   */
      for (const a of bln) {
        let ketemu = [0, 0, 0];
        for (const b of data) {
          if (
            a === parseInt(b.month_no) &&
            parseInt(b.year_no) == parseInt(body.year)
          ) {
            let lead_time =
              parseFloat(b.lead_time) % 1 === 0
                ? parseFloat(b.lead_time)
                : parseFloat(parseFloat(b.lead_time).toFixed(1));
            ketemu = [
              isInvoice ? parseInt(b.total_invoice) : parseInt(b.total_bast),
              parseInt(b.total_vendor),
              lead_time,
            ];
            break;
          }
        }
        ketemu[0] === 0 ? NumOfInvoice.push(0) : NumOfInvoice.push(ketemu[0]);
        ketemu[1] === 0 ? NumOfVendor.push(0) : NumOfVendor.push(ketemu[1]);
        ketemu[2] === 0 ? avgLeadTime.push(0) : avgLeadTime.push(ketemu[2]);
      }
      return {
        NumOfInvoice,
        NumOfVendor,
        avgLeadTime,
        avgPlanLead,
        year: body.year,
      };
    }
  } catch (error) {
    console.log(error);
  }
  // console.log(data);

  // fetch("https://evimbackend.sumbawatimurmining.com/api/dashboard/approval-lead-time", {
  // return fetch(
  //   API_URI + `dashboard/${isInvoice ? "invoice" : "bast"}-kpi-role`,
  //   {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json", // penting
  //       "Access-Control-Allow-Origin": "*",
  //       // Authorization:
  //       //   "Bearer " +
  //       //   JSON.parse(sessionStorage.getItem("shreyu_user") as string)
  //       //     .access_token,
  //     },
  //     method: "POST",
  //     body: JSON.stringify(body),
  //   }
  // )
  //   .then((response) => response.json())
  //   .then((data) => {
  //     console.log(data);
  //     const dataAsli = data.data;
  //     const bln = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  //     const NumOfInvoice = [];
  //     const NumOfVendor = [];
  //     const avgLeadTime = [];
  //     const avgPlanLead = [];
  //     for (let i = 0; i < 12; i++) {
  //       avgPlanLead.push(body.kpi);
  //     }
  //     for (const a of bln) {
  //       let ketemu = [0, 0, 0];
  //       for (const b of dataAsli) {
  //         if (
  //           a === parseInt(b.month_no) &&
  //           parseInt(b.year_no) == parseInt(body.year)
  //         ) {
  //           ketemu = [
  //             parseInt(b.total_bast),
  //             parseInt(b.total_vendor),
  //             parseFloat(b.lead_time),
  //           ];
  //           break;
  //         }
  //       }
  //       ketemu[0] === 0 ? NumOfInvoice.push(0) : NumOfInvoice.push(ketemu[0]);
  //       ketemu[1] === 0 ? NumOfVendor.push(0) : NumOfVendor.push(ketemu[1]);
  //       ketemu[2] === 0 ? avgLeadTime.push(0) : avgLeadTime.push(ketemu[2]);
  //     }
  //     return {
  //       NumOfInvoice,
  //       NumOfVendor,
  //       avgLeadTime,
  //       avgPlanLead,
  //       year: body.year,
  //     };
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });
};

export const getDataKpiTable = async ({ body, isInvoice = true }: any) => {
  if (!body?.approval1 && !body?.approval2) return [];

  const res = await fetch(
    `${API_URI}dashboard/${isInvoice ? "invoice" : "bast"}-kpi-role`,
    {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json", // penting
        "Access-Control-Allow-Origin": "*",
        // Authorization:
        //   "Bearer " +
        //   JSON.parse(sessionStorage.getItem("shreyu_user") as string)
        //     .access_token,
      },
    }
  );
  const data = await res.json();
  console.log(data);
  if (data?.data) {
    return data.data;
  } else {
    return [];
  }
};

export const getDataKpiTableDownload = async ({
  body,
  isInvoice = true,
}: any) => {
  const res = await fetch(
    `${API_URI}dashboard/${isInvoice ? "invoice" : "bast"}-kpi-role`,
    {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json", // penting
        "Access-Control-Allow-Origin": "*",
        // Authorization:
        //   "Bearer " +
        //   JSON.parse(sessionStorage.getItem("shreyu_user") as string)
        //     .access_token,
      },
    }
  );
  const blob = await res.blob();
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement("a");
  a.href = url;
  a.download = "Report.xlsx";
  a.click();
  // if (data?.data) {
  //   return data;
  // } else {
  //   return [];
  // }
};

export const getDataMonthUsers = async ({ body, isInvoice = true }: any) => {
  console.log(body);
  try {
    const res = await fetch(
      `${API_URI}dashboard/approval-user-${isInvoice ? "invoice" : "bast"}-lead-time`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // penting
          "Access-Control-Allow-Origin": "*",
          // Authorization:
          //   "Bearer " +
          //   JSON.parse(sessionStorage.getItem("shreyu_user") as string)
          //     .access_token,
        },
      }
    );
    const data = await res.json();
    console.log(data);
    if (data?.data && data.data?.length > 0) {
      return data.data;
    } else {
      return [];
    }
    // return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getDataMonthUsersTable = async ({ body, isInvoice = true }: any) => {
  console.log(body);
  try {
    const res = await fetch(
      `${API_URI}dashboard/${isInvoice ? "invoice" : "bast"}-kpi-role-user`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // penting
          "Access-Control-Allow-Origin": "*",
          // Authorization:
          //   "Bearer " +
          //   JSON.parse(sessionStorage.getItem("shreyu_user") as string)
          //     .access_token,
        },
      }
    );
    const data = await res.json();
    console.log(data);
    if (data?.data && data.data?.length > 0) {
      return data.data;
    } else {
      return [];
    }
    // return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};


export const dummyUsersKpi = [
  {
      "user_name": "User_1",
      "ontime": 16,
      "delay": 6,
      "total": 22,
      "percent_ontime": "72%",
      "percent_delay": "28%"
  },
  {
      "user_name": "User_2",
      "ontime": 23,
      "delay": 8,
      "total": 31,
      "percent_ontime": "74%",
      "percent_delay": "26%"
  },
  {
      "user_name": "User_3",
      "ontime": 22,
      "delay": 3,
      "total": 25,
      "percent_ontime": "88%",
      "percent_delay": "12%"
  },
  {
      "user_name": "User_4",
      "ontime": 25,
      "delay": 0,
      "total": 25,
      "percent_ontime": "99%",
      "percent_delay": "1%"
  },
  {
      "user_name": "User_5",
      "ontime": 12,
      "delay": 2,
      "total": 14,
      "percent_ontime": "84%",
      "percent_delay": "16%"
  },
  {
      "user_name": "User_6",
      "ontime": 17,
      "delay": 2,
      "total": 19,
      "percent_ontime": "91%",
      "percent_delay": "9%"
  },
  {
      "user_name": "User_7",
      "ontime": 43,
      "delay": 4,
      "total": 47,
      "percent_ontime": "92%",
      "percent_delay": "8%"
  },
  {
      "user_name": "User_8",
      "ontime": 16,
      "delay": 3,
      "total": 19,
      "percent_ontime": "83%",
      "percent_delay": "17%"
  },
  {
      "user_name": "User_9",
      "ontime": 20,
      "delay": 0,
      "total": 20,
      "percent_ontime": "99%",
      "percent_delay": "1%"
  },
  {
      "user_name": "User_10",
      "ontime": 21,
      "delay": 7,
      "total": 28,
      "percent_ontime": "75%",
      "percent_delay": "25%"
  },
  {
      "user_name": "User_11",
      "ontime": 41,
      "delay": 9,
      "total": 50,
      "percent_ontime": "83%",
      "percent_delay": "17%"
  },
  {
      "user_name": "User_12",
      "ontime": 14,
      "delay": 3,
      "total": 17,
      "percent_ontime": "83%",
      "percent_delay": "17%"
  },
  {
      "user_name": "User_13",
      "ontime": 7,
      "delay": 1,
      "total": 8,
      "percent_ontime": "83%",
      "percent_delay": "17%"
  },
  {
      "user_name": "User_14",
      "ontime": 3,
      "delay": 0,
      "total": 3,
      "percent_ontime": "89%",
      "percent_delay": "11%"
  },
  {
      "user_name": "User_15",
      "ontime": 3,
      "delay": 1,
      "total": 4,
      "percent_ontime": "76%",
      "percent_delay": "24%"
  },
  {
      "user_name": "User_16",
      "ontime": 16,
      "delay": 1,
      "total": 17,
      "percent_ontime": "97%",
      "percent_delay": "3%"
  },
  {
      "user_name": "User_17",
      "ontime": 34,
      "delay": 9,
      "total": 43,
      "percent_ontime": "79%",
      "percent_delay": "21%"
  },
  {
      "user_name": "User_18",
      "ontime": 42,
      "delay": 8,
      "total": 50,
      "percent_ontime": "83%",
      "percent_delay": "17%"
  },
  {
      "user_name": "User_19",
      "ontime": 4,
      "delay": 0,
      "total": 4,
      "percent_ontime": "95%",
      "percent_delay": "5%"
  },
  {
      "user_name": "User_20",
      "ontime": 13,
      "delay": 5,
      "total": 18,
      "percent_ontime": "72%",
      "percent_delay": "28%"
  },
  {
      "user_name": "User_21",
      "ontime": 28,
      "delay": 4,
      "total": 32,
      "percent_ontime": "88%",
      "percent_delay": "12%"
  },
  {
      "user_name": "User_22",
      "ontime": 9,
      "delay": 0,
      "total": 9,
      "percent_ontime": "96%",
      "percent_delay": "4%"
  },
  {
      "user_name": "User_23",
      "ontime": 11,
      "delay": 0,
      "total": 11,
      "percent_ontime": "96%",
      "percent_delay": "4%"
  },
  {
      "user_name": "User_24",
      "ontime": 11,
      "delay": 2,
      "total": 13,
      "percent_ontime": "84%",
      "percent_delay": "16%"
  },
  {
      "user_name": "User_25",
      "ontime": 10,
      "delay": 3,
      "total": 13,
      "percent_ontime": "75%",
      "percent_delay": "25%"
  },
  {
      "user_name": "User_26",
      "ontime": 20,
      "delay": 1,
      "total": 21,
      "percent_ontime": "95%",
      "percent_delay": "5%"
  },
  {
      "user_name": "User_27",
      "ontime": 29,
      "delay": 3,
      "total": 32,
      "percent_ontime": "92%",
      "percent_delay": "8%"
  },
  {
      "user_name": "User_28",
      "ontime": 22,
      "delay": 4,
      "total": 26,
      "percent_ontime": "86%",
      "percent_delay": "14%"
  },
  {
      "user_name": "User_29",
      "ontime": 37,
      "delay": 7,
      "total": 44,
      "percent_ontime": "85%",
      "percent_delay": "15%"
  },
  {
      "user_name": "User_30",
      "ontime": 3,
      "delay": 1,
      "total": 4,
      "percent_ontime": "72%",
      "percent_delay": "28%"
  },
  // {
  //     "user_name": "User_31",
  //     "ontime": 5,
  //     "delay": 1,
  //     "total": 6,
  //     "percent_ontime": "81%",
  //     "percent_delay": "19%"
  // },
  // {
  //     "user_name": "User_32",
  //     "ontime": 36,
  //     "delay": 13,
  //     "total": 49,
  //     "percent_ontime": "74%",
  //     "percent_delay": "26%"
  // },
  // {
  //     "user_name": "User_33",
  //     "ontime": 8,
  //     "delay": 3,
  //     "total": 11,
  //     "percent_ontime": "77%",
  //     "percent_delay": "23%"
  // },
  // {
  //     "user_name": "User_34",
  //     "ontime": 20,
  //     "delay": 7,
  //     "total": 27,
  //     "percent_ontime": "75%",
  //     "percent_delay": "25%"
  // },
  // {
  //     "user_name": "User_35",
  //     "ontime": 18,
  //     "delay": 4,
  //     "total": 22,
  //     "percent_ontime": "81%",
  //     "percent_delay": "19%"
  // },
  // {
  //     "user_name": "User_36",
  //     "ontime": 21,
  //     "delay": 9,
  //     "total": 30,
  //     "percent_ontime": "71%",
  //     "percent_delay": "29%"
  // },
  // {
  //     "user_name": "User_37",
  //     "ontime": 29,
  //     "delay": 6,
  //     "total": 35,
  //     "percent_ontime": "82%",
  //     "percent_delay": "18%"
  // },
  // {
  //     "user_name": "User_38",
  //     "ontime": 7,
  //     "delay": 1,
  //     "total": 8,
  //     "percent_ontime": "91%",
  //     "percent_delay": "9%"
  // },
  // {
  //     "user_name": "User_39",
  //     "ontime": 38,
  //     "delay": 7,
  //     "total": 45,
  //     "percent_ontime": "85%",
  //     "percent_delay": "15%"
  // },
  // {
  //     "user_name": "User_40",
  //     "ontime": 22,
  //     "delay": 3,
  //     "total": 25,
  //     "percent_ontime": "88%",
  //     "percent_delay": "12%"
  // },
  // {
  //     "user_name": "User_41",
  //     "ontime": 13,
  //     "delay": 2,
  //     "total": 15,
  //     "percent_ontime": "89%",
  //     "percent_delay": "11%"
  // },
  // {
  //     "user_name": "User_42",
  //     "ontime": 17,
  //     "delay": 5,
  //     "total": 22,
  //     "percent_ontime": "76%",
  //     "percent_delay": "24%"
  // },
  // {
  //     "user_name": "User_43",
  //     "ontime": 34,
  //     "delay": 7,
  //     "total": 41,
  //     "percent_ontime": "82%",
  //     "percent_delay": "18%"
  // },
  // {
  //     "user_name": "User_44",
  //     "ontime": 2,
  //     "delay": 1,
  //     "total": 3,
  //     "percent_ontime": "70%",
  //     "percent_delay": "30%"
  // },
  // {
  //     "user_name": "User_45",
  //     "ontime": 18,
  //     "delay": 7,
  //     "total": 25,
  //     "percent_ontime": "72%",
  //     "percent_delay": "28%"
  // },
  // {
  //     "user_name": "User_46",
  //     "ontime": 13,
  //     "delay": 2,
  //     "total": 15,
  //     "percent_ontime": "87%",
  //     "percent_delay": "13%"
  // },
  // {
  //     "user_name": "User_47",
  //     "ontime": 26,
  //     "delay": 10,
  //     "total": 36,
  //     "percent_ontime": "72%",
  //     "percent_delay": "28%"
  // },
  // {
  //     "user_name": "User_48",
  //     "ontime": 4,
  //     "delay": 1,
  //     "total": 5,
  //     "percent_ontime": "80%",
  //     "percent_delay": "20%"
  // },
  // {
  //     "user_name": "User_49",
  //     "ontime": 17,
  //     "delay": 3,
  //     "total": 20,
  //     "percent_ontime": "85%",
  //     "percent_delay": "15%"
  // },
  // {
  //     "user_name": "User_50",
  //     "ontime": 43,
  //     "delay": 4,
  //     "total": 47,
  //     "percent_ontime": "92%",
  //     "percent_delay": "8%"
  // }
]