import { useMemo, useState } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import Select from "react-select";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import axios from "axios";

// import {schemaResolverBast} from './validation/yupValidation'
// import GroupInput from "../../components/MyComponents/Input/GroupInput";
import { BastItem } from "./data";
import { toBase64 } from "../../utils";
import CreateNewBastItemModal from "./components/CreateNewBastModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import GroupInputFile from "../../components/MyComponents/Input/GroupInputFile";
import { changeUStoID, removeDot, replaceToDot } from "../../helpers/utils";
import { getUserVendorData } from "../../helpers/getUserVendor";
import { CURRENT_COMPANY, USER_COMPANIES } from "../../helpers/api/apiCore";
import { useGetUserRole } from "../../hooks";

// types
type AddStockCol = {
  stock_code: any;
  description: any;
  uom: any;
  qty: any;
  currency_id: any;
  unit_price: any;
  unit_price_in_idr?: any;
  total_value?: any;
  total_value_idr?: any;
  is_reimbursement?: any;
  currName?: any;
  scName?: any;
  uomName?: any;
  currency_rate?: any;
};

const { vendor_code, company_code, vendor_type } = getUserVendorData();
const ucc = sessionStorage.getItem(CURRENT_COMPANY) as string;
const ucs = sessionStorage.getItem(USER_COMPANIES) as string;
let userCurrentCompany: any;
let userCompanies: any;
if (ucc?.[0] === "{" || ucc?.[0] === "[") {
  userCurrentCompany = JSON.parse(ucc);
}
if (ucs?.[0] === "{" || ucs?.[0] === "[") {
  userCompanies = JSON.parse(ucs);
}
// console.log(userCurrentCompany);
// console.log(userCompanies);

const FormBASTClone = ({
  formData,
  pageTitle = "Create BAST",
  userType = "",
  loading,
}: {
  formData: any;
  pageTitle?: string;
  userType?: string;
  loading?: boolean;
}) => {
  console.log(formData)
  const navigate = useNavigate();
  const isStm = userCurrentCompany?.company_code === "1492";
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  // React Hook Form
  const schemaResolverBast = yupResolver(
    yup.object().shape({
      // bast_no: yup.string().required("Required"),
      good_or_services: yup.mixed().required("Required"),
      company_sub_id: isStm
        ? yup.mixed().required("Required")
        : yup.mixed().nullable(),
      // migo_or_ses: yup.string().required("Required"),
      // gl_no: yup.string().required("Required"),
      // wbs_no: yup.string().required("Required"),
      posting_date: yup.string().required("Required"),
      contract_no: yup.mixed().required("Required"),
      contract_sap: yup
        .string()
        .required("Required, Please Choose Contract Number"),
      // po_no: yup.string().when("good_or_services", {
      //   is: (good_or_services: any) => good_or_services === "1",
      //   then: yup.string().required("Required if goods"),
      // }),
      // suplier_id: yup.string().required("Required"),
      suplier_name: yup.string().required("Required"),
      delivery_note: yup.string(),
      bill_of_leading: yup.string(),
      airway_bill: yup.string(),
      // requested_by: yup.string().required("Required"),
      notes: yup.string(),
      //
      timesheet_file: yup.mixed().when("good_or_services", {
        is: (good_or_services: any) => good_or_services === "2",
        then: yup.mixed().required("Required if services"),
      }),
      sumary_reimburse_file: yup.mixed(),
      proforma_invoice_file: yup.mixed().required("Required"),
      delivery_order_file: yup.mixed().when("good_or_services", {
        is: (good_or_services: any) => good_or_services === "1",
        then: yup.mixed().required("Required if goods"),
      }),
      bill_of_leading_file: yup.mixed().when("bill_of_leading", {
        is: (bill_of_leading: any) => bill_of_leading.length > 0,
        then: yup.mixed().required("Required if bill of lading is filled"),
      }),
      airway_bill_file: yup.mixed().when("airway_bill", {
        is: (airway_bill: any) => airway_bill.length > 0,
        then: yup.mixed().required("Required if airway bill is filled"),
      }),
      pib_file: yup.mixed(),
      po_file: yup.mixed().when("good_or_services", {
        is: (good_or_services: any) => good_or_services === "1",
        then: yup.mixed().required("Required if goods"),
      }),
      // po_file: yup.mixed().when("good_or_services", {
      //   is: (good_or_services: any) => good_or_services === "1",
      //   then: yup.mixed().required("Required if goods"),
      // }),
      // contract_file: yup.mixed().required("Contract File Required"),
      contract_file: yup.mixed().required("Confirmation File Required"),
      others_file: yup.mixed(),
      // BAST Items
      // grand_total: yup.mixed(),
      // currency_id: yup.mixed(),
      // approval_status_id: yup.mixed(),
    })
  );
  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  }: // reset,
  any = useForm({
    defaultValues: {
      // bast_no: "1122" + Math.ceil(Math.random() * 10000000),
    },
    resolver: schemaResolverBast,
  });
  const watchAll = watch();
  // console.log(watchAll, "WATCHALL");
  // console.log(errors, "FORM ERRORS");
  const { user } = useSelector(({ Auth }: any) => Auth);
  const {
    data: { name },
  } = user;
  const {userId} = useGetUserRole()
  // console.log(name, "USER");

  // const [goodsOrServices, setGoodsOrServices] = useState(
  //   formData.mineralOrGeothermal.name
  // );
  const { t } = useTranslation();
  // const [geoOrMin, setGeoOrMin] = useState(formData.mineralOrGeothermal.name);
  const [bastItems, setBastItems] = useState<any>(formData.bastTableItem);
  // console.log(bastItems)
  const [bastItemIndex, setBastItemIndex] = useState<any>(null);
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const [newBastItem, setNewBastItem] = useState<any>({
    description: "",
    uom: "",
    qty: null,
    currency_id: "",
    unit_price: "",
    total_value: "",
    is_reimbursement: "",
    stock_code: "",
  });
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const [formFieldAttr2, setFormData2] = useState([
    {
      controlId: "timesheet_file",
      label: t("Timesheet"),
      type: "file",
      defaultValue: formData?.timesheet?.name,
      readOnly: formData?.timesheet?.isDisabled,
    },
    {
      controlId: "sumary_reimburse_file",
      label: t("Summary Reimburse"),
      type: "file",
      defaultValue: formData?.summaryReimburse?.name,
      readOnly: formData?.summaryReimburse?.isDisabled,
    },
    {
      controlId: "proforma_invoice_file",
      label: t("Proforma Invoice"),
      type: "file",
      defaultValue: formData?.proformaInvoice?.name,
      readOnly: formData?.proformaInvoice?.isDisabled,
    },
    {
      controlId: "delivery_order_file",
      label: t("Delivery Order"),
      type: "file",
      // defaultValue: formData.proformaInvoice.name,
      // readOnly: formData.proformaInvoice.isDisabled,
    },
    {
      controlId: "bill_of_leading_file",
      label: t("Bill of Lading Attachment"),
      type: "file",
      // defaultValue: formData.proformaInvoice.name,
      // readOnly: formData.proformaInvoice.isDisabled,
    },
    {
      controlId: "airway_bill_file",
      label: t("Airway Bill Attachment"),
      type: "file",
      // defaultValue: formData.proformaInvoice.name,
      // readOnly: formData.proformaInvoice.isDisabled,
    },
    {
      controlId: "pib_file",
      label: t("PB1 Attachment"),
      type: "file",
      // defaultValue: formData.proformaInvoice.name,
      // readOnly: formData.proformaInvoice.isDisabled,
    },
    {
      controlId: "po_file",
      label: t("Purchase Order Attachment"),
      type: "file",
      // defaultValue: formData.proformaInvoice.name,
      // readOnly: formData.proformaInvoice.isDisabled,
    },
    {
      controlId: "contract_file",
      label: t("Confirmation Sheet"),
      type: "file",
      // defaultValue: formData.proformaInvoice.name,
      // readOnly: formData.proformaInvoice.isDisabled,
    },
    {
      controlId: "others_file",
      label: t("Others Attachment"),
      type: "file",
      // defaultValue: formData.proformaInvoice.name,
      // readOnly: formData.proformaInvoice.isDisabled,
    },
  ]);
  const [openModalDelRow, setOpenModalDelRow] = useState(false);

  const columnMRTCreateBAST = useMemo<MRT_ColumnDef<AddStockCol>[]>(
    () => [
      {
        accessorKey: "stock_code",
        header: t("Stock Code"),
        size: 80,
        Cell: ({ row }) => {
          return <p>{row.original.scName}</p>;
        },
      },
      {
        accessorKey: "description",
        header: t("Description"),
        size: 80,
      },
      {
        accessorKey: "uom",
        header: "UOM",
        size: 80,
        Cell: ({ cell, row }) => {
          return <p className="m-0">{row.original.uomName}</p>;
        },
      },
      {
        accessorKey: "qty",
        header: "QTY",
        size: 80,
        Cell: ({ row }) => {
          return <div>{changeUStoID(removeDot(String(row.original.qty)))}</div>;
        },
      },
      {
        accessorKey: "currency_id",
        header: "Curr",
        size: 80,
        Cell: ({ row }) => {
          return <p className="m-0">{row.original.currName}</p>;
        },
      },
      {
        accessorKey: "currency_rate",
        header: "Curr Rate",
        size: 80,
        Cell: ({ row }) => {
          if (row.original.currName !== "IDR")
            return <p className="m-0">{row.original.currency_rate}</p>;
        },
      },
      {
        accessorKey: "unit_price",
        header: t("Original Unit Price"),
        size: 80,
        Cell: ({ row }) => {
          return (
            <div>
              {changeUStoID(removeDot(String(row.original.unit_price)))}
            </div>
          );
        },
      },
      {
        accessorKey: "total_value",
        header: t("Original Total Value"),
        size: 80,
        Cell: ({ row }) => {
          return (
            <div>
              {changeUStoID(removeDot(String(row.original.total_value)))}
            </div>
          );
        },
      },
      {
        accessorKey: "unit_price_in_idr",
        header: t("Local Unit Price"),
        size: 80,
        Cell: ({ row }) => {
          return (
            <div>
              {changeUStoID(removeDot(String(row.original.unit_price_in_idr)))}
            </div>
          );
        },
      },
      {
        accessorKey: "total_value_idr",
        header: t("Local Total Value"),
        size: 80,
        Cell: ({ row }) => {
          return (
            <div>
              {changeUStoID(removeDot(String(row.original.total_value_idr)))}
            </div>
          );
        },
      },
      {
        accessorKey: "is_reimbursement",
        header: t("Is Reimbursement"),
        size: 80,
        Cell: ({ cell, row }) => {
          let value: any = cell.getValue();
          value = value === "1" ? "Yes" : "No";
          return <p className="m-0">{value}</p>;
        },
      },
    ],
    []
  );

  // const fillData = () => {
  //   console.log("FILL");
  //   const data1: any = {
  //     // bast_no: "1122149210",
  //     good_or_services: "1",
  //     company_sub_id: "1",
  //     // migo_or_ses: "qwer",
  //     // gl_no: "1234",
  //     // wbs_no: "1234",
  //     posting_date: new Date().toISOString().split("T")[0],
  //     contract_no: { label: "STM-KPOP28-025", value: "38" },
  //     contract_sap: "6942009786",
  //     // po_no: "123",
  //     // suplier_name: "ESIT CANADA ENTERPRISE SERVICES CO",
  //     delivery_note: "qwer",
  //     // bill_of_leading: "123",
  //     // airway_bill: "123",
  //     notes: "asdfasdf",
  //   };
  //   for (const key in data1) {
  //     setValue(key, data1[key]);
  //   }
  //   setBastItems((prev: any): any => [
  //     ...prev,
  //     {
  //       is_reimbursement: "1",
  //       stock_code: "2",
  //       description: "qwer",
  //       uom: "1",
  //       qty: "11",
  //       currency_id: "2",
  //       unit_price: "4",
  //       total_value: "44",
  //     },
  //     {
  //       is_reimbursement: "0",
  //       stock_code: "1",
  //       description: "qwer",
  //       uom: "2",
  //       qty: "13",
  //       currency_id: "1",
  //       unit_price: "8",
  //       total_value: "104",
  //     },
  //   ]);
  // };

  return (
    <>
      <Card className="mt-3">
        <div className="card-header bg-vale" 
        // onClick={() => fillData()}
        >
          {pageTitle}
        </div>

        <Form className="form-horizontal">
          <Card.Body>
            <Row className="mb-2">
              <Col md={4}>
                <Form.Group>
                  <Form.Label>BAST No.</Form.Label>
                </Form.Group>
                <Form.Control
                  type="text"
                  isInvalid={errors.bast_no ? true : false}
                  disabled
                  {...register("bast_no")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.bast_no?.message}
                </Form.Control.Feedback>
              </Col>
              <Col md={{ span: 3, offset: 5 }} className="my-2">
                <Form.Group
                  className="d-flex flex-direction-column align-items-center mb-4"
                  controlId="good_or_services"
                >
                  <Form.Check
                    className="me-3"
                    type="radio"
                    label={t("Goods")}
                    value="1"
                    isInvalid={errors.good_or_services ? true : false}
                    // disabled={formData?.goodsOrServices.isDisabled}
                    {...register("good_or_services")}
                    // name="goodsOrServices"
                    // checked={goodsOrServices === "goods"}
                    // onChange={(e) => {
                    //   setGoodsOrServices(e.target.value);
                    // }}
                    feedback={errors.good_or_services?.message}
                    feedbackType="invalid"
                  />
                  <Form.Check
                    type="radio"
                    label={t("Services")}
                    value="2"
                    isInvalid={errors.good_or_services ? true : false}
                    {...register("good_or_services")}
                  />
                </Form.Group>
                {userCurrentCompany?.company_code === "1492" && (
                  <Form.Group className="d-flex flex-direction-column align-items-center mt-2">
                    <Form.Check
                      className="me-3"
                      type="radio"
                      label="Mineral"
                      value={1}
                      isInvalid={errors.company_sub_id ? true : false}
                      {...register("company_sub_id")}
                      feedback={errors.company_sub_id?.message}
                      feedbackType="invalid"
                    />
                    <Form.Check
                      type="radio"
                      label="Geothermal"
                      value={2}
                      isInvalid={errors.company_sub_id ? true : false}
                      {...register("company_sub_id")}
                    />
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={4} className="mb-3">
                <Form.Group className="">
                  <Form.Label>MIGO / SES No.</Form.Label>
                  <Form.Control
                    type="text"
                    disabled={true}
                    {...register("migo_or_ses")}
                  />
                </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group>
                  <Form.Label>GL No.</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly={true}
                    {...register("gl_no")}
                  />
                </Form.Group>
              </Col>
              {userCurrentCompany?.company_code === "1492" ? (
                <Col md={4} className="mb-3">
                  <Form.Group>
                    <Form.Label>{"WBS No."}</Form.Label>
                    <Form.Control
                      type="text"
                      readOnly={true}
                      {...register("wbs_no")}
                    />
                  </Form.Group>
                </Col>
              ) : (
                <Col md={4} className="mb-3">
                  <Form.Group>
                    <Form.Label>{"Cost Center"}</Form.Label>
                    <Form.Control
                      type="text"
                      readOnly={true}
                      {...register("coscenter_no")}
                    />
                  </Form.Group>
                </Col>
              )}
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Posting Date")}</Form.Label> <br />
                  <Form.Control
                    type="date"
                    defaultValue={new Date().toISOString().split("T")[0]}
                    isInvalid={errors.posting_date ? true : false}
                    disabled={false}
                    {...register("posting_date")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.posting_date?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3 position-relative">
                  <div className="position-absolute" style={{ right: 0 }}>
                  {t("*Required")}
                  </div>
                  <Form.Label>{t("Contract/BPO No")}</Form.Label>
                  {/* <Controller
                    name={"contract_no"}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        isMulti={false}
                        onChange={(e) => {
                          // console.log(e)
                          setValue("contract_sap", e?.contract_sap);
                          setValue("department_id", e?.department_code);
                          field.onChange(e);
                        }}
                        options={formData?.contract_no?.options}
                        defaultValue={formData?.contractNo?.options?.[0]}
                        className={`${
                          formData?.contractNo?.isDisabled &&
                          "react-select-disabled"
                        } react-select-container`}
                        isDisabled={formData?.contractNo?.isDisabled}
                      />
                    )}
                  /> */}
                  {errors.contract_no && (
                    <div
                      style={{
                        width: "100%",
                        marginTop: "0.25rem",
                        fontSize: "0.75rem",
                        color: "#ff5c75",
                        position: "absolute",
                      }}
                    >
                      {errors.contract_no?.message}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group>
                  <Form.Label>{t("Contract SAP")}</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly={true}
                    isInvalid={errors.contract_sap ? true : false}
                    value={watchAll?.contractNo?.contract_sap}
                    {...register("contract_sap")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.contract_sap?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group>
                  <Form.Label>PO No.</Form.Label>
                  <Form.Control
                    type="text"
                    // readOnly={true}
                    disabled
                    isInvalid={errors.po_no ? true : false}
                    {...register("po_no")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.po_no?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group>
                  <Form.Label>{t("Supplier Name")}</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly={true}
                    defaultValue={formData?.supplierName?.name}
                    {...register("suplier_name")}
                    value={name}
                  />
                </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group>
                  <Form.Label>{t("Delivery Note")}</Form.Label>
                  <Form.Control type="text" {...register("delivery_note")} />
                </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group>
                  <Form.Label>{t("Bill of Lading (if any)")}</Form.Label>
                  <Form.Control type="text" {...register("bill_of_leading")} />
                </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group>
                  <Form.Label>{t("Airway Bill (if any)")}</Form.Label>
                  <Form.Control type="text" {...register("airway_bill")} />
                </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group>
                  <Form.Label>{t("Requested By")}</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={formData?.requestedBy?.name}
                    readOnly={true}
                    {...register("requested_by")}
                  />
                </Form.Group>
              </Col>
              <Col md={12} className="mb-3">
                <Form.Group>
                  <Form.Label>{t("Notes")}</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={formData?.notes?.name}
                    {...register("notes")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <GroupInputFile
              data={formFieldAttr2}
              control={control}
              setValue={setValue}
              errors={errors}
              getValues={getValues}
              watch={watch}
              specialConditional={{
                is_reimbursement: bastItems.some(
                  (el: any) => el.is_reimbursement === "1"
                ),
              }}
            />
            <Row>
              <Col md={12}>
                <MaterialReactTable
                  columns={columnMRTCreateBAST}
                  data={bastItems}
                  enableEditing
                  renderRowActions={({ row, table }) => {
                    return (
                      <Box sx={{ display: "flex", gap: "1rem" }}>
                        <Tooltip arrow placement="left" title="Edit">
                          <IconButton
                            onClick={() => {
                              setNewBastItem(bastItems[row.index]);
                              setBastItemIndex(row.index);
                              setCreateModalOpen(true);
                              setIsEditModal(true);
                              // bastItems[row.index]
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="left" title="Edit">
                          <IconButton
                            onClick={() => {
                              setOpenModalDelRow(true);
                              setBastItemIndex(row.index);
                              // bastItems[row.index]
                            }}
                          >
                            <Delete color="error" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    );
                  }}
                  renderTopToolbarCustomActions={() => (
                    <>
                      {userType === "vendor" && (
                        <Button
                          onClick={() => {
                            setCreateModalOpen(true);
                            setNewBastItem({
                              description: "",
                              uom: "",
                              qty: null,
                              currency_id: "",
                              unit_price: "",
                              total_value: "",
                              is_reimbursement: "",
                              stock_code: "",
                            });
                            setIsEditModal(false);
                          }}
                          className="vale-button"
                        >
                          {t("Add Row")}
                        </Button>
                      )}
                    </>
                  )}
                />
              </Col>
            </Row>
            <div className="d-flex flex-row-reverse">
              <Button
                className="mt-3 vale-button"
                onClick={handleSubmit((data: any) => {
                  // console.log(data, "DATA SUBMIT");
                  // console.log(bastItems);
                  setIsSubmitDisabled(true);
                  if (bastItems.length === 0) {
                    toast.error("At least 1 item must be added");
                    setIsSubmitDisabled(false);
                    return;
                  }
                  console.log(bastItems);
                  if (
                    bastItems.some((el: any) => el.is_reimbursement === "1") &&
                    !getValues("sumary_reimburse_file")
                  ) {
                    toast.error(
                      "If is reimbursement, summary reimbursement file must be attached"
                    );
                    setIsSubmitDisabled(false);
                    return;
                  }
                  const mapBastItems: any = [];
                  for (let i = 0; i < bastItems.length; i++) {
                    const a = bastItems[i];
                    mapBastItems.push({ ...a });
                    mapBastItems[i].qty = replaceToDot(removeDot(a?.qty));
                    mapBastItems[i].total_value = replaceToDot(
                      removeDot(a?.total_value)
                    );
                    mapBastItems[i].total_value_idr = replaceToDot(
                      removeDot(a?.total_value_idr)
                    );
                    mapBastItems[i].unit_price = replaceToDot(
                      removeDot(a?.unit_price)
                    );
                    mapBastItems[i].currency_rate = replaceToDot(
                      removeDot(a?.currency_rate)
                    );
                    mapBastItems[i].unit_price_in_idr = replaceToDot(
                      removeDot(a?.unit_price_in_idr)
                    );
                  }
                  submitBast({
                    bastItems: mapBastItems,
                    getValues,
                    navigate,
                    setIsSubmitDisabled,
                    user_id: userId
                  });
                  // console.log({ bastItems: mapBastItems, ...getValues() });
                })}
                disabled={isSubmitDisabled}
                // disabled={true}
              >
                Submit
              </Button>
            </div>
          </Card.Body>
        </Form>
      </Card>
      <CreateNewBastItemModal
        open={createModalOpen}
        onClose={() => {
          // reset()
          setCreateModalOpen(false);
        }}
        index={bastItemIndex}
        item={newBastItem}
        onSubmit={(newRow: any): any => {
          // console.log(newRow)
          if (newRow.currency_id !== 1 && newRow.amount_in_idr) {
            newRow.unit_price = newRow.amount_in_idr;
          }
          if (isEditModal) {
            setBastItems((prev: any[]): any => {
              prev.splice(bastItemIndex, 1, newRow);
              return [...prev];
            });
          } else {
            setBastItems((prev: any[]): any => [...prev, newRow]);
          }
        }}
      />
      <ModalConfirmRowDelete
        open={openModalDelRow}
        onClose={() => setOpenModalDelRow(false)}
        onYes={() => {
          setBastItems((prev: any): any => {
            prev.splice(bastItemIndex, 1);
            return [...prev];
          });
          setOpenModalDelRow(false);
        }}
      />
    </>
  );
};

const submitBast = async ({
  bastItems,
  getValues,
  navigate,
  setIsSubmitDisabled,
  user_id
}: any) => {
  try {
    const bast_detail = bastItems.map((el: any) => {
      delete el.currName
      delete el.scName
      delete el.uomName
      return el
    });
    const contract_no = getValues("contract_no")?.value;
    // HANDLE FILES TO BASE64
    const files: any = {
      airway_bill_file: getValues("airway_bill_file"),
      bill_of_leading_file: getValues("bill_of_leading_file"),
      contract_file: getValues("contract_file"),
      delivery_order_file: getValues("delivery_order_file"),
      others_file: getValues("others_file"),
      pib_file: getValues("pib_file"),
      po_file: getValues("po_file"),
      proforma_invoice_file: getValues("proforma_invoice_file"),
      sumary_reimburse_file: getValues("sumary_reimburse_file"),
      timesheet_file: getValues("timesheet_file"),
    };
    const filesBase64: any = {};
    try {
      for (const a of Object.keys(files)) {
        if (files[a]) {
          console.log(files[a]);
          const b64: string = (await toBase64(files[a])) as string;
          console.log(b64);
          filesBase64[a] = b64;
        }
      }
    } catch (error) {
      console.log(error);
      // setIsSubmitDisabled(false);
    }
    const grand_total = bast_detail.reduce((total: number, curVal: any) => {
      return total + parseFloat(curVal.total_value) 
    }, 0).toFixed(2)
    const grand_total_idr = bast_detail.reduce((total: number, curVal: any) => {
      return total + parseFloat(curVal.total_value_idr) 
    }, 0).toFixed(2)
    const data: any = {
      ...getValues(),
      ...filesBase64,
      bast_detail,
      contract_no,
      // bast_no: "1122" + Math.ceil(Math.random() * 10000000),
      approval_status_id: getValues("good_or_services") === "1" ? "1" : "2",
      vendor_code,
      company_code: userCurrentCompany?.company_code,
      vendor_type,
      user_id,
      grand_total,
      grand_total_idr
    };
    console.log(data, "DATA FETCH");
    // setIsSubmitDisabled(false);
    // return 
    axios({
      // url: "/test-bast",
      url: "/bast-clone",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization:
          "Bearer " +
          JSON.parse(sessionStorage.getItem("shreyu_user") as string)
            .access_token,
      },
      data: [data],
    })
      .then((res: any) => {
        console.log(res, "RES BAST CREATE");
        if (res.data.success) {
          navigate("/new/bast/list");
          toast.success("BAST Added");
        } else {
          toast.error("Failed to Submit BAST");
        }
      })
      .catch((err) => {
        console.log(err, "ERR BAST CREATE");
        toast.error("Failed to Submit BAST");
      })
      .finally(() => {
        setIsSubmitDisabled(false);
      });
  } catch (error) {
    toast.error("Failed to Submit BAST");
    setIsSubmitDisabled(false);
  } finally {
    // setIsSubmitDisabled(false);
  }
};

const ModalConfirmRowDelete = ({ open, onClose, onYes }: any) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">{t("Confirm Delete Row")}</DialogTitle>
      <DialogContent>Are you sure?</DialogContent>

      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose} variant="secondary">
          {t("No")}
        </Button>
        <Button onClick={onYes} className="vale-button">
          {t("Yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormBASTClone;
