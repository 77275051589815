import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { useMemo, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import * as XLSX from "xlsx/xlsx.mjs";
import { API_URI } from "../../constants";
import {
  addCommas,
  changeUStoID,
  removeDot,
  replaceToComma,
  replaceToDot,
} from "../../helpers/utils";
import Loading from "../../components/MyComponents/Loading";
import FeatherIcon from "feather-icons-react";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";

type RkabColumn = {
  no?: any;
  description?: any;
  budget?: any;
  actual?: any;
  remaining?: any;
  jan?: any;
  feb?: any;
  mar?: any;
  apr?: any;
  may?: any;
  jun?: any;
  jul?: any;
  aug?: any;
  sep?: any;
  oct?: any;
  nov?: any;
  dec?: any;
  totalFromMonths?: any;
  q1?: any;
  q2?: any;
  q3?: any;
  q4?: any;
  totalFromQuarters?: any;
  jan2?: any;
  feb2?: any;
  mar2?: any;
  apr2?: any;
  may2?: any;
  jun2?: any;
  jul2?: any;
  aug2?: any;
  sep2?: any;
  oct2?: any;
  nov2?: any;
  dec2?: any;
  totalFromMonths2?: any;
  q12?: any;
  q22?: any;
  q32?: any;
  q42?: any;
  totalFromQuarters2?: any;
  mapping?: any;
  map_doc?: any;
};

const initialRowData: RkabColumn[] = [
  {
    description: "Direct Cost, Consist of:",
    // map_doc:[],
  },
  {
    no: 1,
    description: "Topography and Geology Mapping:",
    // map_doc:[],
  },
  {
    no: "1a",
    mapping: "a1a",
    description: "* Topography Mapping",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
    map_doc:[],
  },
  {
    no: "1b",
    mapping: "a1b",
    description: "* Geology Local Mapping",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
    map_doc:[],
  },
  {
    no: 2,
    mapping: "a2",
    description: "Surface Geophysics Survey",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: 3,
    mapping: "a3",
    description: "Open Hole Drilling",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: 4,
    mapping: "a4",
    description: "Core Drilling",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: 5,
    mapping: "a5",
    description: "Geochemistry Sample Analysis",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: 6,
    mapping: "a6",
    description: "Geotechnical Sample Analysis",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: 7,
    mapping: "a7",
    description: "Metallurgy Analysis",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: 8,
    description:
      "Geotechnical Study, Geophysics Study, Hydro & Hydrogeology Study and Software:",
  },
  {
    no: "8a",
    mapping: "a8a",
    description: "* Geotechnical Study",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: "8b",
    mapping: "a8b",
    description: "* Geophysics Study",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: "8c",
    mapping: "a8c",
    description: "* Hydrology & Hydrogeology study",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: "8d",
    mapping: "a8d",
    description: "* Software, Equipment, Materials and Others",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: 9,
    description: "Environment Studies:",
  },
  {
    no: "9a",
    mapping: "a9a",
    description: "* Environmental Monitoring",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: "9b",
    mapping: "a9b",
    description: "* Environmental Management",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: "9c",
    mapping: "a9c",
    description: "* Environmental Base Line Study",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: 10,
    description: "Occupational, Health and Safety (K3L):",
  },
  {
    no: "10a",
    mapping: "a10a",
    description: "* Mining Occoupational and Health",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: "10b",
    mapping: "a10b",
    description: "* Mining Operational Safety",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: "10c",
    mapping: "a10c",
    description: "* National OHS Month Implementation",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: 11,
    mapping: "a11",
    description: "Helicopter Rental",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: 12,
    mapping: "a12",
    description: "Fuel",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: 13,
    mapping: "a13",
    description: "Drillpad Preparation and Rehabilitation",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: 14,
    description: "Purchase Goods and Equipment:",
  },
  {
    no: "14a",
    mapping: "a14a",
    description: "* Import",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: "14b",
    mapping: "a14b",
    description:
      "* National Expenditures for Bulk Storage Fuel (Tank) construction",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: "14c",
    mapping: "a14c",
    description: "* Fuel Storage",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: "14d",
    mapping: "a14d",
    description: "*Other National Expenditures",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: 15,
    mapping: "a15",
    description: "Consultant Fees (included IT cost)",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: 16,
    mapping: "a16",
    description: "Exploration Access Construction",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: 17,
    mapping: "a17",
    description: "GED Test",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: 18,
    mapping: "a18",
    description: "GED Construction",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    description: "SUB-TOTAL",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    description: "Indirect Cost:",
  },
  {
    no: 1,
    mapping: "b1",
    description: "Salary",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: 2,
    mapping: "b2",
    description: "Administration (Include Permitting)",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: 3,
    mapping: "b3",
    description: "Food Supply and Camp Site Food",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: 4,
    mapping: "b4",
    description: "Building and Maintenance Camp Site",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: 5,
    mapping: "b5",
    description: "Official Travel and Logistic",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: 6,
    mapping: "b6",
    description: "Community Development and Community Relation",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: 7,
    mapping: "b7",
    description: "Training, etc",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    no: 8,
    mapping: "b8",
    description: "State Renevue",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    description: "SUB-TOTAL",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
  {
    description: "TOTAL",
    budget: 0,
    actual: 0,
    remaining: 0,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    totalFromMonths: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    totalFromQuarters: 0,
    jan2: 0,
    feb2: 0,
    mar2: 0,
    apr2: 0,
    may2: 0,
    jun2: 0,
    jul2: 0,
    aug2: 0,
    sep2: 0,
    oct2: 0,
    nov2: 0,
    dec2: 0,
    totalFromMonths2: 0,
    q12: 0,
    q22: 0,
    q32: 0,
    q42: 0,
    totalFromQuarters2: 0,
  },
];

export default function Rkab() {
  const [openImportBgReport, setOpenImportBgReport] = useState<boolean>(false);
  const [file, setFile] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [load, setLoad] = useState(false);
  const [rkabRowData, setRkabRowData] = useState<RkabColumn[]>(
    JSON.parse(JSON.stringify(initialRowData))
  );
  const [rawExcelData, setRawExcelData] = useState<any[]>([]);
  const [year, setYear] = useState<any>(new Date().getFullYear().toString())
  const [year2, setYear2] = useState<any>(new Date().getFullYear().toString())
  const yearOptionsFunc=() =>{
    const yearOption = [];
    for (let i = 2023; i <= new Date().getFullYear(); i++) {
      yearOption.push({ label: i.toString(), value: i.toString() });
    }
    return yearOption
  }
  const monthOptions=[
    {label: "Jan", value: 1},{label: "Feb", value: 2},
    {label: "Mar", value: 3},{label: "Apr", value: 4},
    {label: "May", value: 5},{label: "Jun", value: 6},
    {label: "Jul", value: 7},{label: "Aug", value: 8},
    {label: "Sep", value: 9},{label: "Oct", value: 10},
    {label: "Nov", value: 11},{label: "Dec", value: 12},
  ]
  const subcompanyOptions = [
    { label: "Mineral", value: "001" },
    { label: "Geotermal", value: "002" },
  ]
  const [startMonth, setStartMonth] = useState<any>(monthOptions[0].value)
  const [endMonth, setEndMonth] = useState<any>(monthOptions[11].value)
  const [subCompany, setSubCompany] = useState<any>(subcompanyOptions[0].value)
  const [startMonthOption, setStartMonthOption] = useState<any>(monthOptions)
  const [endMonthOption, setEndMonthOption] = useState<any>(monthOptions)
  const [yearOption, setYearOption] = useState<any>(yearOptionsFunc())
  const [doneImport, setDoneImport] = useState<any>(false)
  const [windowHeight, setWindowHeight] = useState<any>(window.innerHeight ? window.innerHeight * 0.6 : 800)
  // console.log(window.innerHeight ? window.innerHeight : 800);
  // console.log(yearOption);
  // console.log(year)
  // console.log(rkabRowData);

  const onChangeUploadCSV = (e: any) => {
    // console.log(e);
    setFile(e);
  };

  function get_header_row(sheet: any) {
    var headers = [];
    var range = XLSX.utils.decode_range(sheet["!ref"]);
    // console.log(range);
    // console.log(range.s.r);
    // console.log(range.s.c);
    // console.log(range.e.c);
    var C,
      R = 2; // range.s.r ; /* start in the first row */
    /* walk every column in the range */
    for (C = range.s.c; C <= range.e.c; ++C) {
      var cell =
        sheet[
          XLSX.utils.encode_cell({ c: C, r: R })
        ]; /* find the cell in the first row */
      // console.log(cell)
      var hdr = "UNKNOWN " + C; // <-- replace with your desired default
      if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);

      headers.push(hdr);
    }
    return headers;
  }

  const submitImport = () => {
    let fileInput: any = { ...file };
    // console.log(fileInput);
    const thisFile = fileInput.target.files[0];
    // console.log(thisFile);
    let reader = new FileReader();
    // console.log(reader);
    reader.onload = function (e: any) {
      const data = e.target.result;
      // console.log(data);
      const workbook = XLSX.read(e.target.result, {
        cellDates: true,
        dateNF: "DD/MM/YYYY",
      });
      // console.log(workbook);
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      // console.log(sheet);
      const jsa = XLSX.utils.sheet_to_json(sheet, { range: 2 });
      for (const a of jsa) {
        if (a["Month Paid"] instanceof Date) {
          const day = a["Month Paid"].getDate() < 10 ? '0' + a["Month Paid"].getDate() : a["Month Paid"].getDate();
          const month = (a["Month Paid"].getMonth() + 1) < 10 ? '0' + (a["Month Paid"].getMonth() + 1) : (a["Month Paid"].getMonth() + 1);
          a["Month Paid"] = day + '/' + month + '/' + a["Month Paid"].getFullYear();
        }
      }
      console.log(jsa)
      setRawExcelData(jsa);
      const head: any = get_header_row(sheet);
      // console.log(head);
      // console.log(jsa);

      // const newData = JSON.parse(JSON.stringify(rkabRowData))
      const newData = JSON.parse(JSON.stringify(initialRowData));

      for (const a of jsa) {
        for (const b of newData) {
          // console.log(a?.Remapping?.toLowerCase(), 'Filter')
          // console.log(b?.mapping?.toLowerCase(), 'Filter')
          // console.log(a?.Remapping?.toLowerCase() === b?.mapping?.toLowerCase(), 'Filter')
          // const mapping = a.Remapping
          //   ? a.Remapping.toLowerCase()
          //   : a.Mapping && a.Mapping.toLowerCase();
          let mapping: any = a.Remapping || a.Mapping || null;
          mapping = typeof mapping === "string" ? mapping.toLowerCase() : null;
          if (mapping && b.mapping && mapping === b.mapping) {
            // console.log("ini");
            // console.log(a);
            // console.log(b);

            const amount =
              typeof a["USD Amount"] === "number"
                ? a["USD Amount"]
                : parseFloat(replaceToDot(removeDot(a["USD Amount"])));
            // console.log(a["USD Amount"]);
            // console.log(amount);
            // console.log(typeof amount);
            b.actual += amount;
            b.remaining = b.budget - b.actual;

            if (b.mapping[0] === "a") {
              newData[35].actual += amount;
            }
            if (b.mapping[0] === "b") {
              newData[45].actual += amount;
            }
            newData[46].actual += amount;

            if (b.mapping && b.map_doc) {
              b.map_doc.push(a['Invoice Number'])
            } else {
              b.map_doc = [a['Invoice Number']]
            }

            // ====== Temporary if Needed for inserting to parent mapping ========
            //
            // let str = mapping.substring(1, mapping.length - 1);
            // if(mapping && isNaN(mapping.slice(-1))){
            //   str = mapping.substring(1, mapping.length - 1);
            //   // console.log(str);
            //   for (const c of newData){
            //     if (c.no == str){
            //       if(c.map_doc){
            //         let ketemu = false;
            //         for (const d of c.map_doc) {
            //           if(d.mapping === mapping){
            //             d.invoice_no.push(a['Invoice Number'])
            //             ketemu = true
            //             break;
            //           }
            //         }
            //         if(ketemu === false){
            //           c.map_doc.push({
            //             mapping: mapping,
            //             invoice_no: [a['Invoice Number']]
            //           })
            //         }
            //       } else {
            //         c.map_doc = [{ 
            //           mapping: mapping,
            //           invoice_no : [a['Invoice Number']]
            //         }]
            //       }
            //       console.log('ketemu')
            //       break;
            //     }
            //   }
            // }

            const addSubTotal = (key1: any, key2: any) => {
              b[key1] += amount;
              b[key2] += amount;
              if (b.mapping[0] === "a") {
                newData[35][key1] += amount;
                newData[35][key2] += amount;
              }
              if (b.mapping[0] === "b") {
                newData[45][key1] += amount;
                newData[45][key2] += amount;
              }
              newData[46][key1] += amount;
              newData[46][key2] += amount;
            };
            if (a["Year"] == year) {
              switch (a["Period"]) {
                case 1:
                  addSubTotal("jan", "q1");
                  break;
                case 2:
                  addSubTotal("feb", "q1");
                  break;
                case 3:
                  addSubTotal("mar", "q1");
                  break;
                case 4:
                  addSubTotal("apr", "q2");
                  break;
                case 5:
                  addSubTotal("may", "q2");
                  break;
                case 6:
                  addSubTotal("jun", "q2");
                  break;
                case 7:
                  addSubTotal("jul", "q3");
                  break;
                case 8:
                  addSubTotal("aug", "q3");
                  break;
                case 9:
                  addSubTotal("sep", "q3");
                  break;
                case 10:
                  addSubTotal("oct", "q4");
                  break;
                case 11:
                  addSubTotal("nov", "q4");
                  break;
                case 12:
                  addSubTotal("dec", "q4");
                  break;
  
                default:
                  break;
              }
            } else if (a["Year"] == year2) {
              switch (a["Period"]) {
                case 1:
                  addSubTotal("jan2", "q12");
                  break;
                case 2:
                  addSubTotal("feb2", "q12");
                  break;
                case 3:
                  addSubTotal("mar2", "q12");
                  break;
                case 4:
                  addSubTotal("apr2", "q22");
                  break;
                case 5:
                  addSubTotal("may2", "q22");
                  break;
                case 6:
                  addSubTotal("jun2", "q22");
                  break;
                case 7:
                  addSubTotal("jul2", "q32");
                  break;
                case 8:
                  addSubTotal("aug2", "q32");
                  break;
                case 9:
                  addSubTotal("sep2", "q32");
                  break;
                case 10:
                  addSubTotal("oct2", "q42");
                  break;
                case 11:
                  addSubTotal("nov2", "q42");
                  break;
                case 12:
                  addSubTotal("dec2", "q42");
                  break;
  
                default:
                  break;
              }
            }

            // console.log(b);
            break;
            // Object.assign(a, b);
          }
        }
      }

      newData[35].remaining = newData[35].budget - newData[35].actual;
      newData[45].remaining = newData[45].budget - newData[45].actual;
      newData[46].remaining = newData[46].budget - newData[46].actual;
      // console.log(newData);
      setRkabRowData(newData);
      setDoneImport(true)
    };
    reader.readAsArrayBuffer(thisFile);
  };

  const handleExport = async () => {
    setLoad(true);
    try {
      // console.log(rkabRowData, year, year2)
      const res = await fetch(API_URI + `dashboard/export-rkab`, {
        method: "POST",
        body:JSON.stringify({
          data: rkabRowData,
          year: year,
          year2: year2
        }),
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          Authorization:
            "Bearer " +
            JSON.parse(
              sessionStorage.getItem(
                "shreyu_user"
              ) as string
            ).access_token,
        },
      });
      const blob: any = await res.blob();
      // console.log(blob);
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = "RKAB Mineral.xlsx";
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.log(error);
      setLoad(false);
    }
    setLoad(false);
  }

  const renderVal: any = ({ cell, row }: any) => {
    let val: any = cell.getValue();
    // let val: any = row.original[cell.column.id];
    // if (cell.column.id === "budget" && row.original.mapping === "a1a") {
    //   console.log(val);
    //   console.log(cell.getValue())
    //   console.log(cell)
    //   console.log(row)
    // }
    const isNegative = val < 0;
    const areNan = isNaN(val);
    // console.log(val)
    // console.log(typeof val)
    // val = parseFloat(Math.abs(val).toFixed(2))
    // console.log(val)
    // val = replaceToComma(String(val))
    // console.log(val)
    // val = changeUStoID(val);
    // console.log(val)
    if (!areNan) {
      val = Number(Number(Math.abs(val)).toFixed(0));
      val = val?.toLocaleString("en-US");
    }
    // console.log(val)
    val = areNan ? "" : !isNegative ? "$" + val : `($${val})`;
    // console.log(val)
    return <>{val}</>;
  };

  const renderTotal: any = ({ cell, row }: any) => {
    let mapping = row.original.mapping;
    const a = rkabRowData.filter(a => a.mapping === mapping)[0];
    let val = a.jan + a.feb + a.mar + a.apr + a.may + a.jun + a.jul + a.aug + a.sep + a.oct + a.nov + a.dec;
    // console.log(val)
    // let val: any = row.original.actual;
    const isNegative = val < 0;
    const areNan = isNaN(val);
    if (!areNan) {
      val = Number(Number(Math.abs(val)).toFixed(0));
      val = val?.toLocaleString("en-US");
    }
    // val = Math.abs(val).toFixed(2);
    // val = isNaN(val) ? "" : !isNegative ? "$" + val : `($${val})`;
    val = areNan ? "" : !isNegative ? "$" + val : `($${val})`;

    return <>{val}</>;
  };
  const renderTotal2: any = ({ cell, row }: any) => {
    let mapping = row.original.mapping;
    const a = rkabRowData.filter(a => a.mapping === mapping)[0];
    let val = a.jan2 + a.feb2 + a.mar2 + a.apr2 + a.may2 + a.jun2 + a.jul2 + a.aug2 + a.sep2 + a.oct2 + a.nov2 + a.dec2;
    // console.log(val)
    // let val: any = row.original.actual;
    const isNegative = val < 0;
    const areNan = isNaN(val);
    if (!areNan) {
      val = Number(Number(Math.abs(val)).toFixed(0));
      val = val?.toLocaleString("en-US");
    }
    // val = Math.abs(val).toFixed(2);
    // val = isNaN(val) ? "" : !isNegative ? "$" + val : `($${val})`;
    val = areNan ? "" : !isNegative ? "$" + val : `($${val})`;

    return <>{val}</>;
  };
  
  const rkabColumnMRT = useMemo<MRT_ColumnDef<RkabColumn>[]>(
    () => [
      {
        accessorKey: "mapping",
        header: "Action",
        enableColumnOrdering: false,
        enableColumnFilter: false,
        enableEditing: false, //disable editing on this column
        size: 80,
        Cell: ({ row, column, cell }) => {
          return row?.original?.mapping && row?.original?.actual ?
          (<FeatherIcon icon="download" 
          onClick={ async() => {
            try{
              console.log("column id: ", column.id);
              if(column.id === "mapping") {
                setLoad(true)
                console.log("column: ", column);
                console.log("row: ", row.original?.map_doc);
                console.log("row.original: ", row.original);
                try{
                  const res = await axios({
                    method: 'post',
                    url: API_URI + `invoice-mergepdfs`,
                    data: {data: row?.original?.map_doc},
                    headers: {
                      Accept: "*/*",
                      // "Content-Type": "application/json",
                      "Access-Control-Allow-Origin": "*",
                      Authorization:
                        "Bearer " +
                        JSON.parse(sessionStorage.getItem("shreyu_user") as string)
                          .access_token,
                      "Content-Type": "application/json",
                      "Connection": "keep-alive"
                    },
                    responseType: 'blob',
                    timeout: 60 * 60 * 1000 // only wait for 2s
                  })
                  // const res = await fetch(API_URI + `invoice-mergepdfs`, {
                  //   method: "POST",
                  //   body:JSON.stringify({
                  //     data: row?.original?.map_doc
                  //   }),
                  //   // body:JSON.stringify({
                  //   //   "data": ["v1346bv3246", "INPLJK-2312-0193", "5205030710890002213", "787889", "EC24-STM-MAR-17"]
                  //   // }),
                  //   headers: {
                  //     Accept: "*/*",
                  //     "Access-Control-Allow-Origin": "*",
                  //     Authorization:
                  //       "Bearer " +
                  //       JSON.parse(
                  //         sessionStorage.getItem(
                  //           "shreyu_user"
                  //         ) as string
                  //       ).access_token,
                  //   },
                  // });
                  // console.log(res);
                  const blob: any = res.data;
                  const url = URL.createObjectURL(blob);
                  // const win: any = window.open();
                  const aDom = document.createElement("a");
                  aDom.setAttribute("style", "display:none");
                  aDom.setAttribute("href", url);
                  aDom.setAttribute("download", row?.original.mapping.toUpperCase() + " - " + row?.original?.description + ".zip");
                  document.body.appendChild(aDom);
                  aDom.click();
                  // console.log(aDom);
                  document.body.removeChild(aDom);
                  // win.close();
                }catch(error) {
                  console.log(error);
                  toast.error('Failed To Download', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                }
                setLoad(false)
              }
            }catch (error) {
              console.log(error);
              setLoad(false)
            }
          }}
          />):
          <div></div>
        }
      },
      {
        accessorKey: "no",
        header: "No",
        enableColumnOrdering: false,
        enableColumnFilter: false,
        enableEditing: false, //disable editing on this column
        size: 80,
      },
      {
        accessorKey: "description",
        header: "Description",
        enableColumnDragging: true,
        enableColumnOrdering: false,
        enableColumnFilter: false,
        enableEditing: false, //disable editing on this column
        size: 80,
      },
      {
        accessorKey: "budget",
        header: "Budget",
        // enableStickyHeader: true,
        enableColumnOrdering: false,
        enableColumnFilter: false,
        // enableResizing: true,
        enableEditing: (row: any) => {
          // console.log(row)
          return (
            row.original.description !== "SUB-TOTAL" &&
            row.original.description !== "TOTAL" &&
            (row.original.actual || row.original.actual === 0)
          );
        }, //disable editing on this column
        muiTableBodyCellEditTextFieldProps: ({ cell, row }) => ({
          onBlur: (e: any) => {
            // console.log(e.target.value);
            // console.log(cell);
            // console.log(row);
            // console.log(rkabRowData)
            // const newData: any = [...rkabRowData];
            // console.log(newData);
            // newData[row.index][cell.column.id] = e.target.value;
            // console.log(newData);
            // setRkabRowData(newData);
            setRkabRowData((prev: any) => {
              // console.log(prev);
              prev[row.index][cell.column.id] = parseFloat(e.target.value);
              prev[row.index].remaining =
                prev[row.index].budget - prev[row.index].actual;

              const subTotal = prev.reduce(
                (acc: any, curval: any, curidx: any, arr: any) => {
                  if (row.original.mapping[0] === arr[curidx].mapping?.[0]) {
                    // console.log(row.original.mapping[0])
                    // console.log(arr[curidx].mapping?.[0])
                    // console.log(acc)
                    // console.log(curval.budget)
                    return acc + curval.budget;
                  } else {
                    return acc;
                  }
                },
                0
              );
              // console.log(subTotal)
              if (row.original.mapping[0] === "a") {
                prev[35].budget = subTotal;
                prev[35].remaining = prev[35].budget - prev[35].actual;
              }
              if (row.original.mapping[0] === "b") {
                prev[45].budget = subTotal;
                prev[45].remaining = prev[45].budget - prev[45].actual;
              }
              prev[46].budget = prev[35].budget + prev[45].budget;
              prev[46].remaining = prev[46].budget - prev[46].actual;
              return [...prev];
            });
          },
        }),
        editVariant: "text",
        size: 80,
        // Footer () {
        //   return (<Stack>
        //   <Box>
        //     0
        //   </Box>
        // </Stack>)
        // },
        // Cell: renderVal,
        Cell: renderVal
      },
      {
        accessorKey: "actual",
        header: "Actual",
        enableColumnOrdering: false,
        enableColumnFilter: false,
        enableEditing: false, //disable editing on this column
        size: 80,
        // Footer () {
        //   return (<Stack>
        //   <Box>
        //     0
        //   </Box>
        // </Stack>)
        // },
        // Cell: renderVal,
        Cell: renderVal
      },
      {
        accessorKey: "remaining",
        header: "Remaining",
        enableColumnOrdering: false,
        enableColumnFilter: false,
        enableEditing: false, //disable editing on this column
        size: 80,
        // Footer () {
        //   return (<Stack>
        //   <Box>
        //     0
        //   </Box>
        // </Stack>)
        // },
        // Cell: ({ cell, row }: any) => {
        //   const budget = row.original.budget;
        //   const actual = row.original.actual;
        //   const remaining = budget - actual;
        //   const isNegative = remaining < 0;
        //   let val: any = parseFloat(Math.abs(remaining).toFixed(2));
        //   val = val?.toLocaleString("en-US");
        //   val = isNaN(remaining) ? "" : !isNegative ? "$" + val : `($${val})`;
        //   return <>{val}</>;
        // },
        Cell: renderVal,
      },
      {
        columns: [
          {
            accessorKey: "jan",
            // header: "Jan",
            header: year ? 'Jan-' + year.toString() : "Jan",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "feb",
            header: "Feb",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "mar",
            header: "Mar",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "apr",
            header: "Apr",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "may",
            header: "May",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "jun",
            header: "Jun",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "jul",
            header: "Jul",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "aug",
            header: "Aug",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "sep",
            header: "Sep",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "oct",
            header: "Oct",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "nov",
            header: "Nov",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "dec",
            header: "Dec",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "totalFromMonths",
            header: "Total",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderTotal,
          },
          {
            accessorKey: "q1",
            header: "Q1",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "q2",
            header: "Q2",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "q3",
            header: "Q3",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "q4",
            header: "Q4",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "totalFromQuarters",
            header: "Total",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderTotal,
          },
        ],
        header: year.toString(),
        id: 'year1',
      },
      {
        columns: [
          
          {
            accessorKey: "jan2",
            header: "Jan",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "feb2",
            header: "Feb",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "mar2",
            header: "Mar",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "apr2",
            header: "Apr",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "may2",
            header: "May",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "jun2",
            header: "Jun",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "jul2",
            header: "Jul",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "aug2",
            header: "Aug",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "sep2",
            header: "Sep",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "oct2",
            header: "Oct",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "nov2",
            header: "Nov",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "dec2",
            header: "Dec",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "totalFromMonths2",
            header: "Total",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderTotal2,
          },
          {
            accessorKey: "q12",
            header: "Q1",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "q22",
            header: "Q2",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "q32",
            header: "Q3",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "q42",
            header: "Q4",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderVal,
          },
          {
            accessorKey: "totalFromQuarters2",
            header: "Total",
            enableColumnOrdering: false,
            enableColumnFilter: false,
            enableEditing: false, //disable editing on this column
            size: 80,
            Cell: renderTotal2,
          },
        ],
        header: year2 === year ? '' : year2.toString(),
        id: 'year2',
      }
    ],
    [...rkabRowData]
  );

  return (
    <>
      <Loading loading={load} />
      <Row className="mt-3">
        <Col></Col>
        <Card>
          <Card.Body>
            <h4 className="header-title2 ">RKAB</h4>
            <MaterialReactTable
              columns={rkabColumnMRT}
              data={rkabRowData}
              state={{ pagination: { pageIndex: 0, pageSize: 100 } }}
              initialState={{
                density: "compact",
                columnPinning: { left: ["mapping","no", "description"] },
              }}
              enableStickyHeader={true}
              muiTableContainerProps={{ sx: { maxHeight: `${windowHeight}px` } }}
              enableFilters={false}
              enablePagination={false}
              enableEditing={true}
              editingMode="cell"
              muiTableBodyCellProps={({ row, cell }) => ({
                sx: {
                  backgroundColor: row?.original?.description ===  "Direct Cost, Consist of:" || row?.original?.description === "Indirect Cost:" ? '#FDDE55' : 'inherit',
                  color: row?.original?.description ===  "Direct Cost, Consist of:" || row?.original?.description === "Indirect Cost:" ? 'blue' : 'inherit',
                },
              })}
              // muiTableBodyRowProps={({ row }) => ({
                
              // }
            // )}
              renderTopToolbarCustomActions={() => (
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Select
                    isMulti={false}
                    onChange={(e: any) => {
                      // console.log(e.value);
                      setStartMonth(e.value);
                    }}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 50000 }) }}
                    options={startMonthOption}
                    defaultValue={startMonthOption[0]}
                  />
                  <Select
                    isMulti={false}
                    onChange={(e: any) => {
                      // console.log(e.value);
                      setYear(e.value);
                    }}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 50000 }) }}
                    options={yearOption}
                    defaultValue={yearOption.at(-1)}
                    // className={`${
                    //   // "react-select-disabled"
                    //   ""
                    // } react-select-container`}
                    // isDisabled={formData.contractNo.isDisabled}
                  />
                  <Select
                    isMulti={false}
                    onChange={(e: any) => {
                      // console.log(e.value);
                      setEndMonth(e.value);
                    }}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 50000 }) }}
                    options={endMonthOption}
                    defaultValue={endMonthOption.at(-1)}
                  />
                  <Select
                    isMulti={false}
                    onChange={(e: any) => {
                      // console.log(e.value);
                      setYear2(e.value);
                    }}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 50000 }) }}
                    options={yearOption}
                    defaultValue={yearOption.at(-1)}
                    // className={`${
                    //   // "react-select-disabled"
                    //   ""
                    // } react-select-container`}
                    // isDisabled={formData.contractNo.isDisabled}
                  />
                  <Select
                    isMulti={false}
                    onChange={(e: any) => {
                      // console.log(e.value);
                      setSubCompany(e.value);
                    }}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 50000 }) }}
                    options={subcompanyOptions}
                    defaultValue={subcompanyOptions[0]}
                  />
                  <Button
                    onClick={() => {
                      setLoad(true);
                      // setDataModalCreate({});
                      // setDataModalCreateMode("Create New")
                      // setCreateModal3Open(true)}
                      const fetchRkabReport = async () => {
                        // console.log("donload started");
                        try {
                          // let startM = startMonth;
                          // let endM = endMonth;
                          // if(startMonth > endMonth){
                          //   const tempMonth = startMonth;
                          //   setStartMonth(endMonth);
                          //   setEndMonth(tempMonth);
                          //   startM = endMonth;
                          //   endM = tempMonth;
                          // }
                          const valid = {
                            status: true,
                            msg: ""
                          };
                          // console.log(year, year2)
                          if(parseInt(year2) < parseInt(year)){
                            valid.status = false;
                            valid.msg = "End Year cannot be less than Start Year";
                          } else if(parseInt(year2) === parseInt(year)){
                            // console.log(startMonth, endMonth)
                            if(parseInt(endMonth) <= parseInt(startMonth)){
                              valid.status = false;
                              valid.msg = "End Month cannot be less or equal than Start Month"
                            }
                          }
                          if(valid.status) {
                            const res = await fetch(API_URI + `dashboard/rkab?year=${year}&year2=${year2}&subcompany=${subCompany}&start_month=${startMonth}&end_month=${endMonth}`, {
                              method: "GET",
                              // body:JSON.stringify({
                              // columnFilters,
                              // }),
                              headers: {
                                Accept: "*/*",
                                "Access-Control-Allow-Origin": "*",
                                Authorization:
                                  "Bearer " +
                                  JSON.parse(
                                    sessionStorage.getItem(
                                      "shreyu_user"
                                    ) as string
                                  ).access_token,
                              },
                            });
                            const blob: any = await res.blob();
                            // console.log(blob);
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement("a");
                            a.href = url;
                            a.download = "Bg Report.xlsx";
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                            setLoad(false);
                          } else {
                            toast.info(valid.msg, {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: true,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                            });
                            setLoad(false);
                          }
                        } catch (error: any) {
                          console.log(error);
                          console.log(error.message);
                          toast.error("Failed to Download", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          setLoad(false);
                        }
                      };
                      fetchRkabReport();
                    }}
                    className="vale-button"
                  >
                    Download Report
                  </Button>
                  <Button
                    onClick={() => {
                      setLoad(true);
                      setOpenImportBgReport(true);
                      setLoad(false);
                    }}
                    className="vale-button"
                  >
                    Import Data
                  </Button>
                  <Button
                    hidden={!doneImport}
                    onClick={() => handleExport()}
                    className="vale-button"
                  >
                    Export Data
                  </Button>
                  <Button
                    hidden={!doneImport}
                    onClick={() => {
                      // setDataModalCreate({});
                      // setDataModalCreateMode("Create New")
                      // setCreateModal3Open(true)}
                      setLoad(true);
                      const fetchRkabReport = async () => {
                        // console.log("donload started");
                        // console.log(rawExcelData);
                        for(const a of rawExcelData) {
                          if(a['Remapping']){
                            a['Mapping'] = a['Remapping']
                          }
                          if(a['Original Amount']){
                            a['Payment Amount'] = a['Original Amount']
                          }
                        }
                        try {
                          // const res = await fetch(API_URI + `dashboard/export-rkab-gov`, {
                          //   method: "POST",
                          //   body:JSON.stringify({
                          //     data: rawExcelData
                          //   }),
                          //   headers: {
                          //     Accept: "*/*",
                          //     "Access-Control-Allow-Origin": "*",
                          //     Authorization:
                          //       "Bearer " +
                          //       JSON.parse(
                          //         sessionStorage.getItem(
                          //           "shreyu_user"
                          //         ) as string
                          //       ).access_token,
                          //     "Content-Type": "application/json",
                          //   },
                          // });
                          const res = await axios({
                            method: 'post',
                            url: API_URI + `dashboard/export-rkab-gov`,
                            data: {data: rawExcelData},
                            headers: {
                              Accept: "*/*",
                              "Content-Type": "application/json",
                              "Access-Control-Allow-Origin": "*",
                              Authorization:
                                "Bearer " +
                                JSON.parse(sessionStorage.getItem("shreyu_user") as string)
                                  .access_token,
                            },
                            timeout: 9999 * 1000 // only wait for 2s
                          })
                          const body = await res?.data;
                          // console.log(body);
                          const b64toBlob = (b64Data: any, contentType = "", sliceSize = 512) => {
                            const byteCharacters = atob(b64Data);
                            const byteArrays = [];
                        
                            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                              const slice = byteCharacters.slice(offset, offset + sliceSize);
                        
                              const byteNumbers = new Array(slice.length);
                              for (let i = 0; i < slice.length; i++) {
                                byteNumbers[i] = slice.charCodeAt(i);
                              }
                        
                              const byteArray = new Uint8Array(byteNumbers);
                              byteArrays.push(byteArray);
                            }
                        
                            const blob = new Blob(byteArrays, { type: contentType });
                            return blob;
                          };
                          // const mediaType="data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
                          // const blob: any = await res.blob();
                          // console.log(blob);
                          const blob = b64toBlob(body.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
                          let url = URL.createObjectURL(blob);
                          let a = document.createElement("a");
                          document.body.appendChild(a);
                          a.href = url;
                          a.download = "Final RKAB Report.xlsx";
                          a.click();
                          document.body.removeChild(a);
                          // setLoad(false);
                        } catch (error) {
                          // 123
                          console.log(error);
                          toast.error('Failed To Download', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                        }
                        setLoad(false);
                      };
                      fetchRkabReport();
                    }}
                    className="vale-button"
                  >
                    Final Report
                  </Button>
                </Box>
              )}
            />
          </Card.Body>
        </Card>
      </Row>
      <ImportBgReport
        open={openImportBgReport}
        onClose={() => {
          setOpenImportBgReport(false);
        }}
        onSubmit={() => submitImport()}
        onFileUpload={(e: any) => onChangeUploadCSV(e)}
      />
    </>
  );
}

interface ImportBgReportProps {
  // columns: MRT_ColumnDef<EmployeeColumn>[];
  onClose: () => void;
  onSubmit: () => void;
  onFileUpload: (e: any) => void;
  open: boolean;
}

export const ImportBgReport = ({
  open,
  onClose,
  onSubmit,
  onFileUpload,
}: ImportBgReportProps) => {
  const handleSubmit = () => {
    //put your validation logic here
    onSubmit();
    onClose();
  };

  // const [stmOrVei, setStmOrVei] = useState("stm");
  // const templateFile = API_URI + 'purchase-order-export';
  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Import Report</DialogTitle>
      <DialogContent>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Attach File</Form.Label>
            <Form.Control
              type="file"
              id="upload_csv_file"
              onChange={(e) => onFileUpload(e)}
            />
            <Form.Text>Upload XSLX File (format .xslx)</Form.Text>
          </Form.Group>
        </Form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button className="vale-button" onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
