import { useCallback, useEffect, useMemo, useState } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { MaterialReactTable, MRT_Cell, MRT_ColumnDef, MRT_ColumnFiltersState, MRT_PaginationState, MRT_Row, MRT_SortingState } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from "@mui/material";
import { Delete, Edit, Check, Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import AddInvoiceSAP from "./AddInvoiceSAP";
import { useTranslation, Trans } from 'react-i18next';
import { toast } from "react-toastify";
import { API_URI } from "../../constants";
import { changeUStoID, checkColumnFilters, getCompanySession, getWorkflowRole, roleSpecialPayment, setApprovalAPI } from "../../helpers/utils";
import * as XLSX from 'xlsx/xlsx.mjs';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ConfirmationModal2 from "../../components/MyComponents/Modal/ConfirmationModal2";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Loading from "../../components/MyComponents/Loading";
import FeatherIcon from "feather-icons-react";
import { checkNotificationNumber } from "../../helpers/utils2";

type InvoiceColumn = {
  id: number;
  subcompany_code?: string;
  referenceNumber?: string;
  special_payment_number?: string;
  special_payment_date?: string;
  justification?: string;
  payment_amount?: string;
  department?:{
    id: number;
    name: string;
  }
  company_code?: string;
  currency_id?: string;
  vendor:{
    vendor_code: string;
    vendor_name: string;
  }
  approval_status?:{
    status_name: string;
    status_color: string;
    id: number;
  }
  user?:{
    id: number;
  }
  approval_status_id?: string;
  status: string;
  action: string;
  canEdit: boolean;
  canDelete?: boolean;
  canApprove: boolean;
  canReject?: boolean;
  first_approval?: string;
};
type ModalTypeState = "delete" | "approve" | "reject" | "";

const SpecialPaymentList = () => {
  const [isModalPaymentAdviceOpen, setIsModalPaymentAdviceOpen] = useState(false);
  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
  const [dataReject, setDataReject] = useState<any>({});
  const [modalType, setModalType] = useState<ModalTypeState>("");
  const [listEmployee, setListEmployee] = useState<any>([]);
  const [isError, setIsError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [load, setLoad] = useState(false);

  const [isExportMonth, setIsExportMonth] = useState(false);
  const [monthYear, setMonthYear] = useState("");
  const [start_date, set_start_date] = useState("");
  const [end_date, set_end_date] = useState("");

  //table state
  const [rowCount, setRowCount] = useState(0);

  // const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
  //   [],
  // );
  const colfil = JSON.parse(sessionStorage.getItem('special-payment') as string);
  const [columnFilters, setColumnFilters] = useState<any>(colfil ? colfil : []);
  // const [columnFilters, setColumnFilters] = useState<any>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  // const [sorting, setSorting] = useState<MRT_SortingState>([{
  //   id: 'id',
  //   desc: true
  // }]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const pagi = JSON.parse(sessionStorage.getItem('special-payment-pagination') as string)
  console.log(pagi)
  const [pagination, setPagination] = useState<MRT_PaginationState>(
    pagi?.pageSize ? pagi : {
    pageIndex: 0,
    pageSize: 100,
  });
  const [rowSelection, setRowSelection] = useState({});
  const [CSVData, setCSVData] = useState<ExcelColumn[]>([]);
  const [CSVHead, setCSVHead] = useState();
  // console.log(CSVData)
  // console.log(CSVHead)
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [file, setFile] = useState({});
  const [permission, setPermission] = useState<any>({});
  const [currentCompany, setCurrentCompany] = useState(JSON.parse(sessionStorage.getItem('current_company')as string));
  const [allDepartment, setAllDepartment] = useState<any>(false);
  const { t, i18n } = useTranslation();

  const columnMRT = useMemo<MRT_ColumnDef<InvoiceColumn>[]>(
    () => [
      {
        accessorKey: "special_payment_date",
        header: t("Posting Date"), //"Invoice No",
        size: 80,
        Cell: ({ row, cell }) => {
          return(<div> {cell.getValue()}</div>)
        }
      },
      {
        accessorKey: "referenceNumber",
        header: t("Doc No"), //"Invoice No",
        size: 80,
      },
      {
        accessorKey: "justification",
        header: t("Description"),
        size: 80,
      },
      {
        accessorKey: "currency_id",
        header: t("Currency"),
        size: 80,
      },
      {
        accessorKey: "payment_amount",
        header: t("Payment Amount"),
        size: 80,
        muiTableBodyCellProps: ({
          cell
        }) => ({
          sx: {
            textAlign: 'right'
          }
        }),
        Cell: ({ row, cell }) => {
          return(<div> {changeUStoID(cell.getValue())}</div>)
        }
      },
      {
        accessorKey: "vendor.vendor_name",
        header: t("Vendor Name"),
        size: 80,
      },
      {
        accessorKey: "subcompany_code",
        header: t("Mineral or Geothermal"),
        size: 80,
        filterSelectOptions:[{ text: "Mineral", value: "001" }, {text: "Geothermal", value: '002'}],
        filterVariant: 'select',
      },
      {
        accessorKey: "department.name",
        header: t("Department"),
        size: 80,
      },
      {
        accessorKey: "approval_status.status_name",
        header: "Status",
        size: 80,
        filterSelectOptions:[
          {text: "Waiting Review Approver FV60", value: 'Approver FV60'},
          { text: "Waiting Releaser FV60", value: "Releaser FV60" }, 
          // {text: "Waiting Review Admin AP", value: 'Admin AP'},
          // {text: "Waiting Review Finance Manager", value: 'Finance Manager'},
          { text: "Waiting Review Department Manager", value: "Department Manager" }, 
          { text: "Waiting Payment Advice", value: "Payment Advice" },
          { text: "Rejected", value: "Rejected" },
          { text: "Rejected > 30 days", value: "Rejected > 30 days" },
          { text: "Paid Complete", value: "Paid Complete" },
        ],
        filterVariant: 'select',
        Cell: ({ row, cell }) => {
          const value = String(cell.getValue());
          const isIncludeAppr = value.includes("eview");
          const isIncludeAppr2 = value.includes("nput");
          return (
            <div
              className={`status-default invoice-${
                isIncludeAppr || isIncludeAppr2
                  ? "waiting-approval"
                  : value.toLowerCase().split(" ").join("-")
              }`}
              style={{backgroundColor: row.original.approval_status?.status_color}}
            >
              <p>{value}</p>
            </div>
          );
        },
      },
    //   {
    //     header: "Action",
    //     accessor: "action",
    //     sort: true,
    //   }
    ],
    [t]
  );

  const [columnOrder, setColumnOrder] = useState<any>(
    ['mrt-row-actions', 'mrt-row-select', ...columnMRT.map((c) => c.accessorKey)],
  );
  const checkFilter = () => {
    // console.log("-------------CHECK FILTER--------------")
    let colFilter: any =[]
    let ketemu = false
    for(const a of columnFilters) {
      if (a.id === 'company') {
        ketemu = true;
      }
    }
    colFilter = checkColumnFiltersCompanyCode();
    if (ketemu) {
      // console.log("-------------CHECK FILTER KETEMU--------------")
      checkColumnFilters(colFilter, 'special-payment')
      checkColumnFilters(columnOrder, 'special-payment-column-order')
      checkColumnFilters(pagination, 'special-payment-pagination')
      // console.log("pagination: ", pagination)
    } else {
      // console.log("-------------CHECK FILTER ELSE--------------")
      const a = JSON.parse(sessionStorage.getItem('special-payment') as string)
      const a2 = JSON.parse(sessionStorage.getItem('special-payment-column-order') as string)
      const a3 = JSON.parse(sessionStorage.getItem('special-payment-pagination') as string)
      // console.log("a3:", a3)
      if(a3){
        setPagination(a3)
      } else {
        // console.log("-------------CHECK FILTER ELSE A3--------------")
        setPagination({ pageIndex: 0, pageSize: 100})
      }
      if(a2){
        setColumnOrder(a2)
      }
      if(a){
        for(const b of a) {
          if (b.id === 'company') {
            b.value = currentCompany.company_initial
          }
        }
        setColumnFilters(a)
      } else {
        // console.log('Tidak ada data session storage')
        setColumnFilters([{id: 'company', value: currentCompany.company_initial}])
      }
    }
  }

  // checkFilter();

  const submitImport = () => {
    let fileInput:any = {...file};
    const thisFile = fileInput.target.files[0];
    let reader = new FileReader();
    reader.onload = function(e: any) {
      const data = e.target.result;
      const workbook = XLSX.read(data, {cellDates: true, dateNF:"DD/MM/YYYY"});
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsa = XLSX.utils.sheet_to_json(sheet);
      const head:any = [];
      Object.keys(jsa[0]).forEach(function(key) {
        head.push(key);
      });
      for(const a of jsa) {
        dayjs.extend(customParseFormat)
        // console.log(a);
        // console.log(a.paidDate);
        a.paidDate = a.paidDate ? dayjs(a.paidDate).add(2,'hour').format('DD MMM YYYY')  : null
      }
      setCSVData(jsa);
      setCSVHead(head);
      setCreateModalOpen(true);
    };
    reader.readAsArrayBuffer(thisFile);
  }

  const checkValid = (head: any, jsa: any) => {
    let valid = true;
    for(let a in jsa) {
      for (const b of head) {
        let ketemu = false;
        for (let [key, value] of Object.entries(jsa[a])) {
            if(b === key) {
              if(value !== ''){
                ketemu = true;
              }
            }
        }
        if(!ketemu){
          // console.log(`${b} is required!`)
          // jsa[a][b] = `${b} is required!`
          valid = false;
        }
      }
    }
    setIsValid(valid);
    return valid;
  }

  const sendEditSpecialPayment = async (data2: any) => {
    // const response = await axios.get(API_URI + 'department/');
    // console.log(data2);
    fetch(API_URI + 'special-payment-update', {
      method: "POST",
      body:JSON.stringify(data2),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
    .then(response => response.json())
    .then(data => {
      if(data.success) {
        // console.log(data);
        toast.success('Sucess Save Payment Advice')
        navigate(`/sap/special-payment-list`)
      } else {
        toast.error('Failed To save Payment Advice')
      }
    })
    .catch(err => {
      // console.log(err)
      toast.error('Failed To save Payment Advice', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
    // // console.log(response.json());
    // setDataApi(response);
  };

  const handleAddUpdateApi = ((dataCreate: any) => {
    for(const a of dataCreate) {
      // console.log(a);
      a.special_payment_number = a.docNo;
      delete a.docNo;
      a.paid_date = dayjs(a.paidDate).format("YYYY-MM-DD");
      delete a.paidDate;
      a.user_id = JSON.parse(sessionStorage.getItem('shreyu_user') as string).data.id
    }
    sendEditSpecialPayment(dataCreate)
  })

  const onChangeUploadCSV = (e: any) => {
    // console.log(e)
    setFile(e);
  }
  type ExcelColumn = {
    docNo: string,
    paidDate: Date
  }
  const handleSaveCell = (cell: MRT_Cell<ExcelColumn>, value: any) => {
    //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here
    // console.log(value);
    CSVData[cell.row.index][cell.column.id as keyof ExcelColumn] = value;
    //send/receive api updates here
    setCSVData([...CSVData]); //re-render with new data
    setIsValid(checkValid(CSVHead, CSVData));
  };

  // const role = roleSpecialPayment();
  const role = getWorkflowRole('3');
  const session_storage = JSON.parse(sessionStorage?.getItem("shreyu_user")as string)
  const permissions = session_storage?.data?.permissions;
  
  const checkColumnFiltersCompanyCode = () => {
    if(columnFilters.length <= 1){
       return [
        {id: 'company', value: currentCompany.company_initial},
        // {id: 'employee_id',value: role.employee.payroll_id}
      ];
    } else {
      for (const a of columnFilters) {
        if(a.id === 'company') { 
          a.value = currentCompany.company_initial
        }
      }
      return columnFilters
    }
  }

  const getData = async () => {
    setLoad(true);
    let colFilter: any = [];
    if(permissions) {
      // console.log('permissions:', permissions);
      for (const a of permissions){
        if (a.modules_id === '3'){
          // console.log('permission:' ,a);
          setPermission(a)
        }
      }
    }
    colFilter = checkColumnFiltersCompanyCode();
    fetch(API_URI + 'special-payment-list', {
      method: "POST",
      body:JSON.stringify({
        columnFilters: colFilter,
        sorting,
        pagination,
        all_department: allDepartment
      }),
      headers:{
        "Authorization": 'Bearer ' + JSON.parse(sessionStorage.getItem('shreyu_user')as string).access_token,
        "Content-Type":"application/json",
        "Access-Control-Allow-Origin": "*"
      },
    })
    .then(response => response.json())
    .then(data => {
      // console.log(data);
      if(data && data.success){
        if(data.data && data.data.data) {
          // console.log(data.data.data)
          const a = [];
          for(const b of data.data.data) {
            let ketemu = false;
            if(role?.role){
              console.log(role?.role);
              for(const aa of role?.role){
                if(b.approval_status?.status_name?.includes(aa)){
                  ketemu = true;
                }
              }
              b.canEdit = (
                ((b.approval_status?.status_name === 'Rejected' ||
                // (b.approval_status?.id === 21 ||
                // (b.approval_status?.id === 22 && role?.role?.includes('SAP Admin'))) || 
                role?.role?.includes('Admin AP')))) ? true : false
              b.canDelete = (
                b.approval_status?.status_name === 'Rejected' && 
                (b.approval_status?.id === 21 ) && 
                role?.canDelete) ? true : false
              // if(b.approval_status_id == 15){
              //   // console.log(role?.directManager, role?.employee?.payroll_name)
              //   if(role?.employee?.payroll_name?.toLowerCase() === role?.directManager?.toLowerCase() && 
              //     b.approval_status_id == 15 && (role?.employee.payroll_name === b.direct_manager || role?.employee.payroll_name === b.assign_direct_manager)){
              //     b.canApprove = true
              //     b.canReject = true
              //     b.canEdit = false
              //     b.canDelete = false
              //   } else {
              //     b.canApprove = false
              //     b.canReject = false
              //     b.canEdit = false
              //     b.canDelete = false
              //   }
              // }else {
                // console.log('masuk else')
                if(b.approval_status?.status_name?.includes('President') && role?.role?.includes('President Director')){
                  b.canApprove = true;
                  b.canReject = true;
                } else if(b.approval_status?.status_name?.includes('Payment Advice') && role?.role?.includes('Treasury')){
                  b.canApprove = false;
                  b.canReject = true;
                } else if(b.approval_status?.status_name?.includes('Approver FV60') && role?.role?.includes('Direct Manager')){
                  b.canApprove = true;
                  b.canReject = true;
                } else if(b.approval_status?.status_name?.includes('Department Manager') && role?.role?.includes('Department Manager')){
                  b.canApprove = true;
                  b.canReject = true;
                } else {
                  // if(b.approval_status?.status_name?.includes('Admin AP') && (b.ias?.status == 0 || !b.ias?.status)) {
                  //   b.canApprove = false;
                  //   b.canReject = false;
                  // }else{
                    // console.log('masuk else waiting')
                    b.canApprove = (b.approval_status?.status_name?.includes('Waiting') && ketemu && role?.canApprove) ? true : false
                    b.canReject = (b.approval_status?.status_name?.includes('Waiting') && ketemu && role?.canReject) ? true : false
                  // }
                }
                
              // }
            }
            b.subcompany_code = b.subcompany_code === '001' ? 'Mineral' : 'Geothermal'
            a.push(b)
          }
          checkNotificationNumber(document)
          setListEmployee([...data.data.data]);
          // console.log(data.data.data);
          setRowCount(data.data.total)
          // console.log(data.data.data)
          setIsError(false);
          setLoad(false)
        }else{
          setListEmployee([]);
          setRowCount(0)
          // // console.log(a)
          setIsError(false);
          setLoad(false)
        }
      }else{
        // console.log(data.message);
        setIsError(true);
        toast.error('Failed getting data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoad(false)
      }
    })
    .catch(err => {
      // console.log(err)
      setIsError(true);
      toast.error('Failed getting data', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoad(false)
    })
    // // console.log(response.json());
    // setDataApi(response);
  };

  const handleDeleteRow = useCallback(
    (row: MRT_Row<InvoiceColumn>) => {
      if (
        !window.confirm(
          `Are you sure you want to delete ${row.getValue("special_payment_number")}`
        )
      ) {
        return;
      }
      fetch(API_URI + `special-payment/${row.original.id}`, {
        method: "POST",
        body: JSON.stringify({
          "_method": "DELETE"
        }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        toast.success('Success Removing data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        getData()
      })
      .catch(err => {
        // console.log(err)
        setIsError(true);
        toast.error('Failed Removing data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
    },
    [listEmployee]
  );
  const navigate = useNavigate();

  useEffect( () => {
    checkFilter();
    // console.log('columnFilters', columnFilters)
    // console.log('sorting', sorting)
    // console.log('globalFilter', globalFilter)
    // console.log('pagination', pagination)
    // console.log(API_URI + 'department')
    getData();
    // console.log(role);
    // console.log(getCompanySession)
    // getDataDepartement();
    // axios.get(API_URI + 'department/').then((response)=> {
    //   // console.log(response);
    // })
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    columnOrder,
    allDepartment
  ]);
  
  return (
    <>
      <div className="mb-3"></div>
      <Loading loading={load} />
      <Row className="mt-3">
        {isExportMonth ? (
          <Col md={4} className="mb-2">
            <Form.Group>
              <Form.Label>{t("Choose Month")}</Form.Label>
              <Form.Control
                type="month"
                onChange={(e) => {
                  setMonthYear(e.target.value);
                }}
                max={new Date().toISOString().split("T")[0].substring(0,7)}
                value={monthYear}
              />
            </Form.Group>
          </Col>
        ) : (
          <>
            <Col md={2} className="mb-2">
              <Form.Group>
                <Form.Label>{t("From Date")}</Form.Label>
                <Form.Control
                  type="date"
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    set_start_date(e.target.value);
                  }}
                  value={start_date}
                />
              </Form.Group>
            </Col>
            <Col md={2} className="mb-2">
              <Form.Group>
                <Form.Label>{t("To Date")}</Form.Label>
                <Form.Control
                  type="date"
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    set_end_date(e.target.value);
                  }}
                  value={end_date}
                />
              </Form.Group>
            </Col>
          </>
        )}
        <Col md={2} className="mb-2 d-flex align-items-end">
          <Button
            style={{ fontWeight: "bold" }}
            className="w-100 rounded-pill"
            variant="secondary"
            onClick={() => setIsExportMonth(!isExportMonth)}
          >
            {t("Choose")} {isExportMonth ? t("Date Range") : t("Month")}
          </Button>
        </Col>
        <Col md={2} className="mb-2 d-flex align-items-end">
          <Button
            className="w-100 vale-button d-flex align-items-center justify-content-center"
            onClick={async () => {
              // // console.log(start_date)
              // // console.log(end_date)
              // // console.log(monthYear)
              // // console.log(my)
              let params = "";
              let company_code = JSON.parse(sessionStorage.getItem('current_company')as string).company_code
              let my = '';
              if (isExportMonth) {
                const moye = new Date(monthYear);
                my = `${moye.toLocaleString("default", {
                  month: "long",
                })} ${moye.getFullYear()}`;
                params = `?company_code=${company_code}&monthYear=${my}`;
              } else {
                if (!start_date || !end_date) {
                  return toast.warn("Please select date range");
                }
                const oneDay = 24 * 60 * 60 * 1000;
                let firstDate: any = start_date
                let secondDate: any = end_date
                // const diffDays =
                //   Math.round((secondDate - firstDate) / oneDay) + 1;
                // console.log(diffDays);
                // if (diffDays > 31) {
                //   return toast.warn("Date range must be less than 31 days");
                // }
                // if (!diffDays || diffDays < 1) {
                //   return toast.warn(
                //     "Date range must be greater than 0 days"
                //   );
                // }
                if(new Date(end_date) < new Date(start_date)){
                  const tempMonth = end_date;
                  firstDate = tempMonth
                  secondDate = start_date
                  set_end_date(start_date);
                  set_start_date(tempMonth);
                }
                params = `?company_code=${company_code}&start_date=${firstDate}&end_date=${secondDate}`;
              }
              if (allDepartment) params += "&dept=all";
              // return;
              const res = await fetch(
                API_URI + `special-payment-export-data${params}`,
                {
                  headers: {
                    Accept: "*/*",
                    // "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization:
                      "Bearer " +
                      JSON.parse(
                        sessionStorage.getItem("shreyu_user") as string
                      ).access_token,
                  },
                }
              );
              const blob: any = await res.blob();
              // const header: any = res.headers.get("Content-Disposition");
              // // console.log(res);
              // // console.log(header);
              // let fileName = header.split('=')[1];
              // fileName = fileName.replace('"','').replace('"','')
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement("a");
              a.href = url;
              if(isExportMonth){
                my = my.replace(' ', '_');
                a.download = `Special_Payment_${my}.xlsx`;
              } else {
                a.download = `Special_Payment_${start_date}_${end_date}.xlsx`;
              }
              // a.download = fileName;
              a.click();
            }}
          >
            <FeatherIcon icon="download" />{" "}
            <span className="ms-2">{t("Export Special Payment")}</span>
          </Button>
        </Col>{" "}
        {permission?.is_general === "1" && (
          <Col className="d-flex align-items-center justify-content-start pt-2">
            <div className="d-flex align-items-center justify-content-start">
              <Form.Check
                type="switch"
                label={t("All")}
                checked={allDepartment}
                className="custom-switch"
                onClick={() => setAllDepartment(!allDepartment)}
              />
            </div>
          </Col>
        )}
      </Row>
      <Row className="mt-3">
        <Col>
          <Card>
          <div className="card-header bg-vale">List Special Payment</div>
            <MaterialReactTable
              columns={columnMRT}
              enableSelectAll={role?.canMultiple}
              enableMultiRowSelection={role?.canMultiple}
              enableRowSelection={(row) => {
                return row.original.canApprove
              }}
              enableColumnDragging={true}
              enableColumnOrdering={true}
              // enableColumnResizing={true}
              // layoutMode={'grid'}
              // defaultColumn={{
              //   maxSize: 400,
              //   minSize: 40,
              //   size: 200, //default size is usually 180
              // }}
              enableStickyHeader={true}
              // enableStickyFooter={true}
              manualFiltering={true}
              manualPagination={true}
              manualSorting={true}
              onColumnFiltersChange={setColumnFilters}
              onGlobalFilterChange={setGlobalFilter}
              onPaginationChange={(prev) => {
                console.log(pagination)
                setPagination(prev)
              }}
              onSortingChange={setSorting}
              enableEditing
              enablePinning
              // enableRowNumbers
              rowCount={rowCount}
              onRowSelectionChange={setRowSelection}
              autoResetPageIndex={false}
              autoResetAll={false}
              paginateExpandedRows={false}
              // autoResetAll={false}
              state={{
                rowSelection,
                columnFilters,
                globalFilter,
                // isLoading,
                pagination,
                showAlertBanner: isError,
                // showProgressBars: isRefetching,
                sorting,
                columnOrder,
              }}
              onColumnOrderChange={setColumnOrder}
            
              initialState={{ 
                density: 'compact',
                showColumnFilters: true,
                columnPinning: { 
                  left: ["mrt-row-select", "mrt-row-actions"],
                  right: ["approval_status.status_name"] 
                },
                columnVisibility: { 
                  subcompany_code: currentCompany.company_initial === 'STM' ? true : false,
                  id: false
                },
                // pagination: JSON.parse(sessionStorage.getItem('special-payment-pagination') as string).pageIndex
                //   ? JSON.parse(sessionStorage.getItem('special-payment-pagination') as string)
                //   : { pageSize: 10, pageIndex: 0 },
              }}
              data={listEmployee}
              muiTableBodyCellProps={({ cell, column, row }) => ({
                onClick: (event) => {
                  // console.log(cell.column.id);
                  // console.log('Cell: ', cell);
                  // console.log("column: ", column.id);
                  // console.log("row: ", Object.keys(row.original));
                  // console.log('table : ', table );
                  if (Object.keys(row.original).includes(column.id)) {
                    navigate(`/sap/special-payment-view/${row.original.id}`, {
                      state: row.original,
                    });
                    // console.log(cell.getValue())
                    // console.log(row.original);
                  }
                },
                sx: {
                  cursor: Object.keys(row.original).includes(column.id)
                    ? "pointer"
                    : "", //you might want to change the cursor too when adding an onClick
                },
              })}
              renderTopToolbarCustomActions={() => (
                <Box sx={{ display: "flex", gap: "1rem", width:"20%" }}>
                {(permission?.is_create === '1') && (
                  <Button
                    onClick={() => navigate("/sap/special-payment/create/1")}
                    className="vale-button"
                  >
                    {t(`Create New`)}
                  </Button>
                )}
                {(role?.role?.includes('Treasury')) && (
                  <Button
                    // onClick={() => navigate("/sap/special-payment/create")}
                    onClick={() => setIsModalPaymentAdviceOpen(true)}
                    className="vale-button"
                  >
                    {t(`Import Paid Date`)}
                  </Button>
                )}
                </Box>
              )}
              renderRowActions={({ row, table }) => (
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  {/* {((permission.is_update === '1' && 
                  ((row?.original?.approval_status?.id === 21 && !role?.role.includes('Admin AP')) ||
                  (row?.original?.approval_status?.id === 22 && role?.role?.includes('Admin AP')))&&
                  row?.original?.approval_status?.status_name === 'Rejected') || 
                  (role?.role?.includes('SAP Admin') && row.original?.approval_status?.id === 34) ||
                  (role?.role?.includes('Admin AP') && row.original?.approval_status?.id === 16) */}
                  {(
                    (
                      (permission.is_update === '1' || permission.is_create === '1') &&
                      (
                        // (
                        //   row?.original?.approval_status?.id === 21 && !role?.role.includes('Admin AP')
                        // ) ||
                        // (
                        //   row?.original?.approval_status?.id === 22 && role?.role?.includes('Admin AP')
                        // )
                      // ) &&
                      (row?.original?.approval_status?.status_name === 'Rejected' && row?.original?.user?.id === role?.id) ||
                      (row?.original?.approval_status?.status_name?.includes('Department Manager') && row?.original?.user?.id === role?.id) ||
                      (row?.original?.approval_status?.status_name?.includes('Approver FV60') && row?.original?.first_approval === '1' && row?.original?.user?.id === role?.id)
                    )
                    // (
                    //   role?.role?.includes('Admin AP') && 
                    //   row.original?.approval_status?.id === 16
                    // )
                  // (row?.original?.approval_status?.status_name === 'Rejected' && role?.role?.includes('SAP Admin'))
                  )) &&
                  (
                    <Tooltip arrow placement="top" title={t("Edit")}>
                      <IconButton
                        onClick={() => navigate(`/sap/special-payment/edit/${row.original.id}`)}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  )}
                  {/* {row.original.canDelete && ( */}
                  {(((permission.is_update === '1' || permission.is_create === '1') &&
                  // ((row?.original?.approval_status?.id === 21 && !role?.role.includes('Admin AP'))// ||
                  // (row?.original?.approval_status?.id === 22 && role?.role?.includes('Admin AP')))&&
                  // ) &&
                  (row?.original?.approval_status?.status_name === 'Rejected' && row?.original?.user?.id === role?.id) ||
                  (row?.original?.approval_status?.status_name?.includes('Department Manager') && row?.original?.user?.id === role?.id) ||
                  (row?.original?.approval_status?.status_name?.includes('Approver FV60') && row?.original?.first_approval === '1' && row?.original?.user?.id === role?.id)
                )
                  // (role?.role?.includes('Admin AP') && row.original?.approval_status?.id === 16)
                  // (row?.original?.approval_status?.status_name === 'Rejected' && role?.role?.includes('SAP Admin'))
                  ) &&
                  // ((row?.original?.approval_status?.id === 21 && !role?.role.includes('Admin AP')) ||
                  // (row?.original?.approval_status?.id === 22 && role?.role?.includes('Admin AP')))&&
                  // row?.original?.approval_status?.status_name === 'Rejected')) &&
                  (
                    <Tooltip arrow placement="top" title={t("Delete")}>
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteRow(row)}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  )}
                  {(row.original.canApprove && !row.original?.approval_status?.status_name?.includes('Payment Advice')) && (
                    <Tooltip arrow placement="top" title={t("Approve")}>
                      <IconButton disabled={load} onClick={() => {
                        setLoad(true);
                        // console.log(row.original)
                        // console.log("rowSelection",rowSelection)
                        const a = []
                        const d = []
                        const f = []
                        if(rowSelection){
                          for (const [key, value] of Object.entries(rowSelection)) {
                            a.push(listEmployee[key].id)
                            d.push(listEmployee[key].vendor_code)
                            f.push(listEmployee[key].approval_status_id)
                          }
                        }
                        // console.log(a);
                        let id, approval_status_id, approved, reason, vendor_code
                        if(a.length >= 1) {
                          id = a
                          vendor_code = d
                          approval_status_id = f
                        } else {
                          id = [row.original.id]
                          vendor_code = [row.original.vendor.vendor_code]
                          approval_status_id = [row.original.approval_status_id]
                        }
                        setApprovalAPI([{
                          id: id,
                          approval_status_id,
                          approved: true,
                          reason: null,
                          approved_by: JSON.parse(sessionStorage?.getItem("shreyu_user")as string)?.data?.id,
                          vendor_code: vendor_code
                        }]).then(() => {
                          setRowSelection([])
                          toast.success('Success: Approved', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          getData();
                          setLoad(false);
                          // navigate(`/sap/special-payment-list`);
                        }).catch(() => {
                          setRowSelection([])
                          toast.error('Failed to Approve', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          setLoad(false);
                        })
                      }}
                      >
                        <Check />
                      </IconButton>
                    </Tooltip>
                  )}
                  {(row.original.canReject && !row.original?.approval_status?.status_name?.includes('Payment Advice')) && (
                    <Tooltip arrow placement="top" title={t("Reject")}>
                      <IconButton
                        color="error"
                        onClick={() => {
                          // console.log(row.original)
                          setModalType("reject");
                          setIsModalConfirmationOpen(true);
                          setDataReject(row.original);
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Tooltip>
                  )}
                  {/* {role?.iasEdit && (
                    <Tooltip arrow placement="top" title="IAS edit">
                      <IconButton onClick={() => navigate(`/sap/special-payment-ias/edit/${row.original.id}`)}>
                        <NoteAddIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {role?.iasRead && (
                    <Tooltip arrow placement="top" title="IAS view">
                      <IconButton onClick={() => navigate(`/sap/special-payment-ias/view/${row.original.id}`)}>
                        <NoteAddIcon />
                      </IconButton>
                    </Tooltip>
                  )} */}
                </Box>
              )}
            />
          </Card>
        </Col>
      </Row>
      <InputPaymentAdviceModal
        open={isModalPaymentAdviceOpen}
        onClose={() => setIsModalPaymentAdviceOpen(false)}
        onSubmit={() => submitImport()}
        onFileUpload={(e: any) => onChangeUploadCSV(e)}
      />
      <ValidateExcelModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={(data) => handleAddUpdateApi(data)}
        CSV={CSVData!}
        CSVHead={CSVHead!}
        onCellEdit={(cell, value) => handleSaveCell(cell, value)}
      />
      <ConfirmationModal2
        open={isModalConfirmationOpen}
        setOpen={setIsModalConfirmationOpen}
        modalType={modalType}
        bastStatus={dataReject}
        getData={getData}
      />
    </>
  );
};

const InputPaymentAdviceModal = ({
  open,
  onClose,
  onSubmit,
  setItem,
  item,
  onFileUpload
}: any) => {
  const handleSubmit = () => {
    //put your validation logic here
    onSubmit();
    onClose();
  };
  const templateFile = API_URI  + 'special-payment-export ';
  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Import Payment Date</DialogTitle>
      <DialogContent>
        <Row>
            <Col>
              <a href={templateFile} target="_blank" rel="noopener noreferrer" download>
                <Button>
                    Download Template
                </Button>
              </a>
            </Col>
        </Row>
        <br></br>
        <Form>
          {/* <Form.Group className="mb-3" controlId="paymentAdvice">
            <Form.Label>Invoice No.</Form.Label>
            <Form.Control
              type="text"
              placeholder="Input File"
              defaultValue={"SMI-23080071"}
              disabled
              // disabled={props.data.paymentAdvice.isDisabled}
            />
          </Form.Group> */}
          <Form.Group className="mb-3" controlId="paymentAdviceDateFile">
            <Form.Label>Upload Paid Date File</Form.Label>
            <Form.Control
              type="file"
              placeholder="Input File"
              onChange={(e) => onFileUpload(e)}
              // disabled={props.data.paymentAdvice.isDisabled}
            />
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="paidDate">
            <Form.Label>Paid Date</Form.Label>
            <ShreyuDatepicker
              hideAddon={true}
              dateFormat="dd MMM yyyy"
              value={new Date()}
              onChange={(date) => {
                // onDateChange(date);
              }}
            />
          </Form.Group> */}
        </Form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button className="vale-button" onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type ExcelColumn = {
  docNo: string,
  paidDate: Date
}

interface CreateModalProps {
  // columns: MRT_ColumnDef<EmployeeColumn>[];
  onClose: () => void;
  onSubmit: (data: any[]) => void;
  onCellEdit: (cell: any, value: any) => void;
  // onFileUpload: (e: any) => void;
  CSV: ExcelColumn[];
  CSVHead: [];
  open: boolean;
}

const ValidateExcelModal = ({
  open,
  onClose,
  onSubmit,
  onCellEdit,
  // onFileUpload,
  CSV,
  CSVHead
}: CreateModalProps) => {
  const [currentCompany, setCurrentCompany] = useState(JSON.parse(sessionStorage.getItem('current_company')as string));
  const handleSubmit = (data: any[]) => {
    //put your validation logic here
    onSubmit(data);
    onClose();
  };
  const checkValidation = ((cell:any) => {
    const val = cell.getValue();
    if(val && val !== ''){
      return <div>{cell.getValue() as string}</div>
    } else {
      // return <div style={{backgroundColor: 'red'}}>{cell.getValue() as string}<br/>{cell.column.id} is required!</div>;
      return <div style={{border: '1px solid red', color:'red'}}>{cell.getValue() as string}<br/>{cell.column.columnDef.header} is required!</div>;
    }
  })
  const [stmOrVei, setStmOrVei] = useState("stm");
  const columnMRTImport = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "docNo",
        header: "Document Number",
        enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: false,
        size: 80,
        Cell: ({ cell }) => checkValidation(cell)
      },
      {
        accessorKey: "paidDate",
        header: "Paid Date",
        type: 'date',
        enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: false,
        size: 80,
        Cell: ({ cell }) => checkValidation(cell)
      },
    ],
    []
  );
  return (
    <Dialog open={open} fullScreen={true}>
      <DialogTitle textAlign="center">Validate Data</DialogTitle>
      <DialogContent>
      <MaterialReactTable
        columns={columnMRTImport}
        data={CSV}
        enableEditing
        enableRowNumbers
        editingMode="cell"
        muiTableBodyCellEditTextFieldProps={({ cell }) => ({
          onBlur: (event) => {
            onCellEdit(cell, event.target.value);
          },
        })}
        renderBottomToolbarCustomActions={() => (
          <Typography sx={{ fontStyle: 'italic', p: '0 1rem' }} variant="body2">
            Double-Click a Cell to Edit
          </Typography>
        )}
        initialState={{ columnVisibility: { 
          subcompany_code: currentCompany.company_initial === 'STM' ? true : false,
          // cost_center_id: currentCompany.company_initial === 'STM' ? false : true,
          // cost_element_id: currentCompany.company_initial === 'VEI' ? false : true
        } }}
      />
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button 
          className="vale-button" 
          onClick={() => {
            handleSubmit(CSV)
          }}
          // disabled={!isValid}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SpecialPaymentList;
