import { useEffect, useState } from "react";
import Kpi from "./Kpi";
import {
  getDataKpiChart,
  getDataKpiTable,
  getDataKpiTableDownload,
  getDataMonthUsers,
} from "./getData";
import { useKpiState } from "./hooks";
import { bastChartLegend } from "./data";

const BastApprovalLeadTime = () => {
  /* 
    ========================================================================
    KPI BAST Approval Lead Time
  
    ** PAYLOAD **
    payload: {
      kpi: 5,
      year: 2024,
      company_code: 1492,
      department_id: null,
      approval1: null,
      approval2: 4,
    },

    ** RESPONSE DATA **
    data: [
      // ... MONTH 1 - 12
      {
        month_no: "1",
        month_name: "Jan-2024",
        year_no: "2024",
        company_code: "1492",
        total_bast: "13",
        total_vendor: "4",
        lead_time: "4",
      },
    ],
  */
  const hooksData = useKpiState({approval1: null, approval2: 4, isInvoice: false});
  // const [chartPayload, setChartPayload] = useState<any>({
  //   kpi: 7,
  //   year: new Date().getFullYear(),
  //   company_code: parseInt(
  //     JSON.parse(sessionStorage.getItem("current_company") as string)
  //       .company_code
  //   ),
  //   department_id: null,
  //   approval1: null,
  //   approval2: 4,
  //   excel: false,
  // });
  // const [chartData, setChartData] = useState<any>({});
  // const [tableData, setTableData] = useState<any>([]);
  // const [kpiUsersData, setKpiUsersData] = useState<any>([]);

  const chartTitle = "BAST Approval Lead Time";
  const chartLegend = bastChartLegend


  // useEffect(() => {
  //   // window.scrollTo(0, 0);
  //   getDataKpiChart({ body: chartPayload, isInvoice: false })
  //     .then((data) => setChartData(data))
  //     .catch((err) => {})
  //     .finally(() => {});

  //   getDataKpiTable({ body: chartPayload, isInvoice: false })
  //     .then((data) => setTableData(data))
  //     .catch((err) => {})
  //     .finally(() => {});
  // }, [chartPayload]);

  // const downloadExcel = () => {
  //   getDataKpiTableDownload({
  //     body: { ...chartPayload, excel: true },
  //     isInvoice: false,
  //   });
  // };
  // const getMonthUsers = (month: any) => {
  //   getDataMonthUsers({ body: { ...chartPayload, month }, isInvoice: false })
  //     .then((data) => setKpiUsersData(data))
  //     .catch((err) => {
  //       console.log(err);
  //     })
  //     .finally(() => {});
  // };

  const props = {
    chartTitle,
    chartLegend,
    // tableRow,
    // tableColumn,
    // tableColumn1,
    // chartData,
    // chartPayload,
    // setChartPayload,
    // tableData,
    // downloadExcel,
    // getMonthUsers,
    // kpiUsersData,
    ...hooksData,
    isPerMonthUser: false,
  };

  return <Kpi {...props} />;
};

export default BastApprovalLeadTime;
