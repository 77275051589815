import { useEffect, useState } from "react";
import { getDataKpiChart, getDataKpiTable, getDataKpiTableDownload, getDataMonthUsers, getDataMonthUsersTable } from "./getData";

export function useKpiState({approval1, approval2, isInvoice = true}: any) {
  // ACCOUNT PAYABLES EXAMPLE
  const [chartPayload, setChartPayload] = useState<any>({
    kpi: 7,
    year: new Date().getFullYear(),
    company_code: parseInt(
      JSON.parse(sessionStorage.getItem("current_company") as string)
        .company_code
    ),
    department_id: null,
    approval1: approval1,
    approval2: approval2,
    excel: false,
  });
  const [chartData, setChartData] = useState<any>({});
  const [tableData, setTableData] = useState<any>([]);
  const [kpiUsersData, setKpiUsersData] = useState<any>([]);
  const [kpiUsersDataTable, setKpiUsersDataTable] = useState<any>([]);

  const downloadExcel = () => {
    getDataKpiTableDownload({
      body: { ...chartPayload, excel: true },
      isInvoice,
    });
  };

  const getMonthUsers = (month: any) => {
    getDataMonthUsers({ body: { ...chartPayload, month}, isInvoice })
    .then((data) => setKpiUsersData(data))
    .catch((err) => {console.log(err)})
    .finally(() => {});
    getDataMonthUsersTable({ body: { ...chartPayload, month }, isInvoice })
    .then((data) => setKpiUsersDataTable(data))
    .catch((err) => {console.log(err)})
    .finally(() => {});
  }
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  useEffect(() => {
    getDataKpiChart({ body: chartPayload, isInvoice })
      .then((data) => setChartData(data))
      .catch((err) => {})
      .finally(() => {});

    getDataKpiTable({ body: chartPayload, isInvoice })
      .then((data) => setTableData(data))
      .catch((err) => {})
      .finally(() => {});
  }, [chartPayload]);
  
  return {
    // state
    chartPayload,
    setChartPayload,
    chartData,
    setChartData,
    tableData,
    setTableData,
    kpiUsersData,
    setKpiUsersData,
    kpiUsersDataTable,
    setKpiUsersDataTable,
    // method
    downloadExcel,
    getMonthUsers,
  }
}