import Kpi from "./Kpi";
import { bastChartLegend } from "./data";
import { useKpiState } from "./hooks";

export default function ContractManagerReviewLeadTime() {
  /* ==================================================================================================================================================
   *  4 KPI Contract Manager review Lead Time
      - per department
      "KPI Contract Manager review Lead Time": {
        Description:
          "perbandingan tanggal BAST di approve oleh Contract Inspector sampai dengan Contract Manager melakukan approval",
        // PAYLOAD
        payload: {
          kpi: 5,
          year: 2024,
          company_code: 1492,
          department_id: 1,
          approval1: 2,
          approval2: 4,
        },
        // RESPONSE DATA
        data: [
          // ... MONTH 1 - 12
          {
            month_no: "1",
            month_name: "Jan-2024",
            year_no: "2024",
            company_code: "1492",
            total_bast: "13",
            total_vendor: "4",
            lead_time: "4",
          },
        ],
      },
      // ========================================================================================
      "KPI Contract Manager review Lead Time Per User": {
      // PAYLOAD
        payload: {
          kpi: 5,
          year: 2024,
          month: 4,
          company_code: 1492,
          department_id: 1,
          approval1: 2,
          approval2: 4,
        },
        // RESPONSE DATA
        data: [
          // ... ALL USER
          {
            user_name: "user co test email",
            ontime: 7,
            delay: 1,
            total: 8,
            percent_ontime: "87.5%",
            percent_delay: "12.5%",
          },
        ],
      },
      */
  const hooksData = useKpiState({approval1: 2, approval2: 4, isInvoice: false});

  const chartTitle = "Contract Manager review Lead Time";
  const chartLegend = bastChartLegend


  const props = {
    chartTitle,
    chartLegend,
    ...hooksData,
  };

  return <Kpi {...props} />;
}
